import {Component, OnInit} from '@angular/core';
import {map, take, takeUntil} from "rxjs/operators";
import {AngularFirestore} from '@angular/fire/firestore';
import {Store} from '@ngrx/store';
import {Subject} from "rxjs";
import {User, Client, TeamMemberInvitation, OrganizationRole} from "@deliver-sense-librarian/data-schema";
import {ActivatedRoute, Router} from "@angular/router";
import {FirestoreUtilities} from "../../utilities/firestore-utilities";
import moment = require("moment");
import {FirebaseAuthService} from "../services/firebase-auth.service";
import {AngularFireAuth} from "@angular/fire/auth";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CustomValidators} from "ng2-validation";
import {verifyPasswordMatch} from "../../shared/validators";
import {LoadingDialogService} from "../../services/loading-dialog.service";
import {UnauthenticateUserAction} from "../../redux/custom-states/uiState/ui-state-actions";

@Component({
  selector: 'app-team-member-signup',
  templateUrl: './team-member-signup.component.html',
  styleUrls: ['./team-member-signup.component.scss']
})
export class TeamMemberSignupComponent implements OnInit {
  public loginForm: FormGroup;
  public signupForm: FormGroup;
  public showSignupForm = true;
  public invitationExpired = false;
  public invalidInvitation = false;
  private _destroy$ = new Subject();
  private _user: User;
  private _client: Client;
  private invitationId: string;
  private invitation: TeamMemberInvitation;

  constructor(private afAuth: AngularFireAuth,
              private fb: FormBuilder,
              private router: Router,
              private afs: AngularFirestore,
              private loadingService: LoadingDialogService,
              private snackBar: MatSnackBar,
              private auth: FirebaseAuthService,
              private activateRoute: ActivatedRoute,
              private store: Store<any>,
              private fbAuth: FirebaseAuthService) {
    this.store.dispatch(new UnauthenticateUserAction());
    this.afAuth.auth.signOut()
  }

  ngOnInit() {
    this.activateRoute.params.subscribe(params$ => {
      this.invitationId = params$['invitationId'];
      if (this.invitationId) {
        this.getInvitation().subscribe(() => {
          this.store.select(store => store.uiState)
            .pipe(takeUntil(this._destroy$))
            .subscribe(uiState$ => {
              if (uiState$.authUser && uiState$.client) {
                this.showSignupForm = false;
                this._user = uiState$.authUser;
                this._client = uiState$.client;
              } else {
                this.showSignupForm = true;
              }
            });
        });
      } else {
        this.invalidInvitation = true;
      }
    })
  }

  private getInvitation() {
    return this.afs.doc(`teamMemberInvitations/${this.invitationId}`)
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$), map(invitation$ => {
        this.invitation = FirestoreUtilities.objectToType(invitation$) as TeamMemberInvitation;
        if (this.invitation) {
          if (moment(this.invitation.expiration.toDate()).isSameOrBefore(moment())
            || this.invitation.status === 'complete') {
            this.invitationExpired = true;
          }
          this._setupLoginForm();
          this._setupSignupForm();
        } else {
          this.invalidInvitation = true;
        }
      }));
  }

  public async login() {
    if (this.loginForm.invalid) {
      this.snackBar.open('Please enter your email and password.', 'Dismiss', {
        duration: 5000
      });
      return;
    }
    try {
      const user$ = await this.fbAuth.login(this.loginForm.value.email, this.loginForm.value.password);
      this.loadingService.isLoading(true, 'Adding your to the client account.');
      await this._updateUserRoles(user$);
      await this._acceptInvitation();
      this.loadingService.isLoading(false);
      this.snackBar.open('Login Successful!', 'Dismiss', {
        duration: 5000
      });
      await this.router.navigate(['client-selection']);
    } catch (e) {
      this.snackBar.open('Invalid email and/or password', 'Dismiss', {
        duration: 5000
      });
    }
  }

  public async signup() {
    if (this.signupForm.valid) {
      const form = this.signupForm.value;
      const user = new User();
      user.firstName = form.firstName;
      user.lastName = form.lastName;
      user.email = form.email;
      const user$ = await this.fbAuth.register(user, form.password);
      await this._updateUserRoles(user$);
      await this._acceptInvitation();
      this.snackBar.open('Registration Successful!', 'Dismiss', {
        duration: 5000
      });
      await this.router.navigate(['client-selection']);
    } else {
      this.snackBar.open('Please complete the signup form.', 'Dismiss', {
        duration: 5000
      })
    }
  }

  private _setupLoginForm() {
    this.loginForm = this.fb.group({
      email: new FormControl(this.invitation.email, [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required)
    });
  }

  private _setupSignupForm() {
    this.signupForm = this.fb.group({
      email: new FormControl(this.invitation.email, [Validators.required, CustomValidators.email]),
      password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.required]),
      confirmPassword: new FormControl(null, Validators.required),
    }, {validator: verifyPasswordMatch});
  }

  private async _updateUserRoles(user: User) {
    const invitation = this.invitation;
    const userClientRoleRef = this.afs.doc(`users/${user.id}/clientRoles/${this.invitation.client}`);
    const userOrgRolesRef = this.afs.collection(`users/${user.id}/clientRoles/${this.invitation.client}/organizationRoles`);
    this.loadingService.isLoading(true, 'Setting your client roles....');
    const updatedRoles = invitation.clientRole;
    await userClientRoleRef.set({
      role: updatedRoles.role,
      resource: this.invitation.client
    });
    const orgRolesBatch = this.afs.firestore.batch();
    updatedRoles.entities.forEach((entityRole: OrganizationRole) => {
      orgRolesBatch.set(userOrgRolesRef.doc(entityRole.resource).ref, {
        resource: entityRole.resource,
        role: entityRole.role,
        type: entityRole.type
      });
    });
    updatedRoles.locations.forEach((locationRole: OrganizationRole) => {
      orgRolesBatch.set(userOrgRolesRef.doc(locationRole.resource).ref, {
        resource: locationRole.resource,
        role: locationRole.role,
        type: locationRole.type
      });
    });
    updatedRoles.departments.forEach((departmentRole: OrganizationRole) => {
      orgRolesBatch.set(userOrgRolesRef.doc(departmentRole.resource).ref, {
        resource: departmentRole.resource,
        role: departmentRole.role,
        type: departmentRole.type
      });
    });
    updatedRoles.projects.forEach((projectRole: OrganizationRole) => {
      orgRolesBatch.set(userOrgRolesRef.doc(projectRole.resource).ref, {
        resource: projectRole.resource,
        role: projectRole.role,
        type: projectRole.type
      });
    });
    try {
      this.loadingService.isLoading(false);
      return await orgRolesBatch.commit();
    } catch (e) {
      this.loadingService.isLoading(false);
      this.snackBar.open('Oops... something went wrong. Please refresh and try again.', 'Dismiss', {
        duration: 5000
      });
    }
    // // create reference to user client role
    // const newClientRoleRef = this.afs.doc(`users/${user.id}/clientRoles/${this.invitation.client}`);
    // const newOrganizationRoleRef = this.afs.collection(`users/${user.id}/clientRoles/${this.invitation.client}/organizationRoles`);
    // // set the user client role from the invitation
    // await newClientRoleRef.set({
    //   resource: invitation.client,
    //   role: invitation.clientRole.role
    // });
    // const organizationRoleSets = [];
    // if (invitation.clientRole.entities) {
    //   Object.keys(invitation.clientRole.entities).forEach(docId => {
    //     organizationRoleSets.push(
    //       newOrganizationRoleRef.doc(docId).set({
    //         resource: docId,
    //         type: 'entity',
    //         role: invitation.clientRole.entities[docId]
    //       })
    //     )
    //   });
    // }
    // if (invitation.clientRole.locations) {
    // Object.keys(invitation.clientRole.locations).forEach(docId => {
    //   organizationRoleSets.push(
    //     newOrganizationRoleRef.doc(docId).set({
    //       resource: docId,
    //       type: 'location',
    //       role: invitation.clientRole.locations[docId]
    //     })
    //   )
    // });
    // }
    // if (invitation.clientRole.locations) {
    //   Object.keys(invitation.clientRole.departments).forEach(docId => {
    //     organizationRoleSets.push(
    //       newOrganizationRoleRef.doc(docId).set({
    //         resource: docId,
    //         type: 'department',
    //         role: invitation.clientRole.departments[docId]
    //       })
    //     )
    //   });
    // }
    // if (invitation.clientRole.locations) {
    //   Object.keys(invitation.clientRole.projects).forEach(docId => {
    //     organizationRoleSets.push(
    //       newOrganizationRoleRef.doc(docId).set({
    //         resource: docId,
    //         type: 'project',
    //         role: invitation.clientRole.projects[docId]
    //       })
    //     )
    //   });
    // }
    // return await Promise.all(organizationRoleSets);
  }

  private _acceptInvitation() {
    return this.afs.doc(`teamMemberInvitations/${this.invitationId}`).update({status: 'complete'});
  }
}
