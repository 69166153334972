import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FirebaseAuthService} from "../../../../auth/services/firebase-auth.service";
import {Papa} from "ngx-papaparse";
import {MatCheckboxChange, MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource} from "@angular/material";
import {AngularFirestore} from '@angular/fire/firestore';
import {ThirdParty, Location, LocationThirdParty, LocationThirdPartyRate, RateType, User, UserLocationRole, Client, ClientRole} from "@deliver-sense-librarian/data-schema";
import {FirestoreUtilities} from "../../../../utilities/firestore-utilities";
import {combineAll, takeUntil} from "rxjs/operators";
import {combineLatest, from, Subject} from "rxjs";
import {ConfirmDialogComponent} from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import {Store} from '@ngrx/store';
import {tableExpandAnimation} from "../../../../shared/ds-constant";
import {LocationUploadDialogComponent} from "../../../../dialogs/location-upload-dialog/location-upload-dialog.component";
import {UiState} from "../../../../redux/custom-states/uiState/ui-state";

/**
 * @Bookmark
 * Remove total tax concept
 * flatten addresses into location, client, project --> filterable by address
 * move state, county, special, and city tax to expansion panel
 * keep total tax as calculation in main row
 * move address to main row
 * audit trail for changes (active log of what users and what they changed)
 *      user:
 *      collection:
 *      document:
 *      field:
 *      oldValue:
 *      newValue:
 *      timestamp:
 *      approved:
 * audit trail notification for reviewer --> admin (cron to summarize at the end of the day)
 * notification settings
 */

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
  animations: tableExpandAnimation
})

export class LocationsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public tableData: MatTableDataSource<Location>;
  public mutableTableData: MatTableDataSource<any> & MatTableDataSource<Location>;
  public displayedColumns: string[] = ['id', 'name', 'addressLine1', 'addressLine2', 'city', 'state', 'zip', 'totalTaxRate', 'expandTrigger'];
  public isInEditMode = false;
  private _user: User;
  private _locations: Location[] = [];
  private _locationThirdParties: LocationThirdParty[] = [];
  private _locationThirdPartyRates: LocationThirdPartyRate[] = [];
  public teamMembers: User[] = [];
  expandedElement: any | null;
  private _destroy$ = new Subject();
  public thirdParties: ThirdParty[] = [];
  public rateTypes: RateType[] = [];
  public uiState: UiState;

  private ldprsToChange = {};
  private ldpsToChange = {};
  private locationsToChange = {};

  private _ldpActiveChanges = {};
  private _client: Client;

  constructor(private store: Store<any>,
              private papa: Papa,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private afs: AngularFirestore,
              private _cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this._destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this._user = uiState$.authUser;
          this._client = uiState$.client;
          this.uiState = uiState$;
          this.teamMembers.push(this._user);
          this._fetchLocations();
          this._fetchThirdPartysAndRateTypes();
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  private _fetchLocations() {
    const locationsRequests = this.uiState.locations.map(location => {
      return this.afs.doc(`locations/${location.resource}`).snapshotChanges();
    });
    from(locationsRequests)
      .pipe(combineAll(), takeUntil(this._destroy$))
      .subscribe(locations$ => {
        this._locations = FirestoreUtilities.mergeToType(locations$);
        this._mergeDataForTable();
      })
  }

  private _fetchLocationThirdParties() {
    const locationThirdPartyRequests = this._locations.map(location => {
      return this.afs.collection('locationThirdParties', ref => ref
        .where('location', '==', location.id))
        .snapshotChanges();
    });
    from(locationThirdPartyRequests)
      .pipe(combineAll(), takeUntil(this._destroy$))
      .subscribe(locationThirdParties$ => {
        this._locationThirdParties = FirestoreUtilities.mergeCollectionToType(locationThirdParties$);
        this._fetchLocationThirdPartyRates();
      })
  }

  private _fetchLocationThirdPartyRates() {
    const locationThirdPartyRateRequests = this._locationThirdParties.map(locationThirdParty => {
      return this.afs.collection('locationThirdPartyRates', ref => ref
        .where('locationThirdParty', '==', locationThirdParty.id))
        .snapshotChanges();
    });
    from(locationThirdPartyRateRequests)
      .pipe(combineAll(), takeUntil(this._destroy$))
      .subscribe(locationThirdPartyRates$ => {
        this._locationThirdPartyRates = FirestoreUtilities.mergeCollectionToType(locationThirdPartyRates$);
        this._mergeDataForTable();
      })
  }

  private _mergeDataForTable() {

    this.tableData = new MatTableDataSource<Location>(this._locations);
    this.mutableTableData = Object.assign(new MatTableDataSource(), this.tableData);
      this.tableData.paginator = this.paginator;
      this.tableData.sort = this.sort;
      this._cdr.detectChanges();
  }

  private _fetchLocationChildData() {
    const userViewRequests = this._locations.map(location => {
      return this.afs.doc(`userViews/${location.primaryContact}`).snapshotChanges();
    });
    combineLatest([
      from(userViewRequests)
        .pipe(combineAll(), takeUntil(this._destroy$))
    ]).subscribe(([userViews$]) => {
      const userViews = FirestoreUtilities.mergeToType(userViews$);
      this._locations.forEach(location => {
        location.primaryContact = userViews.find(userView => userView.id === location.primaryContact);
      });
    })
  }

  cancelTableChanges() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Cancel',
        message: 'Are you sure you want to discard your changes?',
        action: 'Yes, cancel.'
      }
    });
    dialogRef.afterClosed().subscribe(discardChanges => {
      if (discardChanges) {
        this.isInEditMode = false;
      }
    });
  }

  saveTableChanges() {
    const dataModRequests = [
      ...this._getUpdateRequests(this.ldprsToChange, 'locationThirdPartyRates'),
      ...this._getUpdateRequests(this.ldpsToChange, 'locationThirdParties'),
      ...this._getUpdateRequests(this.locationsToChange, 'locations'),
      ...this._getActiveChanges(),
    ];
    Promise.all(dataModRequests).then(() => {
      this.snackBar.open('Updated location data successfully', 'Dismiss', {
        duration: 5000
      });
      this.isInEditMode = false;
    });
  }


  markForChange(key: string, value: any, location: Location, thirdParty?: ThirdParty, rateType?: RateType) {
    const locationWithChange = this.mutableTableData.data.find(_location => _location.id === location.id);
    if (rateType) {
      const locationThirdParty = locationWithChange.locationThirdParties.find(ldp => ldp.thirdParty === thirdParty.id);
      if (locationThirdParty) {
        const ldpr = locationThirdParty.locationThirdPartyRates.find(_ldpr => _ldpr.rateType === rateType.id);
        if (ldpr) {
          this.ldprsToChange[ldpr.id] = this.ldprsToChange[ldpr.id] ? this.ldprsToChange[ldpr.id] : {};
          this.ldprsToChange[ldpr.id][key] = value;
        }
      }
    } else if (thirdParty) {
      const ldp = locationWithChange.locationThirdParties.find(_ldp => _ldp.thirdParty === thirdParty.id);
      if (ldp) {
        this.ldpsToChange[ldp.id] = this.ldpsToChange[ldp.id] ? this.ldpsToChange[ldp.id] : {};
        this.ldpsToChange[ldp.id][key] = value;
      }
    } else if (location) {
      this.locationsToChange[location.id] = this.locationsToChange[location.id] ? this.locationsToChange[location.id] : {};
      this.locationsToChange[location.id][key] = value;
    }

  }

  private _fetchThirdPartysAndRateTypes() {
    const fetchThirdPartys = this.afs.collection('thirdParties').snapshotChanges();
    const fetchRateTypes = this.afs.collection('rateTypes').snapshotChanges();
    combineLatest([
      fetchThirdPartys,
      fetchRateTypes
    ]).pipe(takeUntil(this._destroy$))
      .subscribe(([thirdParties$, rateTypes$]) => {
        this.thirdParties = FirestoreUtilities.mapToType(thirdParties$);
        this.rateTypes = FirestoreUtilities.mapToType(rateTypes$)
          .filter(rate => {
            return rate.name !== 'Beer' &&
              rate.name !== 'Non-Taxable' &&
              rate.name !== 'Wine' &&
              rate.name !== 'Liquor'
          })
          .sort((a, b) => {
            return a.name === 'Food & N/A Bev' ? -1 : 1;
          });

      })
  }

  getLocationThirdParties(location: Location) {
    return location.thirdParties ? location.thirdParties : []
  }

  isThirdPartyAvailableForLocation(location: any, thirdParty: ThirdParty) {
    const isDpAvailalbe = this.getLocationThirdParties(location).find(ldp => ldp.thirdParty === thirdParty.id);
    return isDpAvailalbe ? isDpAvailalbe.active : false;
  }

  getThirdPartyRateAtLocation(thirdParty: ThirdParty, rateType: RateType, location: any, getEffectiveDate?) {
    const ldp = this.getLocationThirdParties(location).find(_ldp => _ldp.thirdParty === thirdParty.id);
    if (ldp) {
      const ldpr = this.getLocationThirdParties(location).find(rate => rate.rateType === rateType.id);
      if (ldpr) {
        if (!getEffectiveDate) {
          return +ldpr.rate ? +ldpr.rate : 0;
        } else {
          return ldpr.effectiveDate ? ldpr.effectiveDate : '';
        }
      }
    }
    return 0;
  }

  getThirdPartyDeliveryFee(thirdParty: ThirdParty, location: Location) {
    const ldp = this.getLocationThirdParties(location).find(_ldp => _ldp.thirdParty === thirdParty.id);
    if (ldp) {
      return ldp.thirdPartyFee;
    }
    return 0;
  }

  getTotalTaxRate(row: any) {
    return (+row.stateTaxRate + +row.cityTaxRate + +row.countyTaxRate + +row.specialTaxRate).toFixed(2);
  }

  // @TODO need to create a ldp for every single location but only active if stuff in upload is there
  // checkbox will just set to active or not
  markLdpActiveChange(event: MatCheckboxChange, location: Location, thirdParty: ThirdParty) {
    const existingLdp = this.getLocationThirdParties(location).find(_ldp => _ldp.thirdParty === thirdParty.id);
    if (existingLdp) {
      this._ldpActiveChanges[existingLdp.id] = this._ldpActiveChanges[existingLdp.id] ? this._ldpActiveChanges[existingLdp.id] : {};
      this._ldpActiveChanges[existingLdp.id]['active'] = event.checked;
    }
  }

  private _getUpdateRequests(changeTracker, collectionName) {
    const changeRequests = [];
    for (const resourceId in changeTracker) {
      if (resourceId && changeTracker[resourceId]) {
        changeRequests.push(
          this.afs.doc(`${collectionName}/${resourceId}`).update(
            changeTracker[resourceId]
          )
        );
      }
    }
    return changeRequests;
  }

  private _getActiveChanges() {
    const changeRequests = [];
    for (const ldpId in this._ldpActiveChanges) {
      if (ldpId && this._ldpActiveChanges[ldpId] && this._ldpActiveChanges[ldpId]['active']) {
        changeRequests.push(
          this.afs.doc(`locationThirdParties/${ldpId}`).update(
            {active: this._ldpActiveChanges[ldpId]['active']}
          )
        );
      }
    }
    return changeRequests;
  }

  private _getDeleteRequests(changeTracker, collectionName) {

  }

  openLocationUploadDialog() {
    this.dialog.open(LocationUploadDialogComponent, {
      panelClass: 'invisible-panel-dialog'
    });
  }
}
