import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AuditTrailDocument, AuditTrailDocumentTabComment, AuditTrailDocumentTabCommentStatuses, Client, User, UserView} from "@deliver-sense-librarian/data-schema";
import {Store} from "@ngrx/store";
import {Papa} from "ngx-papaparse";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {LoadingDialogService} from "../../../../services/loading-dialog.service";
import {AngularFirestore} from "@angular/fire/firestore";
import {CreateNotificationService} from "../../../../services/create-notification.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import moment = require("moment");
import {ConfirmDialogComponent} from "../../../../dialogs/confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-audit-trail-document-comment',
  templateUrl: './audit-trail-document-comment.component.html',
  styleUrls: ['./audit-trail-document-comment.component.scss']
})
export class AuditTrailDocumentCommentComponent implements OnInit, OnDestroy {
  @Input() comment: AuditTrailDocumentTabComment;
  @Input() reviewDocument: AuditTrailDocument;
  @Input() availableAssignees: UserView[];
  @Input() userViews: UserView[] = [];
  public commentForm: FormGroup;
  public user: User;
  public commentStatuses = [
    {name: 'pending', value: AuditTrailDocumentTabCommentStatuses.pending, disabled: true},
    {name: 'complete', value: AuditTrailDocumentTabCommentStatuses.complete, disabled: true},
    {name: 'approved', value: AuditTrailDocumentTabCommentStatuses.approved, disabled: true},
    {name: 'cancelled', value: AuditTrailDocumentTabCommentStatuses.cancelled, disabled: true},
  ];
  private _client: Client;
  private _destroy$ = new Subject();

  constructor(private store: Store<any>,
              private papa: Papa,
              private dialog: MatDialog,
              private activedRoute: ActivatedRoute,
              private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private loadingService: LoadingDialogService,
              private afs: AngularFirestore,
              private notificationService: CreateNotificationService) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this._destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client && (this.user !== uiState$.authUser && this._client !== uiState$.client)) {
          this.user = uiState$.authUser;
          this._client = uiState$.client;
          this.filterCommentsBasedOnCreatorVsAssignee();
          this.setupCommentForm();
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  async saveTabComment() {
    if (this.commentForm.valid) {
      try {
        const originalComment = Object.assign({}, this.comment);
        const formValues = this.commentForm.value;
        await this.afs.doc(`auditTrailDocumentTabComments/${this.comment.id}`).update({
          message: formValues.message ? formValues.message : null,
          response: formValues.response ? formValues.response : null,
          assignee: formValues.assignee,
          dueDate: formValues.dueDate ? moment(formValues.dueDate).toDate() : null,
          status: formValues.status
        });
        if (originalComment.assignee !== formValues.assignee) {
          this.createNewAssigneeNotification(this.comment);
          this.createCommentUnassignedToYou(originalComment);
        }
        if (originalComment.status !== formValues.status) {
          if (formValues.status === AuditTrailDocumentTabCommentStatuses.approved) {
            this.createCommentApprovedNotification(this.comment);
          } else if (formValues.status === AuditTrailDocumentTabCommentStatuses.complete) {
            this.createCommentCompletedNotification(this.comment);
          }
        }
        this.snackBar.open('Comment updated', 'Dismiss', {
          duration: 5000
        });
      } catch (e) {
        console.log(e);
        this.snackBar.open('Oops... something went wrong. Please refresh the page and try again.', 'Dismiss', {
          duration: 5000
        });
      }
    } else {
      this.snackBar.open('Please fill out required fields.', 'Dismiss', {
        duration: 5000
      });
    }
  }
  getCommentAssigneeName() {
    const userMatch = this.userViews.find(userView => userView.id === this.comment.assignee);
    return userMatch ? `${userMatch.firstName} ${userMatch.lastName}` : '';
  }
  async deleteTabComment() {
    const confirmRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Cancel',
        message: 'Are you sure you want to discard your changes?',
        action: 'Yes, cancel.'
      }
    });
    confirmRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`auditTrailDocumentTabComments/${this.comment.id}`).delete();
        this.snackBar.open('Comment deleted successfully!', 'Dismiss', {
          duration: 5000
        })
      }
    });
  }

  private async createCommentApprovedNotification(comment: AuditTrailDocumentTabComment) {
    const auditTrailDocumentTab = this.reviewDocument['tabs'].find(tab => tab.id === comment.auditTrailDocumentTab);
    await this.notificationService.createNotification(
      `Comment approved for ${this.reviewDocument.name} ${auditTrailDocumentTab ? ('in section ' + auditTrailDocumentTab.name) : ''} by ${this.user.email}`,
      comment.assignee);
  }

  private async createCommentUnassignedToYou(originalComment: AuditTrailDocumentTabComment) {
    const auditTrailDocumentTab = this.reviewDocument['tabs'].find(tab => tab.id === originalComment.auditTrailDocumentTab);
    await this.notificationService.createNotification(
      `Comment unassigned from you in ${this.reviewDocument.name} ${auditTrailDocumentTab ? ('in section ' + auditTrailDocumentTab.name) : ''} by ${this.user.email}`,
      originalComment.assignee);
  }

  private async createNewAssigneeNotification(comment: AuditTrailDocumentTabComment) {
    const auditTrailDocumentTab = this.reviewDocument['tabs'].find(tab => tab.id === comment.auditTrailDocumentTab);
    await this.notificationService.createNotification(
      `Comment for ${this.reviewDocument.name} ${auditTrailDocumentTab ? ('in section ' + auditTrailDocumentTab.name) : ''} assigned to you by ${this.user.email}`,
      comment.assignee);
  }

  private async createCommentCompletedNotification(comment: AuditTrailDocumentTabComment) {
    const auditTrailDocumentTab = this.reviewDocument['tabs'].find(tab => tab.id === comment.auditTrailDocumentTab);
    await this.notificationService.createNotification(
      `Comment completed for ${this.reviewDocument.name} ${auditTrailDocumentTab ? ('in section ' + auditTrailDocumentTab.name) : ''} by ${this.user.email}`,
      comment.creator);
  }

  private filterCommentsBasedOnCreatorVsAssignee() {
    if (this.reviewDocument && (this.reviewDocument.creator === this.user.id || this.reviewDocument.creator['id'] === this.user.id)) {
      // Creators can set to approved to clear comment, cancelled to remove the need to do it, or pending for work to be completed on it.
      this.commentStatuses = [
        {name: 'pending', value: AuditTrailDocumentTabCommentStatuses.pending, disabled: false},
        {name: 'complete', value: AuditTrailDocumentTabCommentStatuses.complete, disabled: true},
        {name: 'approved', value: AuditTrailDocumentTabCommentStatuses.approved, disabled: false},
        {name: 'cancelled', value: AuditTrailDocumentTabCommentStatuses.cancelled, disabled: false},
      ];
    } else {
      // Assignees can only update to completed or back to pending
      // @TODO change complete to ready for review
      // @TODO lock changes from assignee if set to approved
      this.commentStatuses = [
        {name: 'pending', value: AuditTrailDocumentTabCommentStatuses.pending, disabled: true},
        {name: 'complete', value: AuditTrailDocumentTabCommentStatuses.complete, disabled: false},
        {name: 'approved', value: AuditTrailDocumentTabCommentStatuses.approved, disabled: true},
        {name: 'cancelled', value: AuditTrailDocumentTabCommentStatuses.cancelled, disabled: true},
      ];
    }
  }
  getCommentDateFormatted(comment: AuditTrailDocumentTabComment) {
    if (comment.dueDate) {
      return comment.dueDate.seconds ? moment(comment.dueDate.toDate()).format('M/D/YYYY') : moment(comment.dueDate).format('M/D/YYYY');
    }
  }
  private setupCommentForm() {
    const dueDate = this.comment.dueDate ? this.comment.dueDate.toDate() : '';
    this.commentForm = this.fb.group({
      message: new FormControl(this.comment.message, Validators.required),
      response: new FormControl(this.comment.response),
      dueDate: new FormControl(dueDate),
      assignee: new FormControl(this.comment.assignee),
      status: new FormControl(this.comment.status, Validators.required)
    })
  }
}
