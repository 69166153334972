import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog } from "@angular/material";
import { ConfirmLeaveDialogComponent } from "../dialogs/unsaved-changes/confirm-leave-dialog.component";
import { map } from "rxjs/operators";

// @ts-ignore
@Injectable({
    providedIn: 'root'
})
export class ConfirmLeaveGuard implements CanDeactivate<any> {
    constructor(private dialog: MatDialog) {

    }

    canDeactivate(component: any): Observable<boolean> | boolean {
        if (!component.unsavedChanges()) {
            return true;
        } else {
            const dialogRef = this.dialog.open(ConfirmLeaveDialogComponent, {
                autoFocus: false,
                panelClass: 'invisible-panel-dialog'
            });
            return dialogRef.afterClosed().pipe(map(result => {
                if (result) {
                    return true;
                } else if (result === undefined) {
                    return false;
                } else {
                    return false;
                }
            }));
        }
    }
}
