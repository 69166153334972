import {ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AngularFirestore} from "@angular/fire/firestore";
import {Store} from "@ngrx/store";
import {Subject} from "rxjs";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {tableExpandAnimation} from "../../../../shared/ds-constant";

@Component({
  selector: 'app-tax-drill-down',
  templateUrl: './tax-drill-down.component.html',
  styleUrls: ['./tax-drill-down.component.scss'],
  animations: tableExpandAnimation
})
export class TaxDrillDownComponent implements OnInit, OnDestroy {
  @Input() locationReportData: any;
  @Input() thirdParties: any;
  @Input() locations: any;
  @Input() reportHeaderCard: ElementRef;
// @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    'location',
    'thirdParty',
    'posTax',
    'thirdPartyTax',
    'thirdPartyEffectiveTax',
    'locationTotalTax',
    'mfTax',
    'taxResponsibility',
    'taxAdjustment'
  ];
  public expandedElement: any | null;
  private _destroy$ = new Subject();
  private screenHeight = window.innerHeight;

  // private _user: User;
  // private _client: Client;
  // private posTransactions: PosTransaction[] = [];
  // private clientPosCategories: ClientPosCategory[] = [];

  constructor(private afs: AngularFirestore,
              private cdr: ChangeDetectorRef,
              private store: Store<any>) {
  }

  ngOnInit() {
    this.tableData = new MatTableDataSource(this.locationReportData);
    // this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.cdr.detectChanges();
    // this.store.select(store => store.uiState)
    //   .pipe(takeUntil(this._destroy$))
    //   .subscribe(uiState$ => {
    //     if (uiState$.authUser && uiState$.client) {
    //       this._user = uiState$.authUser;
    //       this._client = uiState$.client;
    //       this.afs.collection('clientPosCategories', ref => ref
    //         .where('client', '==', this._client.id))
    //         .snapshotChanges()
    //         .pipe(takeUntil(this._destroy$))
    //         .subscribe(clientPosCategories$ => {
    //           this.clientPosCategories = <ClientPosCategory[]>FirestoreUtilities.mapToType(clientPosCategories$);
    //           this.getTransactionCheckLevelDetails();
    //         }, (e) => {
    //           console.log(e);
    //         });
    //     }
    //   });
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  getThirdPartyName(thirdPartyId: string) {
    const tp = this.thirdParties.find(_tp => _tp.id === thirdPartyId);
    return tp ? tp.name : '';
  }

  getVariance(a: number, b: number) {
    return +(a - b).toFixed(2);
  }

  exportReport() {
    const exportData = this.locationReportData.map(row => {
      return {
        'location': `${row.locationId} - ${row.name}`,
        'thirdParty': this.getThirdPartyName(row.thirdParty),
        'posTax': `$${row.posTax}`,
        'thirdPartyTax': `$${row.thirdPartyTax}`,
        'taxVariance': `$${this.getVariance(row.posTax, row.thirdPartyTax)}`,
        'thirdPartyEffectiveTax': `${row.thirdPartyEffectiveTaxRate}%`,
        'locationTotalTax': `${row.locationTaxRate}%`,
        'marketFacilitatorTax': `$${row.marketFacilitatorTax}`,
        'taxResponsibility': `$${row.taxResponsibility}`,
        'taxAdjustment': `$${row.taxAdjustment}`
      }
    });
    // @ts-ignore
    this.papa.unparse(exportData, Object.assign({},
      {
        quotes: false,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ",",
        header: true,
        newline: "\r\n",
        skipEmptyLines: false,
        complete: (results, file) => {
        }
      }))
  }

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
  }

  getHeight() {
    // @ts-ignore
    if (this.screenHeight && this.reportHeaderCard && this.reportHeaderCard.clientHeight) {
      // @ts-ignore
      const height = this.screenHeight - (280 + this.reportHeaderCard.clientHeight);
      return height;
    }
    return 500;
  }
  //
  // private getTransactionCheckLevelDetails() {
  //   this.posTransactions = this.locationReportData['posTransactions'];
  //   this.posTransactions.forEach((posTransaction: PosTransaction) => {
  //     posTransaction.date = new Timestamp(posTransaction.date.seconds, posTransaction.date.nanoseconds);
  //   });
  //   const checkDetailRequest = this.posTransactions.map(posTransaction => {
  //     return this.afs.collection('posTransactionItems', ref => ref
  //       .where('posTransaction', '==', posTransaction.id))
  //       .snapshotChanges()
  //   });
  //   from(checkDetailRequest)
  //     .pipe(combineAll(), takeUntil(this._destroy$))
  //     .subscribe(posTransactionItems$ => {
  //       const posTransactionItems = FirestoreUtilities.mergeCollectionToType(posTransactionItems$) as PosTransactionItem[];
  //       this.posTransactions.forEach(posTransaction => {
  //         const items = posTransactionItems.filter(item => item.posTransaction === posTransaction.id);
  //         posTransaction['items'] = items;
  //         this.getTransactionLocationRates();
  //       }, (error) => {
  //         console.log('error getting retrieving data: ' + error);
  //       });
  //     })
  // }
  //
  // private calculateExpectedTaxForTransactions() {
  //   this.posTransactions.forEach(posTransaction => {
  //     posTransaction['items'].forEach(posTransactionItem => {
  //       /**
  //        * add a rate field to each transaction item that is equal to the 3pd location rate for the transaction
  //        * get the category rate
  //        * get the rate value for the location given the posTransactions thirdParty rate value
  //        * */
  //       const clientPosCategory = this.clientPosCategories.find(cat => cat.code === posTransactionItem.category) as ClientPosCategory;
  //       const posTransactionLocationThirdParty = posTransaction['locationObject'].thirdParties.find(tp => tp.thirdParty === posTransaction.account);
  //       if (posTransactionLocationThirdParty && clientPosCategory) {
  //         // sort and rates by effectiveDate
  //         posTransactionLocationThirdParty.rates.sort((a, b) => {
  //           return moment(a.effectiveDate.toDate()).isBefore(moment(b.effectiveDate.toDate())) ? 1 : -1;
  //         });
  //         const categoryRelevantRates = posTransactionLocationThirdParty.rates
  //           .filter(rate => rate.rateType === clientPosCategory.rateType);
  //         if (categoryRelevantRates.length > 0) {
  //           const posTransactionItemLocationThirdPartyRate = posTransactionLocationThirdParty.rates.find(rate => {
  //             return rate.rateType === clientPosCategory.rateType &&
  //               moment(rate.effectiveDate.toDate()).isSameOrBefore(posTransaction.date.toDate());
  //           });
  //           posTransactionItem['rate'] = posTransactionItemLocationThirdPartyRate ? posTransactionItemLocationThirdPartyRate.rate : this.getLocationTotalRate(posTransaction['locationObject']);
  //         }
  //       } else {
  //         posTransactionItem['rate'] = this.getLocationTotalRate(posTransaction['locationObject']);
  //       }
  //     });
  //     // calculate the total expected tax for the order by multiplying each item price by the 3pdLocation rate found above
  //     posTransaction['totalExpected3PDTax'] = posTransaction['items'].reduce((result: number, item: PosTransactionItem) => {
  //       const itemTax = item.price * (item['rate'] / 100);
  //       if (itemTax) {
  //         result += itemTax;
  //       }
  //       return result;
  //     }, 0);
  //   });
  // }
  //
  // private getLocationTotalRate(location: Location) {
  //   const stateTaxRate = +location.stateTaxRate ? +location.stateTaxRate : 0;
  //   const cityTaxRate = +location.cityTaxRate ? +location.cityTaxRate : 0;
  //   const countyTaxRate = +location.countyTaxRate ? +location.countyTaxRate : 0;
  //   const specialTaxRate = +location.specialTaxRate ? +location.specialTaxRate : 0;
  //   return stateTaxRate + cityTaxRate + countyTaxRate + specialTaxRate
  // }
  //
  // private getTransactionLocationRates() {
  //   const locationRequests = this.posTransactions.map(posTransaction => {
  //     return this.afs.collection(`locations`, ref => ref
  //       .where('locationId', '==', posTransaction.location)).snapshotChanges();
  //   });
  //   from(locationRequests)
  //     .pipe(combineAll(), takeUntil(this._destroy$))
  //     .subscribe(locations$ => {
  //       const locations = <Location[]>FirestoreUtilities.mergeCollectionToType(locations$);
  //       this.posTransactions.forEach(posTransaction => {
  //         posTransaction['locationObject'] = locations.find(l => l.locationId === posTransaction.location) as Location;
  //       });
  //       this.calculateExpectedTaxForTransactions();
  //     }, (error) => {
  //       console.log('error getting retrieving data: ' + error);
  //     });
  // }
}
