import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from "@ngrx/store";
import {Papa} from "ngx-papaparse";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AngularFirestore} from "@angular/fire/firestore";
import {ActivatedRoute, Router} from "@angular/router";
import {combineAll, take, takeUntil} from "rxjs/operators";
import {combineLatest, from, pipe, Subject} from "rxjs";
import {Client, User, UserRoles, Location, ThirdParty, ThirdPartyTransaction, PosTransaction, ThirdPartyReport, Pos, Entity, LocationThirdParty} from "@deliver-sense-librarian/data-schema";
import moment = require("moment");
import {FormControl, Validators} from "@angular/forms";
import {FirestoreUtilities} from "../../../../utilities/firestore-utilities";
import {LoadingDialogService} from "../../../../services/loading-dialog.service";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {tableExpandAnimation, testPOSTransactionData, testUETransactionData} from "../../../../shared/ds-constant";
import {ReasonPromptDialogComponent} from "../../../../dialogs/reason-prompt-dialog/reason-prompt-dialog.component";
import {ThirdPartyDeliveryAnalyticsEngine} from "../third-party-delivery-analytics-engine";
import {UiState} from "../../../../redux/custom-states/uiState/ui-state";

export class LocationReport {
  locationId: string;
  name: string;
  entity: string | Entity;
  client: string | Client;
  primaryContact: string;
  stateTaxRate: number;
  countyTaxRate: number;
  cityTaxRate: number;
  specialTaxRate: number;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressState: string;
  addressPostalCode: string;
  addressCountry: string;
  thirdParties: any[] | LocationThirdParty[];

  // Report Specific
  thirdParty: string | ThirdParty;
  posTransactions: any[];
  thirdPartyTransactions: any[];

  /**
   * Sales Remittance Values
   */
  thirdPartySales: number;
  deliveryFees: number;
  otherFees: number;
  expectedRemittance: number;
  actualRemittance: number;
  posSales: number;

  /**
   * Tax Reporting Values
   */
  thirdPartyTax: number;
  thirdPartyEffectiveTaxRate: number;
  thirdPartyRemitted: number;
  posTax: number;
  locationTaxRate: number;
  marketFacilitatorTax: number;
  taxResponsibility: number; // 3pdTax - 3pdTaxRemitted
  taxAdjustment: number // = posTax - taxResponsibility
}

@Component({
  selector: 'app-create-report',
  templateUrl: './create-report.component.html',
  styleUrls: ['./create-report.component.scss'],
})
export class CreateReportComponent implements OnInit, OnDestroy {
  @ViewChild(ElementRef, {static: true}) reportHeaderCard: ElementRef;
  public selectedThirdParties = new FormControl(['TcVJpdbCUWAZo5xK0y98'], Validators.required);
  public selectedLocations = new FormControl(['115700'], Validators.required);
  public startDate = new FormControl(moment('8/26/2019', 'M/D/YYYY').toDate(), Validators.required);
  public endDate = new FormControl(moment('8/30/2019', 'M/D/YYYY').toDate(), Validators.required);
  public reportData: any;
  public thirdParties: ThirdParty[] = [];
  public locations: Location[] = [];
  public reportAvailable: boolean;
  private _destroy$ = new Subject();
  private analyticsEngine = new ThirdPartyDeliveryAnalyticsEngine();
  private _client: Client;
  private _user: User;
  private allLocationsSelected: boolean;
  private allThirdPartiesSelected: boolean;
  private differenceThreshhold = .1;
  private pos: Pos[] = [];
  private thirdPartyReportId: string;
  private existingReport: ThirdPartyReport;
  private invalidExistingReport = false;
  private reportName = new FormControl('', Validators.required);
  editingName = false;
  private uiState$: UiState;

  constructor(private store: Store<any>,
              private papa: Papa,
              private dialog: MatDialog,
              private loadingService: LoadingDialogService,
              private snackBar: MatSnackBar,
              private afs: AngularFirestore,
              private activatedRoute: ActivatedRoute,
              private router: Router
  ) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this._destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this._user = uiState$.authUser;
          this._client = uiState$.client;
          this.uiState$ = uiState$;
          this.getFilterableResources();
          this.listenToMultiSelects();
          this.activatedRoute.params.subscribe(params$ => {
            this.thirdPartyReportId = params$['id'];
            if (this.thirdPartyReportId) {
              this.getExistingReport();
            }
          })
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  runReport() {
    if (this.startDate.valid && this.endDate.valid && this.selectedLocations.valid && this.selectedThirdParties.valid) {
      this.loadingService.isLoading(true, 'Running Report...');
      const start = moment(this.startDate.value).toDate();
      const end = moment(this.endDate.value).toDate();
      const selectedLocationIds = this.selectedLocations.value;
      const getPosTransactions = [];
      const get3pdTransactions = [];
      selectedLocationIds.forEach(locationId => {
        this.selectedThirdParties.value.forEach(thirdPartyId => {
          getPosTransactions.push(this.afs.collection('posTransactions', ref => ref
            .where('date', '>=', start)
            .where('date', '<=', end)
            .where('account', '==', thirdPartyId)
            .where('location', '==', locationId)
            .where('client', '==', this._client.id))
            .snapshotChanges()
          );
          get3pdTransactions.push(
            this.afs.collection('thirdPartyTransactions', ref => ref
              .where('date', '>=', start)
              .where('date', '<=', end)
              .where('location', '==', locationId)
              .where('account', '==', thirdPartyId)
              .where('client', '==', this._client.id))
              .snapshotChanges()
          );
        });
      });
      combineLatest([
        from(getPosTransactions).pipe(combineAll()),
        from(get3pdTransactions).pipe(combineAll())
      ]).pipe(takeUntil(this._destroy$))
        .subscribe(([posTransactions$, thirdPartyTransactions$]) => {
          const posTransactions = FirestoreUtilities.mergeCollectionToType(posTransactions$);
          const thirdPartyTransactions = FirestoreUtilities.mergeCollectionToType(thirdPartyTransactions$);
          const reportLocations = this.locations.filter(location => this.selectedLocations.value.find(locationId => locationId === location.locationId));
          const reportThirdParties = this.thirdParties.filter(thirdParty => this.selectedThirdParties.value.find(tpId => tpId === thirdParty.id));
          const reportThirdPartyLocations = reportThirdParties.reduce((rows: LocationReport[], thirdParty: ThirdParty) => {
            const thirdPartyLocationReports: LocationReport[] = [];
            reportLocations.forEach(location => {
              const locationReport = Object.assign(new LocationReport(), location);
              locationReport.posTransactions = posTransactions
                .filter(posTransaction => {
                  return posTransaction.location === location.locationId && posTransaction.account === thirdParty.id;
                });
              locationReport.thirdPartyTransactions = thirdPartyTransactions
                .filter(thirdPartyTransaction => {
                  return thirdPartyTransaction.location === location.locationId && thirdPartyTransaction.account === thirdParty.id;
                });
              locationReport.thirdParty = thirdParty.id;
              thirdPartyLocationReports.push(locationReport);
            });
            return [...rows, ...thirdPartyLocationReports]
          }, []);
          this.analyticsEngine.setLocationReportData(reportThirdPartyLocations);
          this.reportData = reportThirdPartyLocations;
          this.reportAvailable = true;
          this.loadingService.isLoading(false);
        }, (e) => {
          this.snackBar.open('Error running report. Please validate your selection and try again.', 'Dismiss', {
            duration: 5000
          });
          this.loadingService.isLoading(false)
        })
    } else {
      this.snackBar.open('Please select one or more locations, one or more third parties, a start date, and an end date', 'Dismiss', {
        duration: 5000
      })
    }
  }

  private getFilterableResources() {
    this.getLocations();
    combineLatest([
      this.afs.collection('thirdParties').snapshotChanges(),
      this.afs.collection('pos').snapshotChanges()
    ])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([thirdParties$, pos$]) => {
        this.thirdParties = FirestoreUtilities.mapToType(thirdParties$) as ThirdParty[];
        this.pos = FirestoreUtilities.mapToType(pos$) as Pos[];
      });
  }

  private getLocations() {
    FirestoreUtilities.getUserAccessibleResourcesOfType('locations', this.afs, this.uiState$.locations, [UserRoles.admin])
      .subscribe(locations$ => this.locations = locations$);
  }

  selectAllLocations() {
    if (!this.allLocationsSelected) {
      this.allLocationsSelected = true;
      this.selectedLocations.patchValue([0, ...this.locations.map(l => l.locationId)])
    } else {
      this.allLocationsSelected = false;
      this.selectedLocations.patchValue([]);
    }
    this.selectedLocations.updateValueAndValidity()
  }

  selectAllThirdParties() {
    if (!this.allThirdPartiesSelected) {
      this.allThirdPartiesSelected = true;
      this.selectedThirdParties.patchValue([0, ...this.thirdParties.map(tp => tp.id)])
    } else {
      this.allThirdPartiesSelected = false;
      this.selectedThirdParties.patchValue([]);
    }
    this.selectedThirdParties.updateValueAndValidity()
  }

  private listenToMultiSelects() {
    this.selectedLocations.valueChanges.subscribe(selection => {
      // if (this.allLocationsSelected && selection[0] === 0) {
      //     this.allLocationsSelected = false;
      //     this.selectedLocations.patchValue(selection.splice(0, 1));
      //     this.selectedLocations.updateValueAndValidity();
      //   }
    });
  }

  generateReport(reportType: 'location' | 'tax' | 'transaction' | 'remittance') {
    switch (reportType) {
      case "location":
        this.createLocationReportFile();
        break;
      case "remittance":
        this.createRemittanceReportFile();
        break;
      case "tax":
        this.createTaxReportFile();
        break;
      case "transaction":
        this.createTransactionFile();
        break;
    }
  }

  async saveReport() {
    if (this.existingReport && this.existingReport.id) {
      this.updateReport();
    } else {
      this.createReport();
    }
  }

  private async updateReport() {
    if (this.selectedThirdParties.valid &&
      this.selectedLocations.valid && this.startDate.valid && this.endDate.valid && this.reportName.valid) {
      await this.afs.doc(`thirdPartyReports/${this.existingReport.id}`)
        .update({
          name: this.reportName.value,
          locations: this.selectedLocations.value,
          thirdParties: this.selectedThirdParties.value,
          startDate: moment(this.startDate.value).toDate(),
          endDate: moment(this.endDate.value).toDate()
        });
      this.snackBar.open('Report updated successfully.', 'Dismiss', {
        duration: 5000
      });
    }
  }

  private async createReport() {
    const nameEnterDialog = this.dialog.open(ReasonPromptDialogComponent, {
      panelClass: 'invisible-panel-dialog',
      data: {
        placeholder: 'Report Name',
        title: 'Enter a Name for the Report',
        reason: 'name'
      }
    });
    nameEnterDialog.afterClosed().subscribe(async (name$) => {
      if (name$) {
        const report = new ThirdPartyReport();
        report.id = this.afs.createId();
        report.name = name$;
        report.client = this._client.id;
        report.creator = this._user.id;
        report.locations = this.selectedLocations.value;
        report.thirdParties = this.selectedThirdParties.value;
        report.startDate = moment(this.startDate.value).toDate();
        report.endDate = moment(this.endDate.value).toDate();
        await this.afs.doc(`thirdPartyReports/${report.id}`).set(report.toJSONObject());
        this.snackBar.open('Report saved successfully.', 'Dismiss', {
          duration: 5000
        });
      }
    });
  }

  getThirdPartyName(thirdPartyId: string) {
    const tp = this.thirdParties.find(_tp => _tp.id === thirdPartyId);
    return tp ? tp.name : '';
  }

  getLocationName(locationId: string) {
    const location = this.locations.find(l => l.locationId === locationId);
    return location ? location.name : ''
  }

  private createLocationReportFile() {

  }

  private createRemittanceReportFile() {

  }

  private createTaxReportFile() {

  }

  private createTransactionFile() {

  }

  private getExistingReport() {
    this.afs.doc(`thirdPartyReports/${this.thirdPartyReportId}`)
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(report$ => {
        this.existingReport = <ThirdPartyReport>FirestoreUtilities.objectToType(report$);
        if (this.existingReport) {
          this.selectedLocations.patchValue(this.existingReport.locations);
          this.selectedLocations.updateValueAndValidity();
          this.selectedThirdParties.patchValue(this.existingReport.thirdParties);
          this.selectedThirdParties.updateValueAndValidity();
          this.reportName.patchValue(this.existingReport.name);
          this.reportName.updateValueAndValidity();
          this.startDate.patchValue(this.existingReport.startDate.toDate());
          this.startDate.updateValueAndValidity();
          this.endDate.patchValue(this.existingReport.endDate.toDate());
          this.endDate.updateValueAndValidity();
          // this.runReport();
        } else {
          this.invalidExistingReport = true;
        }
      });
  }

  isUnsavedChanges() {
    if (this.existingReport) {
      if (this.existingReport.thirdParties === this.selectedThirdParties.value &&
        this.existingReport.locations === this.selectedLocations.value &&
        this.existingReport.name === this.reportName.value &&
        moment(this.existingReport.startDate.toDate()).format('M/D/YYYY') === moment(this.startDate.value).format('M/D/YYYY') &&
        moment(this.existingReport.endDate.toDate()).format('M/D/YYYY') === moment(this.endDate.value).format('M/D/YYYY')) {
        return false;
      }
    }
    return true;
  }
}
