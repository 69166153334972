import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {AngularFireModule} from 'angularfire2';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {ROUTES} from './app.routing';
import {MalihuScrollbarModule, MalihuScrollbarService} from 'ngx-malihu-scrollbar';
import {AdminLayoutModule} from './views/admin-layout/admin-layout.module';
import {LoadingDialogComponent} from "./dialogs/loading-dialog/loading-dialog.component";
import {FlexLayoutModule} from '@angular/flex-layout';
import {HttpClientModule} from "@angular/common/http";
import {FaqModule} from "./components/faq/faq.module";
import {Location, LocationStrategy, PathLocationStrategy} from "@angular/common";
import * as Hammer from 'hammerjs';
import {HammerGestureConfig, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {HelpModule} from "./components/help/help.module";
import {ServiceWorkerModule} from "@angular/service-worker";
import {ServicesModule} from "./services/services.module";
import {AuthModule} from "./auth/auth.module";
import {DashboardModule} from "./components/dashboard/dashboard.module";
import {DsSharedModule} from "./shared/ds-shared.module";
import {NotFoundModule} from "./components/not-found/not-found.module";
import {FirebaseAuthService} from "./auth/services/firebase-auth.service";
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from "ngx-perfect-scrollbar";
import {ThirdPartyDeliveryReportsModule} from "./components/ds-modules/3pd-reports/3pd-reports.module";
import {AngularReduxDataLayerModule, AngularReduxDataModule, EntityReducer, getEntityActionStrings} from "angular-redux-data";
import {StoreDevtoolsModule, StoreDevtoolsOptions} from '@ngrx/store-devtools';
import {getArdConfig} from "./utilities/ard-config";
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {OrganizationModule} from "./components/organization/organization.module";
import {DirectivesModule} from "./directives/directives.module";
import {TermsModule} from "./components/terms/terms.module";
import {UserProfileModule} from "./components/organization/user-profile/user-profile.module";
import {DocumentUploadModule} from "./components/document-upload/document-upload.module";
import {ExemptionCertificateManagementModule} from "./components/ds-modules/exemption-certificate-management/exemption-certificate-management.module";
import {AuditTrailModule} from "./components/ds-modules/audit-trail/audit-trail.module";
import {GuardsModule} from "./guards/guards.module";
import {AngularFireStorageModule} from '@angular/fire/storage';
import {ScrollToModule} from "@nicky-lenaers/ngx-scroll-to";
import {NotificationPanelModule} from "./components/structural/notification-panel/notification-panel.module";

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // override hammerjs default configuration
    'swipe': {direction: Hammer.DIRECTION_ALL}
  }
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20
};
// @TODO PR AngularReduxData to provide this as exported constant
/**
 * Establish reducers dynamically based on the configurable entity namespaces
 * Angular issue: https://github.com/angular/angular/issues/14707
 */
export const dynamicEntityReducers: ActionReducerMap<any> = {};
getArdConfig().entityNameSpaces.forEach(nameSpace => {
  const newInstance = new EntityReducer(getEntityActionStrings(nameSpace), nameSpace);
  dynamicEntityReducers[nameSpace] = newInstance.reducer;
});

if (getArdConfig().customReducers && Object.keys(getArdConfig().customReducers).length > 0) {
  Object.keys(getArdConfig().customReducers).forEach(key => {
    dynamicEntityReducers[key] = getArdConfig().customReducers[key];
  });
}
const transactionInstance = new EntityReducer(getEntityActionStrings('ardTransaction'), 'ardTransaction');
dynamicEntityReducers['ardTransaction'] = transactionInstance.reducer;

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    /**
     * FireStore & Firebase
     */
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    /**
     * REDUX
     */
    AngularReduxDataModule.forRoot(getArdConfig()),
    AngularReduxDataLayerModule.forRoot(getArdConfig()),
    StoreModule.forRoot(dynamicEntityReducers),
    EffectsModule.forRoot(getArdConfig().effects),
    StoreDevtoolsModule.instrument(<StoreDevtoolsOptions>{maxAge: 25}),
    /**
     * Angular Base Modules
     */
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES, {useHash: false}),
    MalihuScrollbarModule.forRoot(),
    FlexLayoutModule,
    ScrollToModule.forRoot(),
    /**
     * Views
     */
    AdminLayoutModule,
    /**
     * Components
     */
    ThirdPartyDeliveryReportsModule,
    OrganizationModule,
    DashboardModule,
    FaqModule,
    HelpModule,
    NotFoundModule,
    TermsModule,
    UserProfileModule,
    DocumentUploadModule,
    ExemptionCertificateManagementModule,
    AuditTrailModule,
    NotificationPanelModule,
    /**
     * Application Specific
     */
    AuthModule,
    DirectivesModule,
    DsSharedModule,
    ServicesModule,
    GuardsModule,
    environment.production ? ServiceWorkerModule.register('ngsw-worker.js') : []
  ],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    MalihuScrollbarService,
    FirebaseAuthService
  ],
  entryComponents: [
    LoadingDialogComponent,
  ],
  bootstrap:
    [AppComponent]
})

export class AppModule {

}
