import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Papa} from 'ngx-papaparse';
import {AngularFirestore} from '@angular/fire/firestore';
import {FirestoreUtilities} from "../../../../utilities/firestore-utilities";
import {Router} from "@angular/router";
import {Location, Entity, Client, LocationThirdParty, ThirdParty, LocationThirdPartyRate, RateType, User, ClientRole, UserRoles} from "@deliver-sense-librarian/data-schema";
import {FormControl} from "@angular/forms";
import {MatDialog, MatSnackBar} from "@angular/material";
import {CreateEntityDialogComponent} from "../../../../dialogs/create-entity/create-entity.dialog.component";
import * as moment from 'moment';
import {Store} from '@ngrx/store';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {LoadingDialogService} from "../../../../services/loading-dialog.service";
import {bulkLocationUploadTemplate} from "../../../../shared/ds-constant";
import * as crypto from "crypto-js";
import {UiState} from "../../../../redux/custom-states/uiState/ui-state";

/** @Bookmark:
 * need to add client selection in ui
 * need to add create client dialog form
 * need to add firestore permissions
 * need to finish project and location creations
 **/
@Component({
  selector: 'app-upload-locations',
  templateUrl: './upload-locations.component.html',
  styleUrls: ['./upload-locations.component.scss']
})
export class UploadLocationsComponent implements OnInit, OnDestroy {
  @Output() locationsUploaded = new EventEmitter();
  public _user: User;
  public _client: Client;
  public selectedEntity = new FormControl();
  public entities: Entity[];
  public locationUploadTemplateLocation = bulkLocationUploadTemplate;
  private thirdParties: ThirdParty[] = [];
  private rateTypes: RateType[] = [];
  private _destroy$ = new Subject();
  private uiState: UiState;

  constructor(private store: Store<any>,
              private papa: Papa,
              private dialog: MatDialog,
              private loadingService: LoadingDialogService,
              private snackBar: MatSnackBar,
              private afs: AngularFirestore,
              private router: Router
  ) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this._destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this._user = uiState$.authUser;
          this._client = uiState$.client;
          this.uiState = uiState$ as UiState;
          this.fetchResources();
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  fetchResources() {
    this._fetchClientEntities();
    this._fetchThirdParties();
    this._fetchRateTypes();
  }

  async detectFiles(event) {
    if (event.target.files && event.target.files[0]) {
      const csvData = event.target.files[0];
      this.loadingService.isLoading(true, 'Uploading locations');
      this.papa.parse(csvData, {
        header: true,
        worker: true,
        complete: async (parsedData) => {
          if (this._isParsedDataValid(parsedData.data)) {
            const locationData = this.serializeCSVToLocationData(parsedData);
            const updateLocationRoles = locationData.map(location => {
              return this.afs.doc(`users/${this._user.id}/clientRoles/${this._client.id}/organizationRoles/${location.id}`)
                .set({
                  resource: location.id,
                  role: UserRoles.admin,
                  type: 'location'
                })
            });
            await Promise.all(updateLocationRoles);
            const locationPromises = locationData.map(data => {
              console.log(JSON.stringify(data.toJSONObject(), null, 2));
              return this.afs.doc(`locations/${data.id}`).set(data.toJSONObject());
            });
            try {
              await Promise.all(locationPromises);
              this.snackBar.open('Successfully saved locations', 'Dismiss', {
                duration: 5000
              });
              this.locationsUploaded.emit(true);
              await this.router.navigate(['/app/organization/locations']);
              this.loadingService.isLoading(false);
            } catch (e) {
              console.log('error: ' + e);
              this.snackBar.open('Error uploading locations', 'Dismiss', {
                duration: 5000
              });
              this.loadingService.isLoading(false);
            }
          } else {
            this.loadingService.isLoading(false);
            this.snackBar.open('You must have an id for every location.', 'Dismiss', {
              duration: 6000
            });
          }
        }
      });
    }
  }

  serializeCSVToLocationData(parsedData) {
    return parsedData.data.map(location$ => {
      const newLocation = new Location();
      newLocation.locationId = location$['Location Number'];
      newLocation.id = this.getLocationClientHash(newLocation.locationId); // @TODO need to swap out ??
      newLocation.name = location$['Location Name'];
      newLocation.entity = this.selectedEntity.value.id;
      newLocation.client = this._client.id;
      newLocation.primaryContact = location$['Primary Contact Email'] ? location$['Primary Contact Email'] : null;
      newLocation.cityTaxRate = location$['City Tax Rate'] ? location$['City Tax Rate'] : null;
      newLocation.countyTaxRate = location$['County Tax Rate'] ? location$['County Tax Rate'] : null;
      newLocation.stateTaxRate = location$['State Tax Rate'] ? location$['State Tax Rate'] : null;
      newLocation.specialTaxRate = location$['Special Tax Rate'] ? location$['Special Tax Rate'] : null;
      newLocation.addressLine1 = location$['Address 1'] ? location$['Address 1'] : null;
      newLocation.addressLine2 = location$['Address 2'] ? location$['Address 2'] : null;
      newLocation.addressCity = location$['City'] ? location$['City'] : null;
      newLocation.addressState = location$['State'] ? location$['State'] : null;
      newLocation.addressPostalCode = location$['Zip'] ? location$['Zip'] : null;
      this.getLocationThirdParties(newLocation, location$);
      this.getLocationThirdPartyRates(newLocation, location$);
      return newLocation;
    });
  }

  showCreateEntityForm() {
    this.dialog.open(CreateEntityDialogComponent, {
      data: {
        client: this._client.id
      }
    })
  }

  private _isParsedDataValid(data) {
    return true;
  }

  private _fetchClientEntities() {
    FirestoreUtilities
      .getUserAccessibleResourcesOfType(
        'entities',
        this.afs,
        this.uiState.entities,
        [UserRoles.admin, UserRoles.contributor]
      ).pipe(takeUntil(this._destroy$))
      .subscribe(entities => {
        this.entities = entities;
      });
  }

  private getLocationThirdParties(newLocation: Location, uploadedLocation$) {
    const locationThirdParties = this.thirdParties.map(thirdParty => {
      const ldp = new LocationThirdParty();
      if (uploadedLocation$[thirdParty.name]) {
        ldp.active = true;
        ldp.thirdParty = thirdParty.id;
        ldp.thirdPartyFee = uploadedLocation$[`${thirdParty.name} Fee`] ? uploadedLocation$[`${thirdParty.name} Fee`] : 0;
      } else {
        ldp.active = false;
        ldp.thirdParty = thirdParty.id;
        ldp.thirdPartyFee = 0;
        return ldp;
      }
      return ldp;
    }).filter(ldp => !!ldp);
    newLocation.thirdParties = locationThirdParties.map(ltp => ltp.toJSONObject());
  }

  private _fetchThirdParties() {
    this.afs.collection('thirdParties')
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(thirdParties$ => {
        this.thirdParties = FirestoreUtilities.mapToType(thirdParties$);
      })
  }

  private getLocationThirdPartyRates(newLocation: Location, uploadedLocation$) {
    const locationThirdParties: LocationThirdParty[] = newLocation.thirdParties;
    locationThirdParties.forEach(ldp => {
      const foodNaBevLDPRate = new LocationThirdPartyRate();
      foodNaBevLDPRate.effectiveDate = moment().toDate();
      foodNaBevLDPRate.rate = ldp.active ? this._getTotalTaxRate(uploadedLocation$) : 0;
      foodNaBevLDPRate.rateType = this.rateTypes.find(rateType => rateType.name === 'Food & N/A Bev').id;
      const nonTaxableLDPRate = new LocationThirdPartyRate();
      nonTaxableLDPRate.effectiveDate = moment().toDate();
      nonTaxableLDPRate.rate = 0;
      nonTaxableLDPRate.rateType = this.rateTypes.find(rateType => rateType.name === 'Non-Taxable').id;
      ldp.rates = [foodNaBevLDPRate.toJSONObject(), nonTaxableLDPRate.toJSONObject()];
    });
  }

  private _fetchRateTypes() {
    this.afs.collection('rateTypes')
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(rateType$ => {
        this.rateTypes = FirestoreUtilities.mapToType(rateType$).sort((a, b) => {
          return a < b ? -1 : 1;
        });
      })
  }


  private _getTotalTaxRate(location$: Location) {
    const cityRate = +location$['City Tax Rate'] ? +location$['City Tax Rate'] : 0;
    const countyRate = +location$['County Tax Rate'] ? +location$['County Tax Rate'] : 0;
    const stateRate = +location$['State Tax Rate'] ? +location$['State Tax Rate'] : 0;
    const specialRate = +location$['Special Tax Rate'] ? +location$['Special Tax Rate'] : 0;
    return cityRate + countyRate + stateRate + specialRate;
  }

  private getLocationClientHash(locationId) {
    const digest = crypto.algo.MD5.create();
    digest.update(locationId);
    digest.update(this._client.id);
    const progressiveHash = digest.finalize();
    return progressiveHash.toString(crypto.enc.Hex);
  }
}
