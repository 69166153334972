import {Action} from '@ngrx/store';
/**
 * Application State Changes
 */
export const OPEN_ACTION_PANE_ACTION = 'OPEN_ACTION_PANE_ACTION';
export const CLOSE_ACTION_PANE_ACTION = 'CLOSE_ACTION_PANE_ACTION';
export const SET_ACTION_PANE_CONTENT_TYPE_ACTION = 'SET_ACTION_PANE_CONTENT_TYPE_ACTION';

export class OpenActionPaneAction implements Action {
    readonly type = OPEN_ACTION_PANE_ACTION;
    constructor(public payload?: number) {
    }
}

export class CloseActionPaneAction implements Action {
    readonly type = CLOSE_ACTION_PANE_ACTION;
    constructor(public payload?: number) {
    }
}

export class SetActionPaneContentTypeAction implements Action {
    readonly type = SET_ACTION_PANE_CONTENT_TYPE_ACTION;
    constructor(public payload?: number) {
    }
}


