import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';

import * as screenfull from 'screenfull';
import {FirebaseAuthService} from "../../../auth/services/firebase-auth.service";
import {Store} from '@ngrx/store';
import {Client, User, UserNotification} from "@deliver-sense-librarian/data-schema";
import {FirestoreUtilities} from "../../../utilities/firestore-utilities";
import {FormControl} from "@angular/forms";
import {AngularFirestore} from '@angular/fire/firestore';
import {from, Subject} from "rxjs";
import {combineAll, take, takeUntil} from "rxjs/operators";
import {MatSelectChange} from "@angular/material/select";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../../dialogs/confirm-dialog/confirm-dialog.component";
import {SetAccountClientAction} from "../../../redux/custom-states/uiState/ui-state-actions";
import {LoadingDialogService} from "../../../services/loading-dialog.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output()
  toggleSidenav = new EventEmitter<void>();
  @Output()
  toggleNotificationSidenav = new EventEmitter<void>();
  public user: User;
  public client: Client;
  public selectedClient = new FormControl();
  public availableClients: Client[] = [];
  public userNotifications: UserNotification[] = [];
  private destroy$ = new Subject();

  constructor(private auth: FirebaseAuthService,
              private afs: AngularFirestore,
              private dialog: MatDialog,
              private loadingService: LoadingDialogService,
              private store: Store<any>) {
  }

  ngOnInit(): void {
    this.store.select(state => state.uiState).subscribe(uiState$ => {
      if (uiState$.authUser && uiState$.client) {
        this.user = uiState$.authUser;
        this.client = uiState$.client;
        this.selectedClient.patchValue(this.client.id);
        this.getAvailableClients();
        this.getNotifications();
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  fullScreenToggle(): void {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
  }

  logout() {
    this.auth.signOut();
  }

  public getClient(clientId) {
    const clientMatch = this.availableClients.find(_client => _client.id === clientId);
    return clientMatch;
  }

  private getAvailableClients() {
    this.afs.collection(`users/${this.user.id}/clientRoles`)
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(clientRoles$ => {
        const clientRoles = FirestoreUtilities.mapToType(clientRoles$);
        const clientRequests = clientRoles.map(clientRole => {
          if (clientRole.role) {
            return this.afs.doc(`clients/${clientRole.resource}`).snapshotChanges()
          }
        }).filter(req => !!req);
        if (clientRequests.length > 0) {
          from(clientRequests)
            .pipe(combineAll(), takeUntil(this.destroy$))
            .subscribe(clients$ => {
              this.availableClients = <Client[]>FirestoreUtilities.mergeToType(clients$);
            })
        }
      });
  }

  changeSelectedClient($event: MatSelectChange) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Client Selection Change',
        message: 'Are you sure you want to change your client selection?',
        action: 'Yes.'
      }
    });
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        const selectedClient = this.availableClients.find(client => client.id === this.selectedClient.value);
        if (selectedClient && selectedClient.id !== this.client.id) {
          this.store.dispatch(new SetAccountClientAction(selectedClient));
          this.loadingService.isLoading(true, 'Changing client...');
          setTimeout(() => {
            this.loadingService.isLoading(false);
            window.location.reload();
          })
        }
      }
    });
  }

  private getNotifications() {
    this.afs.collection('userNotifications', ref => ref
      .where('toUser', '==', this.user.id)
      .where('client', '==', this.client.id))
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(userNotifications$ => {
        this.userNotifications = <UserNotification[]>FirestoreUtilities.mapToType(userNotifications$);
      })
  }

  countUnreadNotifications() {
    return this.userNotifications.filter(notification => !notification.read).length;
  }
}
