import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
    selector: 'app-loading-dialog',
    templateUrl: './loading-dialog.component.html',
    styleUrls: ['./loading-dialog.component.scss']
})
export class LoadingDialogComponent implements OnInit {
    public signup = false;
    public text: string;
    public determinateValue: any;
    public mode;

    constructor(public dialogRef: MatDialogRef<LoadingDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data) {
            this.signup = data.signup;
            this.determinateValue = data.determinateValue;
            this.mode = data.determinateValue ? 'determinate' : 'indeterminate';
            this.text = data.text;
        }
    }

    ngOnInit() {
    }
}
