import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Client, AuditTrailDocument, AuditTrailDocumentTab, User, Project, UserRoles} from "@deliver-sense-librarian/data-schema";
import {FirebaseAuthService} from "../../../../auth/services/firebase-auth.service";
import {Papa} from "ngx-papaparse";
import {FirebaseApp} from "@angular/fire";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AngularFirestore} from "@angular/fire/firestore";
import * as JSZip from "jszip";
import {Store} from '@ngrx/store';
import {map, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {FormControl, Validators} from "@angular/forms";
import {FirestoreUtilities} from "../../../../utilities/firestore-utilities";
import {UiState} from "../../../../redux/custom-states/uiState/ui-state";

@Component({
  selector: 'app-upload-review-document',
  templateUrl: './upload-review-document.component.html',
  styleUrls: ['./upload-review-document.component.scss']
})
export class UploadReviewDocumentComponent implements OnInit {

  private zipFile: any;
  private tabNames: string[] = [];
  private _user: User;
  private _fileName: string;
  private _client: Client;
  private _destroy$ = new Subject();
  private selectedProject = new FormControl('', Validators.required);
  public projects: Project[];
  public activated = false;
  private uiState: UiState;

  constructor(private store: Store<any>,
              private papa: Papa,
              private firebaseApp: FirebaseApp,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private afs: AngularFirestore,
              private _cdr: ChangeDetectorRef) {
    this.zipFile = new JSZip();
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this._destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this._user = uiState$.authUser;
          this._client = uiState$.client;
          this.uiState = uiState$;
          this.getAvailableProjects();
        }
      });
  }

  async handleFileSelect(evt) {
    const files = evt.target.files;
    const file = files[0];
    this._fileName = file.name.substr(0, file.name.lastIndexOf('.'));
    const lastDot = file.name.lastIndexOf('.');
    const ext = file.name.substring(lastDot + 1);
    if (files && file && (ext === 'xlsx' || ext === 'xlsm')) {
      const reader = new FileReader();

      reader.onload = (readerEvt) => {
        const binaryString = readerEvt.target['result'];
        this._getTabs(btoa(binaryString));
      };
      reader.readAsBinaryString(file);
    } else {
      this.snackBar.open('Invalid file. Document parsing is only available for excel files at this time.', 'Dismiss', {
        duration: 7000
      })
    }
  }

  /**
   *
   * @param base64file
   */
  private async _getTabs(base64file) {
    let s, i, id;
    const zip = new JSZip();
    const zip$ = await zip.loadAsync(base64file, {
      base64: true
    });
    const t = zip$.file('xl/workbook.xml');
    if (!!t) {
      const data = await t.async('string');
      s = data;
      s = s.split('<sheet ');
      i = s.length;
      while (--i) {
        id = s[i].substr(s[i].indexOf('name="') + 6);
        this.tabNames.push(id.substring(0, id.indexOf('"')));
      }
      if (this.tabNames.length > 0 && this._fileName && this.selectedProject.valid) {
        this._createNewAuditTrailDocument();
      }
    }
  };

  private async _createNewAuditTrailDocument() {
    const porDocument = new AuditTrailDocument();
    porDocument.id = this.afs.createId();
    porDocument.project = this.selectedProject.value;
    porDocument.creator = this._user.id;
    porDocument.name = this._fileName;
    const tabsCreationRequests = this.tabNames.map(tabName => {
      const porDocumentTab = new AuditTrailDocumentTab();
      porDocumentTab.auditTrailDocument = porDocument.id;
      porDocumentTab.name = tabName;
      return this.afs.collection('auditTrailDocumentTabs').add(porDocumentTab.toJSONObject());
    });
    try {
      await this.afs.doc(`auditTrailDocuments/${porDocument.id}`).set(porDocument.toJSONObject());
      await Promise.all(tabsCreationRequests);
      this.snackBar.open('New proof of review document created successfully', 'Dimsiss', {
        duration: 5000
      });
      this.selectedProject.reset();
      this.activated = false;
    } catch (e) {
      this.snackBar.open('Oops... something went wrong please try again.', 'Dismiss', {
        duration: 5000
      });
    }
  }

  private getAvailableProjects() {
    FirestoreUtilities.getUserAccessibleResourcesOfType('projects', this.afs, this.uiState.projects, [UserRoles.admin, UserRoles.contributor])
      .pipe(takeUntil(this._destroy$))
      .subscribe(projects$ => {
        this.projects = (projects$) as Project[];
      })
  }
}
