import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CreateReportComponent} from './create-report/create-report.component';
import {ReportsListComponent} from "./reports-list/reports-list.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MaterialModule} from "../../../material.module";
import { TransactionDrillDownComponent } from './transaction-drill-down/transaction-drill-down.component';
import { TaxDrillDownComponent } from './tax-drill-down/tax-drill-down.component';
import { RemittanceDrillDownComponent } from './remittance-drill-down/remittance-drill-down.component';
import {MalihuScrollbarModule} from "ngx-malihu-scrollbar";
import {DsSharedModule} from "../../../shared/ds-shared.module";
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [
    ReportsListComponent,
    CreateReportComponent,
    TransactionDrillDownComponent,
    TaxDrillDownComponent,
    RemittanceDrillDownComponent
  ],
  exports: [
    TransactionDrillDownComponent,
    TaxDrillDownComponent,
    RemittanceDrillDownComponent
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MaterialModule,
    CommonModule,
    MalihuScrollbarModule.forRoot(),
    DsSharedModule,
    RouterModule
  ]
})
export class ThirdPartyDeliveryReportsModule {
}
