import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {AngularFirestore} from '@angular/fire/firestore';
import {Store} from '@ngrx/store';
import {User, Client, UserNotification} from "@deliver-sense-librarian/data-schema";

@Injectable()
export class CreateNotificationService {
  private tip = new BehaviorSubject(null);
  private _client: Client;
  private _user: User;

  constructor(private afs: AngularFirestore,
              private router: Router,
              private store: Store<any>,
              private activatedRouter: ActivatedRoute) {
    store.select(state => state.uiState).subscribe(uiState$ => {
      if (uiState$.authUser && uiState$.client) {
        this._user = uiState$.authUser;
        this._client = uiState$.client;
      }
    })
  }

  createNotification(message: string, toUserId) {
    const notification = new UserNotification();
    notification.read = false;
    notification.client = this._client.id;
    notification.message = message;
    notification.route = this.router.url;
    notification.fromUser = this._user.id;
    notification.toUser = toUserId;
    return this.afs.collection('userNotifications').add(notification.toJSONObject());
  }
}
