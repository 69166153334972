import {Component, OnDestroy, OnInit} from '@angular/core';
import {FirebaseAuthService} from "../../../auth/services/firebase-auth.service";
import {Store} from "@ngrx/store";
import {Papa} from "ngx-papaparse";
import {ActivatedRoute} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AngularFirestore} from "@angular/fire/firestore";
import {User} from "@deliver-sense-librarian/data-schema";
import {Subject} from "rxjs";
import {CustomValidators} from "ng2-validation";
import {FirestoreUtilities} from "../../../utilities/firestore-utilities";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject();
  public user: User;
  public client: User;
  public userForm: FormGroup;
  public storagePath: string;

  constructor(private auth: FirebaseAuthService,
              private store: Store<any>,
              private papa: Papa,
              private activedRoute: ActivatedRoute,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.storagePath = `users/${this.user.id}/picture/`;
          this.setupUserForm();
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  private setupUserForm() {
    this.userForm = this.fb.group({
      firstName: new FormControl(this.user.firstName, Validators.required),
      lastName: new FormControl(this.user.lastName, Validators.required),
    })
  }

  saveUserForm() {
    if (this.userForm.valid) {
      const formValue = this.userForm.value;
      this.afs.doc(`users/${this.user.id}`).update({
        firstName: formValue.firstName,
        lastName: formValue.lastName
      });
      this.snackBar.open('Successfully updated your profile.', 'Dismiss', {
        duration: 5000
      })
    } else {
      this.snackBar.open('Please complete all required fields', 'Dismiss', {
        duration: 5000
      });
    }
  }

  async resetPassword() {
    await this.auth.resetPassword(this.user.email);
    this.snackBar.open(`An email link to reset your password has been sent to ${this.user.email}`, 'Dismiss', {
      duration: 5000
    })
  }

  async pictureUploaded($event) {
    if ($event.url) {
      if (this.user.picture && this.user.picturePath) {
        try {
          await FirestoreUtilities.deleteImage(this.user.picturePath);
        } catch (e) {
          this.snackBar.open('Oops... something went wrong. Please refresh the page and try again.', 'Dismiss', {
            duration: 5000
          })
        }
      }
      await this.afs.doc(`users/${this.user.id}`)
        .update({
          picture: $event.url,
          picturePath: $event.path
        });
      this.snackBar.open('Logo updated successfully!', 'Dismiss', {
        duration: 5000
      })
    }
  }
}
