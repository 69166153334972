import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {FirebaseAuthService} from "../services/firebase-auth.service";
import {AngularFirestore} from '@angular/fire/firestore';
import {MatSnackBar} from "@angular/material";
import {CustomValidators} from "ng2-validation";
import {Client, ClientRole, User, UserRoles, usStates} from "@deliver-sense-librarian/data-schema";
import {LoadingDialogService} from "../../services/loading-dialog.service";
import {verifyPasswordMatch} from "../../shared/validators";
import {take, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, AfterViewInit {

  signupFormGroup: FormGroup;
  clientInfoFormGroup: FormGroup;
  submitted = false;
  error = '';
  loading = false;
  showUserForm = true;
  private customTokenSet = new Subject();
  states = usStates;

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private fbAuth: FirebaseAuthService,
              private afs: AngularFirestore,
              private router: Router,
              private loadingService: LoadingDialogService,
              public snackBar: MatSnackBar) {
  }


  ngOnInit() {
    this.signupFormGroup = this.fb.group({
      email: new FormControl(null, [Validators.required, CustomValidators.email]),
      password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.required]),
      phoneNumber: new FormControl(null, []),
      confirmPassword: new FormControl(null, Validators.required),
    }, {validator: verifyPasswordMatch});
    this.clientInfoFormGroup = this.fb.group({
      name: new FormControl(null, Validators.required),
      addressLine1: new FormControl(null, Validators.required),
      addressLine2: new FormControl(null),
      city: new FormControl(null, Validators.required),
      stateProvince: new FormControl(null, Validators.required),
      postalCode: new FormControl(null, Validators.required),
      billingEmail: new FormControl(null, [Validators.required, CustomValidators.email]),
      agreeToTerms: new FormControl(null, Validators.required),
      recaptchaReactive: new FormControl(null, Validators.required),
    });
  }

  ngAfterViewInit() {
    document.body.classList.add('authentication-bg');
  }

// convenience getter for easy access to form fields
  get f() {
    return this.signupFormGroup.controls;
  }

  /**
   * On submit form
   */
  async onSubmit() {
    this.submitted = true;
    this.loading = true;
    this.markFields(this.signupFormGroup);
    this.markFields(this.clientInfoFormGroup);
    if (this.signupFormGroup.valid && this.clientInfoFormGroup.valid) {
      const clientId = this.afs.createId();
      const password = this.signupFormGroup.value.password;
      const user = this._mapFormToUser(this.signupFormGroup, clientId);
      const client = this._mapFormToClient(this.clientInfoFormGroup, clientId);
      try {
        const user$ = await this.fbAuth.register(user, password, client);
        this.snackBar.open('Registration Successful!', 'Dismiss', {
          duration: 5000
        });
        await this.router.navigate(['client-selection']);
      } catch (e) {
        this.snackBar.open('Error creating your account. Please refresh and try again.', 'Dismiss', {
          duration: 5000
        });
      }
      // this.loadingService.isSignupLoading(true);
      // const credential = await this.fbAuth.emailRegistrationTemplate(user, password, client);
      // this.fbAuth.getCurrentToken().pipe(takeUntil(this.customTokenSet)).subscribe(token => {
      //   this.fbAuth.getCustomToken(token).pipe(takeUntil(this.customTokenSet)).subscribe(customToken => {
      //     this.customTokenSet.next(customToken);
      //     this.fbAuth.setAuthUserState(credential.user.uid).pipe(take(1)).subscribe((user$) => {
      //       this.router.navigate(['client-selection']).then(() => {
      //         this.snackBar.open('Registration Successful!', 'Dismiss', {
      //           duration: 5000
      //         });
      //         this.loadingService.isSignupLoading(false);
      //       });
      //     });
      //   });
      // });
    } else {
      this.snackBar.open('Please fill out the required fields.', 'Dismiss', {
        duration: 5000
      })
    }
  }

  markFields(form) {
    Object.keys(form.controls).forEach(field => {
      form.get(field).markAsTouched({onlySelf: true});
    });
  }

  private _mapFormToUser(signupFormGroup: FormGroup, clientId) {
    const formValues = signupFormGroup.value;
    const user = new User();
    user.firstName = formValues.firstName;
    user.lastName = formValues.lastName;
    user.email = formValues.email;
    return user
  }

  private _mapFormToClient(clientInfoFormGroup: FormGroup, clientId) {
    const formValues = clientInfoFormGroup.value;
    const client = new Client();
    client.id = clientId;
    client.name = formValues.name;
    client.addressLine1 = formValues.addressLine1;
    client.addressLine2 = formValues.addressLine2;
    client.addressCity = formValues.city;
    client.addressState = formValues.stateProvince;
    client.addressPostalCode = formValues.postalCode;
    client.billingEmail = formValues.billingEmail;
    return client;
  }
}
