import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExemptionCertificateManagementComponent} from './exemption-certificate-management.component';
import {MaterialModule} from "../../../material.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DocumentUploadModule} from "../../document-upload/document-upload.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

@NgModule({
    declarations: [ExemptionCertificateManagementComponent],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        FormsModule,
        DocumentUploadModule,
    ]
})
export class ExemptionCertificateManagementModule {
}
