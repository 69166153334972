import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {AuditTrailDocument, AuditTrailDocumentTab, User, Client, UserRoles, Project} from "@deliver-sense-librarian/data-schema";
import {from, Subject} from "rxjs";
import {Form, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {FirebaseAuthService} from "../../../../auth/services/firebase-auth.service";
import {Papa} from "ngx-papaparse";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AngularFirestore} from "@angular/fire/firestore";
import {combineAll, map, takeUntil} from "rxjs/operators";
import {FirestoreUtilities} from "../../../../utilities/firestore-utilities";
import moment = require("moment");
import {ConfirmDialogComponent} from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import { Store } from '@ngrx/store';
import {UiState} from "../../../../redux/custom-states/uiState/ui-state";

@Component({
  selector: 'app-create-review-document',
  templateUrl: './create-audit-trail-document.component.html',
  styleUrls: ['./create-audit-trail-document.component.scss']
})
export class CreateAuditTrailDocumentComponent implements OnInit, OnDestroy {
  @Output() complete = new EventEmitter();
  private _user: User;
  private _destroy$ = new Subject();
  public reviewDocumentForm: FormGroup;
  public reviewDocument = new AuditTrailDocument();
  public projects: Project[];
  creatingTabComment = false;
  private _client: Client;
  public uiState: UiState;
  constructor(private store: Store<any>,
              private papa: Papa,
              private dialog: MatDialog,
              private activedRoute: ActivatedRoute,
              private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this._destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this._user = uiState$.authUser;
          this._client = uiState$.client;
          this.uiState = uiState$;
          this._getAvailableProjects();
            this._setupReviewDocumentForm()
      }
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  private _setupReviewDocumentForm() {
    this.reviewDocumentForm = this.fb.group({
      name: new FormControl(this.reviewDocument.name, Validators.required),
      description: new FormControl(this.reviewDocument.description),
      project: new FormControl(this.reviewDocument.project, Validators.required),
      tabs: new FormArray([])
    })
  }
  get tabsControl() {
    return this.reviewDocumentForm.get('tabs') as FormArray;
  }
  public addTab() {
    const tabsControl = this.tabsControl;
    tabsControl.push(new FormGroup({
      name: new FormControl('', Validators.required)
    }));
  }
  public removeTab(i) {
    const tabsControl = this.tabsControl;
    tabsControl.removeAt(i);
  }
  public async saveDocument() {
    if (this.reviewDocumentForm.valid) {
      if (this.reviewDocument.id) {
        this._updateReviewDocument();
      } else {
        this._createReviewDocument();
      }
      this.snackBar.open(`Successfully ${this.reviewDocument.id ? 'updated' : 'created'} review document.`, 'Dismiss', {
        duration: 5000
      });
      this.complete.emit(true);
    } else {
      this.snackBar.open('Please fill out all required fields', 'Dismiss', {
        duration: 5000
      });
    }
  }

  private async _updateReviewDocument() {
    const form = this.reviewDocumentForm.value;
    return await this.afs.doc(`auditTrailDocuments/${this.reviewDocument.id}`)
      .update({
        name: form.name,
        description: form.description,
        dateUpdated: moment().toDate()
      });
  }

  private async _createReviewDocument() {
    const form = this.reviewDocumentForm.value;
    const newReviewDoc = new AuditTrailDocument();
    newReviewDoc.description = form.description;
    newReviewDoc.creator = this._user.id;
    newReviewDoc.project = form.project;
    newReviewDoc.name = form.name;
    newReviewDoc.id = this.afs.createId();
    await this.afs.doc(`auditTrailDocuments/${newReviewDoc.id}`).set(newReviewDoc.toJSONObject());
    if (form.tabs && form.tabs.length > 0) {
      const tabCreationRequests = form.tabs.map(tab => {
        const newTab = new AuditTrailDocumentTab();
        newTab.name = tab.name;
        newTab.auditTrailDocument = newReviewDoc.id;
        return this.afs.collection('auditTrailDocumentTabs').add(newTab.toJSONObject());
      });
      return await Promise.all(tabCreationRequests);
    }
  }

  cancel() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Cancel',
        message: 'Are you sure you want to discard your changes?',
        action: 'Yes, cancel.'
      }
    });
    dialogRef.afterClosed().subscribe(discardChanges => {
      if (discardChanges) {
        this.complete.emit(false);
      }
    });
  }

  private _getAvailableProjects() {
    FirestoreUtilities.getUserAccessibleResourcesOfType('projects', this.afs, this.uiState.projects, [UserRoles.admin])
      .pipe((takeUntil(this._destroy$)))
      .subscribe(projects$ => {
        this.projects = projects$;
      })
  }
}
