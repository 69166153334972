import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EntitiesComponent} from './entities/entities/entities.component';
import {EntityComponent} from "./entities/entity/entity.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MaterialModule} from "../../material.module";
import {TeamMembersComponent} from "./team/team-members/team-members.component";
import {TeamMemberComponent} from "./team/team-member/team-member.component";
import {LocationsComponent} from "./locations/locations/locations.component";
import {LocationComponent} from "./locations/location/location.component";
import {UploadLocationsComponent} from "./locations/upload-locations/upload-locations.component";
import {DocumentUploadModule} from "../document-upload/document-upload.module";
import {DepartmentsComponent} from "./departments/departments/departments.component";
import {DepartmentComponent} from "./departments/department/department.component";
import {ProjectsComponent} from "./projects/projects/projects.component";
import {ProjectComponent} from "./projects/project/project.component";
import {MalihuScrollbarModule} from "ngx-malihu-scrollbar";
import {DsSharedModule} from "../../shared/ds-shared.module";
import {ClientComponent} from "./clients/client/client.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

@NgModule({
  declarations: [
    EntitiesComponent,
    EntityComponent,
    DepartmentsComponent,
    DepartmentComponent,
    ProjectsComponent,
    ProjectComponent,
    TeamMembersComponent,
    TeamMemberComponent,
    LocationsComponent,
    LocationComponent,
    UploadLocationsComponent,
  ],
  exports: [
    EntityComponent,
    DepartmentComponent,
    ProjectComponent,
    LocationComponent,
    UploadLocationsComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DocumentUploadModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    MaterialModule,
    MalihuScrollbarModule.forRoot(),
    DsSharedModule,
    FontAwesomeModule
  ]
})
export class OrganizationModule {
}
