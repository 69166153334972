import {AdminLayoutComponent} from './views/admin-layout/admin-layout.component';
import {Routes} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import {TermsComponent} from "./components/terms/terms/terms.component";
import {UnauthenticatedGuard} from "./guards/unauthenticated.guard";
import {FaqComponent} from "./components/faq/faq/faq.component";
import {FaqContentComponent} from "./components/faq/faq-content/faq-content.component";
import {LoginComponent} from "./auth/login/login.component";
import {PasswordresetComponent} from "./auth/passwordreset/passwordreset.component";
import {SignupComponent} from "./auth/signup/signup.component";
import {DashboardComponent} from "./components/dashboard/dashboard/dashboard.component";
import {PrivacyPolicyComponent} from "./components/terms/privacy-policy/privacy-policy.component";
import {NotFoundComponent} from "./components/not-found/not-found.component";
import {ReportsListComponent} from "./components/ds-modules/3pd-reports/reports-list/reports-list.component";
import {EntitiesComponent} from "./components/organization/entities/entities/entities.component";
import {LocationComponent} from "./components/organization/locations/location/location.component";
import {ClientComponent} from "./components/organization/clients/client/client.component";
import {CreateReportComponent} from "./components/ds-modules/3pd-reports/create-report/create-report.component";
import {UserProfileComponent} from "./components/organization/user-profile/user-profile.component";
import {TeamMembersComponent} from "./components/organization/team/team-members/team-members.component";
import {ExemptionCertificateManagementComponent} from "./components/ds-modules/exemption-certificate-management/exemption-certificate-management.component";
import {AuditTrailDocumentListComponent} from "./components/ds-modules/audit-trail/audit-trail-document-list/audit-trail-document-list.component";
import {AuditTrailDocumentComponent} from "./components/ds-modules/audit-trail/audit-trail-document/audit-trail-document.component";
import {ClientSelectorComponent} from "./auth/client-selector/client-selector.component";
import {ClientSelectedGuard} from "./guards/client-selected.guard";
import {TeamMemberSignupComponent} from "./auth/team-member-signup/team-member-signup.component";
import {DepartmentsComponent} from "./components/organization/departments/departments/departments.component";
import {ProjectsComponent} from "./components/organization/projects/projects/projects.component";
import {EntityComponent} from "./components/organization/entities/entity/entity.component";
import {DepartmentComponent} from "./components/organization/departments/department/department.component";
import {ProjectComponent} from "./components/organization/projects/project/project.component";
import {TeamMemberComponent} from "./components/organization/team/team-member/team-member.component";
import {LocationsComponent} from "./components/organization/locations/locations/locations.component";

export const ROUTES: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'invites/:invitationId', component: TeamMemberSignupComponent},
  {path: 'terms', component: TermsComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'faq', component: FaqComponent},
  {path: 'terms', component: TermsComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'sign-up', component: SignupComponent, canActivate: [UnauthenticatedGuard]},
  {path: 'forgot', component: PasswordresetComponent, canActivate: [UnauthenticatedGuard]},
  {path: 'login', component: LoginComponent, canActivate: [UnauthenticatedGuard]},
  {path: 'client-selection', component: ClientSelectorComponent, canActivate: [AuthGuard]},
  {
    path: 'app', canActivate: [AuthGuard, ClientSelectedGuard], component: AdminLayoutComponent, children: [
      {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
      {path: 'dashboard', component: DashboardComponent},
      {path: 'faq', component: FaqContentComponent},
      {
        path: 'organization', children: [
          {
            path: 'locations', children:
              [
                {path: '', component: LocationsComponent},
                {path: ':id', component: LocationComponent}
              ]
          },
          {
            path: 'entities', children: [
              {path: '', component: EntitiesComponent},
              {path: ':id', component: EntityComponent},
              {path: 'new', component: EntityComponent}
            ]
          },
          {
            path: 'departments', children: [
              {path: '', component: DepartmentsComponent},
              {path: ':id', component: DepartmentComponent},
              {path: 'new', component: DepartmentComponent}
            ]
          },
          {
            path: 'projects', children: [
              {path: '', component: ProjectsComponent},
              {path: ':id', component: ProjectComponent},
              {path: 'new', component: ProjectComponent}
            ]
          },
          {
            path: 'team', children: [
              {path: '', component: TeamMembersComponent},
              {path: ':id', component: TeamMemberComponent},
              {path: 'new', component: TeamMemberComponent}
            ]
          }
        ]
      },
      {
        path: 'client', component: ClientComponent
      },
      {
        path: '3pd-reports', children: [
          {path: '', redirectTo: 'list', pathMatch: 'full'},
          {path: 'list', component: ReportsListComponent},
          {path: 'new', component: CreateReportComponent},
          {path: ':id', component: CreateReportComponent}
        ]
      },
      {
        path: 'exemption-certificates', children: [
          {path: '', redirectTo: 'list', pathMatch: 'full'},
          {path: 'list', component: ExemptionCertificateManagementComponent}
        ]
      },
      {
        path: 'audit-trails', children: [
          {path: '', redirectTo: 'list', pathMatch: 'full'},
          {path: 'list', component: AuditTrailDocumentListComponent},
          {path: ':id', component: AuditTrailDocumentComponent}
        ]
      },
      {
        path: 'profile', component: UserProfileComponent
      },
      {
        path: '**', component: NotFoundComponent
      }
    ]
  }
];
