import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {CreateNotificationService} from "../../../services/create-notification.service";
import {Store} from '@ngrx/store';
import {Client, User, UserNotification, UserView} from "@deliver-sense-librarian/data-schema";
import {AngularFirestore} from '@angular/fire/firestore';
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {FirestoreUtilities} from "../../../utilities/firestore-utilities";
import {MatSnackBar} from "@angular/material/snack-bar";


@Component({
  selector: 'app-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss']
})
export class NotificationPanelComponent implements OnInit {
  public user: User;
  public client: Client;
  public userNotifications: UserNotification[];
  private destroy$ = new Subject();
  private userViews: UserView[] = [];

  constructor(private router: Router,
              private notificationService: CreateNotificationService,
              private store: Store<any>,
              private snackbar: MatSnackBar,
              private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.store.select(state => state.uiState)
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.getUserNotifications();
        }
      })
  }

  private getUserNotifications() {
    this.afs.collection('userNotifications', ref => ref
      .where('toUser', '==', this.user.id)
      .where('client', '==', this.client.id)
      .orderBy('dateCreated', 'desc'))
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(userNotifications$ => {
        this.userNotifications = FirestoreUtilities.mapToType(userNotifications$) as UserNotification[];
        FirestoreUtilities.fetchChildrenFromParentCollection(
          this.userNotifications,
          'userViews',
          'fromUser',
          this.afs,
          this.destroy$)
          .subscribe(userViews$ => {
            this.userViews = userViews$ as UserView[];
          })
      })
  }


  async goToNotification(notification: UserNotification) {
    await this.afs.doc(`userNotifications/${notification.id}`).update({read: true});
    if (notification.route) {
      await this.router.navigate([notification.route]);
    } else {
      this.snackbar.open('Oops... error viewing the notification. Please refresh and try again.', 'Dismiss', {
        duration: 5000
      })
    }
  }

  getNotificationUser(notification: UserNotification) {
    return this.userViews.find(userView => userView.id === notification.fromUser);
  }

  getNotificationUserName(notification: UserNotification) {
    const fromUser = this.userViews.find(userView => userView.id === notification.fromUser);
    return fromUser ? `${fromUser.firstName} ${fromUser.lastName}` : '';
  }
}
