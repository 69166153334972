import {Injectable} from '@angular/core';
import {FirebaseAuthService} from "app/auth/services/firebase-auth.service";
import {MatDialog} from "@angular/material";
import {AngularFirestore} from "@angular/fire/firestore";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {AngularFireAuth} from "@angular/fire/auth";
import {flatMap, map, take} from "rxjs/operators";

@Injectable()
export class StripeService {

    constructor(private http: HttpClient,
                public dialog: MatDialog,
                private afs: AngularFirestore,
                public auth: FirebaseAuthService,
                private afAuth: AngularFireAuth) {
    }

    private getToken(token): HttpHeaders {
        return new HttpHeaders().set('Authorization', `Bearer ${token}`);

    }

    public getStripeCustomer(customer) {
        return this.afAuth.idToken.pipe(take(1), map(token => token),
            flatMap(token => {
                const url = `${environment.stripeApiUrl}customers/${customer}`;
                const headerObj = {headers: this.getToken(token)};
                return this.http.get(url, headerObj).pipe(map(urlRes => {
                    return urlRes;
                }));
            }));
    }

    public createStripeSource(customer, source) {
        return this.afAuth.idToken.pipe(take(1), map(token => token),
            flatMap(token => {
                const url = `${environment.stripeApiUrl}stripeSource`;
                const headerObj = {headers: this.getToken(token)};
                return this.http.post(url, {customer: customer, source: source}, headerObj).pipe(map(urlRes => {
                    return urlRes;
                }));
            }));
    }

    public getStripeSource(source) {
        return this.afAuth.idToken.pipe(take(1), map(token => token),
            flatMap(token => {
                const url = `${environment.stripeApiUrl}sources/${source}`;
                const headerObj = {headers: this.getToken(token)};
                return this.http.get(url, headerObj).pipe(map(urlRes => {
                    return urlRes;
                }));
            }));
    }

    public updateStripeCard(customer, source, card, billingInfo: {
        name,
        address_line1,
        address_state,
        address_zip,
        address_city,
        country,
        exp_month,
        exp_year
    }) {
        return this.afAuth.idToken.pipe(take(1), map(token => token),
            flatMap(token => {
                const url = `${environment.stripeApiUrl}customers/${customer}/sources/${source}`;
                const headerObj = {headers: this.getToken(token)};
                return this.http.patch(url, {
                    "name": billingInfo.name,
                    "address_line1": billingInfo.address_line1,
                    "address_state": billingInfo.address_state,
                    "address_zip": billingInfo.address_zip,
                    "address_city": billingInfo.address_city,
                    "country": billingInfo.country,
                    "exp_month": billingInfo.exp_month,
                    "exp_year": billingInfo.exp_year
                }, headerObj).pipe(map(urlRes => {
                    return urlRes;
                }));
            }));
    }

    public removeSource(customer, source) {
        return this.afAuth.idToken.pipe(take(1), map(token => token),
            flatMap(token => {
                const url = `${environment.stripeApiUrl}customers/${customer}/sources/${source}`;
                const headerObj = {headers: this.getToken(token)};
                return this.http.delete(url, headerObj).pipe(map(urlRes => {
                    return urlRes;
                }));
            }));
    }

    public createStripeCustomer(email, source) {
        return this.afAuth.idToken.pipe(take(1), map(token => token),
            flatMap(token => {
                const url = `${environment.stripeApiUrl}stripeCustomer`;
                const headerObj = {headers: this.getToken(token)};
                return this.http.post(url, {email: email, source: source}, headerObj).pipe(map(urlRes => {
                    return urlRes;
                }));
            }));
    }

    public updateDefaultCustomerSource(customer, source) {
        return this.afAuth.idToken.pipe(take(1), map(token => token),
            flatMap(token => {
                const url = `${environment.stripeApiUrl}defaultSource`;
                const headerObj = {headers: this.getToken(token)};
                return this.http.post(url, {customer: customer, source: source}, headerObj).pipe(map(urlRes => {
                    return urlRes;
                }));
            }));
    }

}
