import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClientComponent} from './client/client.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MaterialModule} from "../../../material.module";
import {DocumentUploadModule} from "../../document-upload/document-upload.module";

@NgModule({
  declarations: [
    ClientComponent
  ],
  exports: [
    ClientComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    MaterialModule,
    DocumentUploadModule
  ]
})
export class ClientsModule {
}
