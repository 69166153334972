import {Action} from '@ngrx/store';
import {Client} from "@deliver-sense-librarian/data-schema";

export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';

export const UNAUTHENTICATION_SUCCESS = 'UNAUTHENTICATION_SUCCESS';

export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const UNAUTHENTICATE_USER = 'UNAUTHENTICATE_USER';
export const SET_ACCOUNT_CLIENT = 'SET_ACCOUNT_CLIENT';
export const SET_ACCOUNT_ROLES = 'SET_ACCOUNT_ROLES';

export class AuthenticateUserAction implements Action {
  readonly type = AUTHENTICATE_USER;

  constructor(public payload?: any) {
  }
}

export class UnauthenticateUserAction implements Action {
  readonly type = UNAUTHENTICATE_USER;

  constructor() {
  }
}

export class SetAccountClientAction implements Action {
  readonly type = SET_ACCOUNT_CLIENT;

  constructor(public payload?: Client) {
  }
}

export class UserAuthenticationSuccessAction implements Action {
  readonly type = AUTHENTICATION_SUCCESS;

  constructor(public payload?: any) {
  }
}

export class UserAuthenticationFailedAction implements Action {
  readonly type = AUTHENTICATION_FAILED;

  constructor(public payload?: any) {
  }
}

export class UnauthenticatedUserSuccessAction implements Action {
  readonly type = UNAUTHENTICATION_SUCCESS;

  constructor() {
  }
}

export class SetAccountRolesAction implements Action {
  readonly type = SET_ACCOUNT_ROLES;

  constructor(public payload: { clientRole, entities, locations, departments, projects }) {
  }
}
