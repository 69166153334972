import {ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {scrollbarOptions, tableExpandAnimation, tablescrollbarOptions} from "../../../../shared/ds-constant";
import {Papa} from "ngx-papaparse";

@Component({
  selector: 'app-remittance-drill-down',
  templateUrl: './remittance-drill-down.component.html',
  styleUrls: ['./remittance-drill-down.component.scss'],
  animations: tableExpandAnimation
})
export class RemittanceDrillDownComponent implements OnInit {
  @Input() locationReportData: any;
  @Input() thirdParties: any;
  @Input() locations: any;
  @Input() reportHeaderCard: ElementRef;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    'location',
    'thirdParty',
    'posSales',
    'thirdPartySales',
    'salesVariance',
    'posTax',
    'thirdPartyTax',
    'taxVariance',
    'deliveryFee',
    'otherFees',
    'expectedRemittance',
    'actualRemittance',
    'remittanceVariance',
    'expand',
  ];
  public expandedElement: any | null;
  scrollbarOptions = tablescrollbarOptions;
  private screenHeight = window.innerHeight;

  constructor(private papa: Papa,
              private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.tableData = new MatTableDataSource(this.locationReportData);
      // this.tableData.paginator = this.paginator;
      this.tableData.sort = this.sort;
      this.cdr.detectChanges();
  }

  getVariance(a: number, b: number) {
    return +(a - b).toFixed(2);
  }

  getThirdPartyName(thirdPartyId: string) {
    const tp = this.thirdParties.find(_tp => _tp.id === thirdPartyId);
    return tp ? tp.name : '';
  }

  exportReport() {
    const exportData = this.locationReportData.map(row => {
      return {
        'location': `${row.locationId} - ${row.name}`,
        'thirdParty': this.getThirdPartyName(row.thirdParty),
        'posSales': `$${row.posSales}`,
        'thirdPartySales': `${row.thirdPartySales}`,
        'salesVariance': `${this.getVariance(row.posSales, row.thirdPartySales)}`,
        'posTax': `$${row.posTax}`,
        'thirdPartyTax': `$${row.thirdPartyTax}`,
        'taxVariance': `$${this.getVariance(row.posTax, row.thirdPartyTax)}`,
        'deliveryFee': `$${row.deliveryFees}`,
        'otherFees': `$${row.otherFees}`,
        'expectedRemittance': `$${row.expectedRemittance}`,
        'actualRemittance': `$${row.actualRemittance}`,
        'remittanceVariance': `$${this.getVariance(row.expectedRemittance, row.actualRemittance)}`
      }
    });
    // @ts-ignore
    this.papa.unparse(exportData, Object.assign({} ,
      {
        quotes: false,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ",",
        header: true,
        newline: "\r\n",
        skipEmptyLines: false,
        complete: (results, file) => {
        }
      }))
  }
  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
  }

  getHeight() {
    // @ts-ignore
    if (this.screenHeight && this.reportHeaderCard && this.reportHeaderCard.clientHeight) {
      // @ts-ignore
      const height = this.screenHeight - (280 + this.reportHeaderCard.clientHeight);
      return height;
    }
    return 500;
  }
}
