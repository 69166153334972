import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StripeService} from "./stripe.service";
import {FunctionsService} from "./functions.service";
import {LoadingDialogService} from "./loading-dialog.service";
import {PushMessagingService} from "./push-messaging.service";
import {HelpService} from "./help.service";
import {CreateNotificationService} from "./create-notification.service";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    StripeService,
    FunctionsService,
    PushMessagingService,
    LoadingDialogService,
    HelpService,
    CreateNotificationService
  ]
})
export class ServicesModule {
}
