import {NgModule} from '@angular/core';
import {ConfirmLeaveGuard} from "./confirm-leave.guard";
import {AuthGuard} from "./auth.guard";
import {UnauthenticatedGuard} from "./unauthenticated.guard";
import {ClientSelectedGuard} from "./client-selected.guard";


@NgModule({
  providers: [
    ConfirmLeaveGuard,
    AuthGuard,
    UnauthenticatedGuard,
    ClientSelectedGuard
  ]
})

export class GuardsModule {
}
