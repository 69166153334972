import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {AngularFirestore} from '@angular/fire/firestore';
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {LocationReport} from "../create-report/create-report.component";
import {ThirdPartyDeliveryAnalyticsEngine} from "../third-party-delivery-analytics-engine";
import {Location, PosTransaction, ThirdParty, ThirdPartyTransaction} from "@deliver-sense-librarian/data-schema";
import {tableExpandAnimation, tablescrollbarOptions} from "../../../../shared/ds-constant";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import moment = require("moment");


@Component({
  selector: 'app-transaction-drill-down',
  templateUrl: './transaction-drill-down.component.html',
  styleUrls: ['./transaction-drill-down.component.scss'],
  animations: tableExpandAnimation
})
export class TransactionDrillDownComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() reportHeaderCard: ElementRef;
  @Input() locationReportData: LocationReport[];
  @Input() analyticsEngine: ThirdPartyDeliveryAnalyticsEngine;
  @Input() thirdParties: ThirdParty[];
  @Input() locations: Location[];
  // @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    'posTransactionId',
    'posTransactionLocation',
    'posTransactionDate',
    'posTransactionSale',
    'posTransactionTax',
    'match',
    'thirdPartyTransactionId',
    'thirdPartyName',
    'thirdPartyTransactionDate',
    'thirdPartyTransactionSale',
    'thirdPartyTransactionTax',
  ];
  public expandedElement: any | null;
  scrollbarOptions = tablescrollbarOptions;
  private screenHeight = window.innerHeight;

  constructor(private afs: AngularFirestore,
              private store: Store<any>,
              private cdr: ChangeDetectorRef,
              private snackbar: MatSnackBar,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    this.tableData = new MatTableDataSource(this.analyticsEngine.transactionMatches);
    // this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {

  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  getLocationName(locationId) {
    const location = this.locations.find(l => l.locationId === locationId);
    return location ? location.name : ''
  }

  getThirdPartyName(thirdPartyId) {
    const tp = this.thirdParties.find(_tp => _tp.id === thirdPartyId);
    return tp ? tp.name : '';
  }

  getPosTransaction(posTransactionId: string) {
    return this.analyticsEngine.posTransactions.find(t => t.id === posTransactionId);
  }

  getThirdPartyTransaction(thirdPartyTransactionId: string) {
    return this.analyticsEngine.thirdPartyTransactions.find(t => t.id === thirdPartyTransactionId);
  }

  exportReport() {
    const exportData = this.analyticsEngine.transactionMatches.map(match => {
      const posTransaction = this.getPosTransaction(match.posTransaction);
      const thirdPartyTransaction = this.getThirdPartyTransaction(match.thirdPartyTransaction);
      return {
        'posTransactionId': `${match.posTransaction ? match.posTransaction : ''}`,
        'posTransactionLocation': match.posTransaction ? `${this.getLocationName(posTransaction.location)}` : '',
        'posTransactionDate': match.posTransaction ? `${moment(this.getPosTransaction(match.posTransaction).date.toDate()).format('M/D/YYY')}` : '',
        'posTransactionSale': `$${match.posTransaction ? this.getPosTransaction(match.posTransaction).sale : ''}`,
        'posTransactionTax': `$${match.posTransaction ? this.getPosTransaction(match.posTransaction).tax : ''}`,
        'match': `$${this.analyticsEngine.normalizeDistance(match.match)}%`,
        'thirdPartyTransactionId': `$${match.thirdPartyTransaction}`,
        'thirdPartyName': match.thirdPartyTransaction ? `${(this.getThirdPartyName(thirdPartyTransaction.account))}` : '',
        'thirdPartyTransactionDate': match.thirdPartyTransaction ? `${moment(this.getThirdPartyTransaction(match.thirdPartyTransaction).date.toDate()).format('M/D/YYY')}` : '',
        'thirdPartyTransactionSale': `$${match.thirdPartyTransaction ? this.getThirdPartyTransaction(match.thirdPartyTransaction).sale : ''}`,
        'thirdPartyTransactionTax': `$${match.thirdPartyTransaction ? this.getThirdPartyTransaction(match.posthirdPartyTransactionTransaction).tax : ''}`,
      }
    });
    // @ts-ignore
    this.papa.unparse(exportData, Object.assign({},
      {
        quotes: false,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ",",
        header: true,
        newline: "\r\n",
        skipEmptyLines: false,
        complete: (results, file) => {
        }
      }))
  }

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
  }

  getHeight() {
    // @ts-ignore
    if (this.screenHeight && this.reportHeaderCard && this.reportHeaderCard.clientHeight) {
      // @ts-ignore
      const height = this.screenHeight - (280 + this.reportHeaderCard.clientHeight);
      return height;
    }
    return 500;
  }
}
