import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {Client, Department, Entity, User} from "@deliver-sense-librarian/data-schema";
import {from, Subject} from "rxjs";
import {FirebaseApp} from "@angular/fire";
import {Store} from "@ngrx/store";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AngularFirestore} from "@angular/fire/firestore";
import {combineAll, takeUntil} from "rxjs/operators";
import {FirestoreUtilities} from "../../../../utilities/firestore-utilities";
import {UiState} from "../../../../redux/custom-states/uiState/ui-state";

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public tableData: MatTableDataSource<Department>;
  public displayedColumns: string[] = ['name', 'entity', 'description'];
  private _user: User;
  private _destroy$ = new Subject();
  public client: Client;
  private departments: Department[] = [];
  private entities: Entity[] = [];
  private uiState: UiState;

  constructor(private firebaseApp: FirebaseApp,
              private store: Store<any>,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private afs: AngularFirestore,
              private _cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this._destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client && uiState$.departments) {
          this._user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$ as UiState;
          this._getDepartments();
        }
      })
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  /**
   * Get team members
   * Get team member current client roles
   * Get team member entites and roles
   * Get team member departments and roles
   * @private
   */
  private _getDepartments() {
    FirestoreUtilities.getUserAccessibleResourcesOfType('departments', this.afs, this.uiState.departments, [0, 1, 2, 3])
      .pipe(takeUntil(this._destroy$))
      .subscribe(departments$ => {
        this.departments = (departments$);
        if (this.departments && this.departments.length > 0) {
          this.departments = this.departments.filter(d => d.client === this.client.id);
          this.tableData = new MatTableDataSource(this.departments);
          this._getDepartmentEntities()
        }
      });
  }

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  private _getDepartmentEntities() {
    const entityRequests = this.departments.map(department => {
      return this.afs.doc(`entities/${department.entity}`).snapshotChanges()
    });
    from(entityRequests)
      .pipe(combineAll(), takeUntil(this._destroy$))
      .subscribe(entities$ => {
        this.entities = FirestoreUtilities.mergeToType(entities$);
      })
  }

  public getEntityName(department: Department) {
    const entity = this.entities.find(_entity => _entity.id === department.entity);
    return entity ? entity.name : '';
  }

}
