import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LoadingDialogService} from "../../services/loading-dialog.service";
import {AngularFirestore} from '@angular/fire/firestore';
import {IImage, ImageCompressService, ResizeOptions} from "ng2-image-compress";
import {finalize, takeUntil} from "rxjs/operators";
import {Observable, Subject} from "rxjs";
import {ImageUploadUtilities} from "./document-upload.utilities";
import {FirebaseApp} from "@angular/fire/";
import * as firebase from 'firebase';
import {MatSnackBar} from "@angular/material";
import {AngularFireStorage} from '@angular/fire/storage';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent implements OnInit {
  @Input() storagePath: string;
  @Input() compressFile = true;
  @Output() imageUploadComplete = new EventEmitter();
  private imageFileName;
  private resizeOptions = new ResizeOptions();
  private destroy$ = new Subject();
  private processedImages = [];
  private _fileToUpload: any;
  private progress: any;
  private downloadURL: Observable<any>;
  private uploadPercent: Observable<number>;

  constructor(private loadingService: LoadingDialogService,
              private firebaseApp: FirebaseApp,
              private afs: AngularFirestore,
              private storage: AngularFireStorage,
              private snackBar: MatSnackBar,
              private imgCompressService: ImageCompressService) {
  }

  ngOnInit() {
  }

  detectFiles(event) {
    if (event.target.files && event.target.files[0]) {
      this.loadingService.isLoading(true, 'Uploading File', this.progress);
      const name = event.target.files[0].name;
      const lastDot = name.lastIndexOf('.');
      const ext = name.substring(lastDot + 1);
      this.imageFileName = this.afs.createId() + '.' + ext;
      if (ext === 'pdf' || !this.compressFile) {
        return this._uploadFilesToStorage(event.target.files[0]);
      } else {
        ImageUploadUtilities.getOrientation(event.target.files[0], (orientation) => {
          event.target.files[0]['orientation'] = orientation;
        });
        return this._prepareImageForUpload(event.target.files);
      }
    }
  }

  private async _prepareImageForUpload(files) {
    let image: IImage;
    const observableImages = await ImageCompressService.filesToCompressedImageSourceEx(files, this.resizeOptions);
      observableImages
        .subscribe((image$) => {
          image = image$.compressedImage;
          this.processedImages.push(image)
        }, (error) => {
          console.log("Error while converting");
        }, () => {
            this.loadingService.isLoading(false);
            const file = {};
            file['imageDataUrl'] = this.processedImages[0].imageDataUrl;
            file['imageObjectUrl'] = ImageUploadUtilities.dataURItoBlob(file['imageDataUrl']);
            file['name'] = files[0].name;
            this._uploadFilesToStorage(file);
          });
  }

  private async _uploadFilesToStorage(file) {
    return new Promise(resolve => {
      const filePath = this.storagePath + "/" + this.imageFileName;
      const fileRef = this.storage.ref(filePath);
      let task;
      if (!!file['imageObjectUrl']) {
        task = fileRef.put(file['imageObjectUrl']);
      } else {
        task = this.storage.upload(filePath, file);
      }
      task.snapshotChanges().pipe(
        finalize(() => {
        }))
        .subscribe(result => {
          this.progress = (result.bytesTransferred / result.totalBytes) * 100;
          this.loadingService.isLoading(false);
          fileRef.getDownloadURL().subscribe(downloadUrl => {
            const uploadResult = {};
            uploadResult['path'] = result.ref.fullPath;
            uploadResult['url'] = downloadUrl;
            uploadResult['size'] = result.totalBytes;
            this.imageUploadComplete.emit(uploadResult);
          })
        }, () => {},
          () => {
         this.loadingService.isLoading(false);
        })
    })
  }
}
