import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from "@angular/material";

@Component({
    selector: 'app-unsaved-changes',
    templateUrl: './confirm-leave-dialog.component.html',
    styleUrls: ['./confirm-leave-dialog.component.scss']
})
export class ConfirmLeaveDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<ConfirmLeaveDialogComponent>,
                public snackBar: MatSnackBar,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
    }

}
