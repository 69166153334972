import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material";
import {SupportDialogComponent} from "../../../dialogs/support-dialog/support-dialog.component";
import {User, Client, ClientModule, ClientRole} from "@deliver-sense-librarian/data-schema";
import {Store} from '@ngrx/store';
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {AngularFirestore} from '@angular/fire/firestore';
import {FirestoreUtilities} from "../../../utilities/firestore-utilities";
import {ClientsModule} from "../../organization/clients/clients.module";
import {
  auditTrailsModule,
  exemptSalesManagementModule,
  thirdPartyReportingModule
} from "../../../shared/ds-constant";
import {UiState} from "../../../redux/custom-states/uiState/ui-state";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  menuitems = [
    {
      state: 'dashboard',
      name: 'DASHBOARD',
      id: 'dashboard-link',
      type: 'link',
      icon: 'tachometer-alt'
    },
    {
      state: 'organization',
      name: 'ORGANIZATION',
      id: 'org-nav-link',
      type: 'sub',
      icon: 'sitemap',
      children: [
        {state: 'team', icon: 'users', name: 'TEAM MEMBERS'},
        {state: 'locations', icon: 'globe', name: 'LOCATIONS'},
        {state: 'entities', icon: 'city', name: 'ENTITIES'},
        {state: 'departments', icon: 'building', name: 'DEPARTMENTS'},
        {state: 'projects', icon: 'tasks', name: 'PROJECTS'},
      ]
    }
  ];
  public _user: User;
  public _client: Client;
  public uiState: UiState;
  private _destroy$ = new Subject();
  private _clientModules: ClientsModule[] = [];

  constructor(private store: Store<any>,
              private afs: AngularFirestore,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this._destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this._user = uiState$.authUser;
          this._client = uiState$.client;
          this.uiState = uiState$;
          this._getClientModules();
        }
      });
  }

  showSupportDialog() {
    this.dialog.open(SupportDialogComponent);
  }

  private _getClientModules() {
    this.afs.collection('clientModules', ref => ref
      .where('client', '==', this._client.id))
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(clientModules$ => {
        this._clientModules = FirestoreUtilities.mapToType(clientModules$);
        if (this._clientModules.length > 0) {
          this._clientModules = this._clientModules.filter((clientModule: ClientModule) => {
            return clientModule.active;
          })
        }
        this._addActiveClientModuleLinks();
      })
  }

  private _addActiveClientModuleLinks() {
    this.menuitems.splice(2, 3);
    this._clientModules.forEach((clientModule: ClientModule) => {
      switch (clientModule.module.toString()) {
        case thirdPartyReportingModule:
          this.menuitems.splice(3, 0, {
            state: '3pd-reports',
            name: '3PD REPORTS',
            id: 'third-party-report-module-link',
            type: 'sub',
            icon: 'chart-bar',
            children: [
              {state: 'list', icon: null, name: 'ALL REPORTS'},
              {state: 'new', icon: null, name: 'CREATE REPORT'}
            ]
          });
          break;
        case exemptSalesManagementModule:
          this.menuitems.splice(4, 0, {
            state: 'exemption-certificates',
            id: 'exemption-certificate-module-link',
            name: 'EXEMPTION CERTIFICATES',
            type: 'link',
            icon: 'certificate'
          });
          break;
        case auditTrailsModule:
          this.menuitems.splice(5, 0, {
            state: 'audit-trails',
            id: 'audit-trails-module-link',
            name: 'AUDIT TRAILS',
            type: 'link',
            icon: 'thumbs-up'
          });
          break;
      }
    })
  }

  isUserClientAdmin() {
    return this.uiState.clientRole > 2;
  }
}
