import {Component, OnDestroy, OnInit} from '@angular/core';
import {Papa} from "ngx-papaparse";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AngularFirestore} from "@angular/fire/firestore";
import {AuditTrailDocument, AuditTrailDocumentTab, AuditTrailDocumentTabComment, AuditTrailDocumentTabCommentStatuses, Client, User, UserView} from "@deliver-sense-librarian/data-schema";
import {from, Subject} from "rxjs";
import {combineAll, takeUntil} from "rxjs/operators";
import {FirestoreUtilities} from "../../../../utilities/firestore-utilities";
import {Store} from '@ngrx/store';
import moment = require("moment");
import {LoadingDialogService} from "../../../../services/loading-dialog.service";
import {UiState} from "../../../../redux/custom-states/uiState/ui-state";

@Component({
  selector: 'app-review-document',
  templateUrl: './audit-trail-document.component.html',
  styleUrls: ['./audit-trail-document.component.scss']
})
export class AuditTrailDocumentComponent implements OnInit, OnDestroy {
  public user: User;
  private _destroy$ = new Subject();
  public reviewDocumentForm: FormGroup;
  public reviewDocument = new AuditTrailDocument();
  public creatingTabComment = false;
  public addingTab = false;
  public newTabName = new FormControl('', Validators.required);
  public newTabNotes = new FormControl();
  public reviewDocumentTitle = new FormControl(this.reviewDocument.name, Validators.required);
  public reviewDocumentDescription = new FormControl(this.reviewDocument.description, Validators.required);
  public editSection: any;
  private _client: Client;
  private comments: AuditTrailDocumentTabComment[] = [];
  private mutableComments: AuditTrailDocumentTabComment[] = [];
  private availableAssignees: UserView[];
  titleInEdit = false;
  private userViews: UserView[] = [];
  private uiState: UiState;

  constructor(private store: Store<any>,
              private papa: Papa,
              private dialog: MatDialog,
              private activedRoute: ActivatedRoute,
              private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private loadingService: LoadingDialogService,
              private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this._destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client && uiState$.clientRole) {
          this.user = uiState$.authUser;
          this._client = uiState$.client;
          this.uiState = uiState$;
          this.activedRoute.params.subscribe(params$ => {
            if (params$['id']) {
              this._fetchReviewDocument(params$['id']);
            } else {
              this._setupReviewDocumentForm();
            }
            this._fetchAvailableAssignees();
          });
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  private _setupReviewDocumentForm() {
    this.reviewDocumentForm = this.fb.group({
      name: new FormControl(this.reviewDocument.name, Validators.required),
      description: new FormControl(this.reviewDocument.description),
      tabs: new FormControl([])
    });
  }

  private _fetchReviewDocument(id: any) {
    this.afs.doc(`auditTrailDocuments/${id}`)
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(porDoc$ => {
        this.reviewDocument = FirestoreUtilities.objectToType(porDoc$);
        this._fetchReviewDocumentTabs();
        this.setReviewDocumentFormControlValues();
      })
  }

  private _fetchReviewDocumentTabs() {
    this.afs.collection('auditTrailDocumentTabs', ref => ref
      .where('auditTrailDocument', '==', this.reviewDocument.id))
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(tabs$ => {
        this.reviewDocument['tabs'] = FirestoreUtilities.mapToType(tabs$);
        this._fetchReviewDocumentTabComments();
      });
  }

  private _fetchReviewDocumentTabComments() {
    const tabCommentRequests = this.reviewDocument['tabs'].map(tab$ => {
      return this.afs.collection('auditTrailDocumentTabComments', ref => ref
        .where('auditTrailDocumentTab', '==', tab$.id))
        .snapshotChanges();
    });
    from(tabCommentRequests)
      .pipe(combineAll(), takeUntil(this._destroy$))
      .subscribe(comments$ => {
        this.comments = FirestoreUtilities.mergeCollectionToType(comments$) as AuditTrailDocumentTabComment[];
        this.mutableComments = Object.assign([], this.comments.map(comment => Object.assign(new AuditTrailDocumentTabComment(), comment)));
      });
  }

  getTabComments(tab: AuditTrailDocumentTab) {
    return this.mutableComments.filter(comment => comment.auditTrailDocumentTab === tab.id);
  }

  cancelAddingNewTab() {
    this.newTabName.reset();
    this.addingTab = false;
  }

  async saveNewTab() {
    if (this.newTabName.valid) {
      const newTab = new AuditTrailDocumentTab();
      newTab.auditTrailDocument = this.reviewDocument.id;
      newTab.name = this.newTabName.value;
      newTab.notes = this.newTabNotes.value;
      try {
        await this.afs.collection('auditTrailDocumentTabs').add(newTab.toJSONObject());
        this.cancelAddingNewTab();
      } catch (e) {
        this.snackBar.open('Oops... something went wrong. Please refresh and try again.', 'Dismiss', {
          duration: 5000
        });
      }
    } else {
      this.snackBar.open('The new tab must have a name!', 'Dismiss', {
        duration: 5000
      });
    }
  }

  getCommentAssigneeName(comment: AuditTrailDocumentTabComment) {
    const userMatch = this.userViews.find(userView => userView.id === comment.assignee);
    return userMatch ? `${userMatch.firstName} ${userMatch.lastName}` : '';
  }

  getCommentStatus(comment: AuditTrailDocumentTabComment) {
    switch (comment.status) {
      case AuditTrailDocumentTabCommentStatuses.approved:
        return 'Approved';
      case AuditTrailDocumentTabCommentStatuses.pending:
        return 'Pending';
      case AuditTrailDocumentTabCommentStatuses.cancelled:
        return 'Cancelled';
      case AuditTrailDocumentTabCommentStatuses.complete:
        return 'Complete';
    }
  }

  private _fetchAvailableAssignees() {
    this.afs.collection('userViews', ref => ref
      .where('clients', 'array-contains', this._client.id))
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(availableAssignees$ => {
        this.userViews = FirestoreUtilities.mapToType(availableAssignees$) as UserView[];
        if (this.uiState.clientRole > 2) {
          const projectRoleRequests = this.userViews.map(user => {
            return this.afs.doc(`users/${user.id}/clientRoles/${this._client.id}/organizationRoles/${this.reviewDocument.project}`)
              .snapshotChanges();
          });
          from(projectRoleRequests)
            .pipe(combineAll(), takeUntil(this._destroy$))
            .subscribe(projectRoles$ => {
              this.availableAssignees = this.userViews.filter(user => {
                const projectRoleFound = projectRoles$.find(projectRole$ => {
                  // Loop up through the parent doc references until you get to the user doc
                  let parentRef: any = projectRole$.payload.ref.parent;
                  while (parentRef.path && parentRef.parent.id !== 'users') {
                    parentRef = parentRef.parent;
                  }
                  return projectRole$.payload.exists && parentRef.id === user.id;
                });
                const projectRole = FirestoreUtilities.objectToType(projectRoleFound);
                return user.id !== this.user.id && !!projectRoleFound && projectRole.role > 1;
              });
              this.loadingService.isLoading(false);
            }, () => this.loadingService.isLoading(false));
        } else {
          this.availableAssignees = [];
          this.loadingService.isLoading(false)
        }
      }, () => this.loadingService.isLoading(false))
  }

  async saveSectionChanges(name: string, notes: string) {
    if (name) {
      await this.afs.doc(`auditTrailDocumentTabs/${this.editSection.id}`).update({
        name,
        notes,
        dateUpdated: moment().toDate()
      });
      this.snackBar.open(
        'The section was updated successfully.',
        'Dismiss',
        {duration: 5000}
      );
      this.editSection = null;
    } else {
      this.snackBar.open(
        'The section must have a name',
        'Dismiss',
        {duration: 5000}
      )
    }
  }


  async saveTitleChanges() {
    if (this.reviewDocumentTitle.valid) {
      this.afs.doc(`auditTrailDocuments/${this.reviewDocument.id}`)
        .update({
          name: this.reviewDocumentTitle.value,
          description: this.reviewDocumentDescription.value
        });
      this.snackBar.open('Review document title updated successfully', 'Dismiss', {
        duration: 5000
      });
      this.titleInEdit = false;
    } else {
      this.snackBar.open('You must set a name for the review document.', 'Dismiss', {
        duration: 5000
      });
    }
  }

  public isCommentPastDue(comment: AuditTrailDocumentTabComment) {
    if (comment.dueDate && comment.status !== AuditTrailDocumentTabCommentStatuses.approved) {
      return comment.dueDate.seconds ? moment().isAfter(moment(comment.dueDate.toDate())) : moment().isAfter(moment(comment.dueDate))
    }
    return false;
  }

  private setReviewDocumentFormControlValues() {
    this.reviewDocumentTitle.patchValue(this.reviewDocument.name);
    this.reviewDocumentTitle.updateValueAndValidity();
    this.reviewDocumentDescription.patchValue(this.reviewDocument.description);
    this.reviewDocumentDescription.updateValueAndValidity();
  }

  getCommentDateFormatted(comment: AuditTrailDocumentTabComment) {
    if (comment.dueDate) {
      return comment.dueDate.seconds ? moment(comment.dueDate.toDate()).format('M/D/YYYY') : moment(comment.dueDate).format('M/D/YYYY');
    }
  }
}
