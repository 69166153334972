import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Client, ClientRole, Entity, Location, RateType, ThirdParty, User, UserRoles, usStates} from "@deliver-sense-librarian/data-schema";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {Papa} from "ngx-papaparse";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AngularFirestore} from "@angular/fire/firestore";
import {combineLatest, Subject} from "rxjs";
import {take, takeUntil} from "rxjs/operators";
import {FirestoreUtilities} from "../../../../utilities/firestore-utilities";
import {ConfirmDialogComponent} from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import {CustomValidators} from "ng2-validation";
import {markFields, scrollbarOptions} from "../../../../shared/ds-constant";
import moment = require("moment");
import {uiState} from "../../../../redux/custom-states/uiState/uiStateReducer";
import {UiState} from "../../../../redux/custom-states/uiState/ui-state";
import {LoadingDialogService} from "../../../../services/loading-dialog.service";

@Component({
  selector: 'app-create-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit, OnDestroy {
  @Input() location: Location = new Location();
  public entities: Entity[] = [];
  public states = usStates;
  public locationFormInitialized = false;
  public scrollbarOption = scrollbarOptions;
  public newRateType = new FormControl('');
  public locationForm: FormGroup;
  public uiState: UiState;
  private user: User;
  private client: any;
  private _destroy$ = new Subject();
  private thirdParties: ThirdParty[] = [];
  private rateTypes: RateType[] = [];

  constructor(private store: Store<any>,
              private papa: Papa,
              private dialog: MatDialog,
              private activedRoute: ActivatedRoute,
              private router: Router,
              private loadingService: LoadingDialogService,
              private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = <UiState>uiState$;
          this.activedRoute.params.subscribe(params$ => {
            if (params$['id'] && params$['id'] !== 'new') {
              this._fetchLocation(params$['id']);
            } else {
              this.getRelatedResource();
            }
          });
        } else {
          this.getRelatedResource();
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete()
  }

  private getRelatedResource() {
    FirestoreUtilities.getUserAccessibleResourcesOfType('entities', this.afs, this.uiState.entities, [UserRoles.admin])
      .pipe(takeUntil(this._destroy$))
      .subscribe(entities$ => this.entities = entities$ as Entity[]);
    combineLatest([
      this.afs.collection('thirdParties').snapshotChanges(),
      this.afs.collection('rateTypes').snapshotChanges()
    ])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([thirdParties$, rateTypes$]) => {
        this.thirdParties = FirestoreUtilities.mapToType(thirdParties$) as ThirdParty[];
        this.rateTypes = FirestoreUtilities.mapToType(rateTypes$) as RateType[];
        this.rateTypes = this.rateTypes
          .filter(rateType => rateType.name !== 'Non-Taxable')
          .sort((a, b) => {
            return a.order < b.order ? -1 : 1;
          });
        this._setupLocationForm();
      })
  }

  private _fetchLocation(locationId: string) {
    this.afs.doc(`locations/${locationId}`)
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(location$ => {
        this.location = FirestoreUtilities.objectToType(location$) as Location;
        this.getRelatedResource();
      })
  }

  private _setupLocationForm() {
    this.locationForm = this.fb.group({
      name: new FormControl(this.location.name, Validators.required),
      entity: new FormControl(this.location.entity, Validators.required),
      primaryContact: new FormControl(this.location.primaryContact, CustomValidators.email),
      cityTaxRate: new FormControl(this.location.cityTaxRate),
      countyTaxRate: new FormControl(this.location.countyTaxRate),
      stateTaxRate: new FormControl(this.location.stateTaxRate),
      specialTaxRate: new FormControl(this.location.specialTaxRate),
      addressLine1: new FormControl(this.location.addressLine1, Validators.required),
      addressLine2: new FormControl(this.location.addressLine2),
      addressCity: new FormControl(this.location.addressCity, Validators.required),
      addressState: new FormControl(this.location.addressState, Validators.required),
      addressPostalCode: new FormControl(this.location.addressPostalCode, Validators.required),
      thirdParties: new FormArray([]),
    });
    if (this.location.id) {
      this.populateThirdPartyFormArrayFromExistingLocation();
    } else {
      this.populateThirdPartyFormArray();
    }
    this.locationFormInitialized = true;
    markFields(this.locationForm);
  }

  private populateThirdPartyFormArrayFromExistingLocation() {
    const thirdPartyFormArray = this.locationForm.get('thirdParties') as FormArray;
    this.location.thirdParties.forEach(ltp => {
      const ltpFormGroup = new FormGroup({
        thirdParty: new FormControl(ltp.thirdParty, Validators.required),
        active: new FormControl(ltp.active),
        thirdPartyFee: new FormControl(ltp.thirdPartyFee),
        rates: new FormArray([])
      });
      thirdPartyFormArray.push(ltpFormGroup);
      const thirdPartyRateFormArray = ltpFormGroup.get('rates') as FormArray;
      ltp.rates.forEach(ltpr => {
        const ltprFormGroup = new FormGroup({
          rateType: new FormControl(ltpr.rateType, Validators.required),
          effectiveDate: new FormControl(ltpr.effectiveDate ? ltpr.effectiveDate.toDate() : null),
          rate: new FormControl(ltpr.rate, Validators.required)
        });
        thirdPartyRateFormArray.push(ltprFormGroup);
      });
    })
  }

  private populateThirdPartyFormArray() {
    const thirdPartyFormArray = this.locationForm.get('thirdParties') as FormArray;
    this.thirdParties.forEach(thirdParty => {
      const ltpFormGroup = new FormGroup({
        thirdParty: new FormControl(thirdParty.id, Validators.required),
        active: new FormControl(),
        thirdPartyFee: new FormControl(),
        rates: new FormArray([])
      });
      thirdPartyFormArray.push(ltpFormGroup);
      const thirdPartyRateFormArray = ltpFormGroup.get('rates') as FormArray;
      this.rateTypes.forEach(rateType => {
        const ltprFormGroup = new FormGroup({
          rateType: new FormControl(rateType.id, Validators.required),
          effectiveDate: new FormControl(new Date()),
          rate: new FormControl(0, Validators.required)
        });
      });
    })
  }

  addRate(thirdPartyRateFormArray: FormArray) {
    if (this.newRateType.value) {
      thirdPartyRateFormArray.push(new FormGroup({
        rateType: new FormControl(this.newRateType.value, Validators.required),
        effectiveDate: new FormControl(new Date()),
        rate: new FormControl(0, Validators.required)
      }));
      this.newRateType.reset();
    } else {
      this.snackBar.open(`You must select a rate type to add a new rate.`, 'Dismiss', {
        duration: 5000
      })
    }
  }

  removeRate(thirdPartyRateFormArray: FormArray, rateIndex: number) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this rate?',
        action: 'Yes, delete.'
      }
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        const formArray = thirdPartyRateFormArray as FormArray;
        thirdPartyRateFormArray.removeAt(rateIndex);
      }
    });
  }

  async save() {
    if (this.locationForm.valid) {
      const locationData = this.mapFormToLocation();
      if (this.location.id) {
        await this.updateLocation(locationData);
      } else {
        await this.createLocation(locationData);
      }
      await this.router.navigate(['/app/organization/locations'])
    } else {
      this.snackBar.open('Please fill out all required fields.', 'Dismiss', {
        duration: 5000
      })
    }
  }

  private async updateLocation(locationData: Location) {
    await this.afs.doc(`locations/${this.location.id}`).update(
      locationData.toJSONObject()
    );
    this.snackBar.open('Updated location successfully!', 'Dismiss', {
      duration: 5000
    });
  }

  private async createLocation(locationData: Location) {
    await this.afs.doc(`locations/${this.location.id}`).update(
      locationData.toJSONObject()
    );
    this.snackBar.open('Created location successfully!', 'Dismiss', {
      duration: 5000
    });
  }

  private mapFormToLocation() {
    const formValues = this.locationForm.value;
    const location = new Location();
    location.name = formValues.name;
    location.primaryContact = formValues.primaryContact;
    location.entity = formValues.entity;
    location.addressLine1 = formValues.addressLine1;
    location.addressLine2 = formValues.addressLine2;
    location.addressCity = formValues.addressCity;
    location.addressState = formValues.addressState;
    location.addressPostalCode = formValues.addressPostalCode;
    location.thirdParties = formValues.thirdParties;
    return location;
  }

  public async delete() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this location?',
        action: 'Yes, delete.'
      }
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`locations/${this.location.id}`).delete();
        this.loadingService.isLoading(true, 'Deleting location...');
        setTimeout(() => {
          this.loadingService.isLoading(false);
          this.snackBar.open('Location deleted successfully', 'Dismiss', {
            duration: 5000
          });
          this.router.navigate(['/app/organization/locations'])
        }, 1000)
      }
    });
  }

  getThirdPartyName(id: any) {
    const tp = this.thirdParties.find(_tp => _tp.id === id);
    return tp ? tp.name : '';
  }

  getThirdPartyRatesOfType(thirdParty: FormGroup, rateType: RateType) {
    const rateTypeGroups = thirdParty.get('rates')['controls'].filter(control => {
      return control.get('rateType').value === rateType.id;
    });
    return rateTypeGroups.sort((a, b) => {
      return moment(a.effectiveDate).isBefore(moment(b.effectiveDate)) ? -1 : 1;
    });
  }
}
