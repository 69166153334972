import CustomScrollbarOptions = MCustomScrollbar.CustomScrollbarOptions;
import {animate, state, style, transition, trigger} from "@angular/animations";

export function markFields(form) {
  Object.keys(form.controls).forEach(field => {
    form.get(field).markAsTouched({onlySelf: true});
  });
}

export const scrollbarOptions = <CustomScrollbarOptions>{axis: 'y', theme: 'minimal-dark'};
export const tablescrollbarOptions = <CustomScrollbarOptions>{theme: 'minimal-dark'};
export const thirdPartyReportingModule = 'vlBJZeknUiMFjkSqj6F6';
export const exemptSalesManagementModule = 'WHTZNRSd7qRMiz2nTO84';
export const auditTrailsModule = 'ru8bwRnug0X6Q6bLZwHX';

export function getErrorCodeMessage(code) {
  const errorCodeMap = {};
  return errorCodeMap[code] ? errorCodeMap[code] : 'Unknown error occurred.';
}

export const tableExpandAnimation = [
  trigger('detailExpand', [
    state('collapsed', style({height: '0px', minHeight: '0'})),
    state('expanded', style({height: '*'})),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  ]),
];
export const staticImageLocations = {
  amexLogo: 'https://firebasestorage.googleapis.com/v0/b/vexed-creative.appspot.com/o/static%2Famex_logo.png?alt=media&token=68517f1c-174c-40b3-ae36-243433c375f3',
  visaLogo: 'https://firebasestorage.googleapis.com/v0/b/vexed-creative.appspot.com/o/static%2Fvisa.png?alt=media&token=00ee179e-6bb9-4ba2-877e-c198dbb1503a',
  discoverLogo: 'https://firebasestorage.googleapis.com/v0/b/vexed-professional.appspot.com/o/static%2Fdiscover_logo.jpg?alt=media&token=842f9169-8033-4691-a353-c6f5cc2f3206',
  masterCardLogo: 'https://firebasestorage.googleapis.com/v0/b/vexed-creative.appspot.com/o/static%2Fmc_logo.png?alt=media&token=369d153f-cff2-4798-821c-6a706b1f28bc',
  imagePlaceholder: 'https://firebasestorage.googleapis.com/v0/b/vexed-creative.appspot.com/o/static%2Fimage-placeholder.png?alt=media&token=238f185d-cfb9-4f07-8bf3-61b25b9bc007',
  lpSecondImage: 'https://firebasestorage.googleapis.com/v0/b/vexed-creative.appspot.com/o/static%2Flight_and_dark.jpg?alt=media&token=43fe5c25-7f5f-4de7-97a0-4b075395cfc0',
  lpMainImage: 'https://firebasestorage.googleapis.com/v0/b/vexed-creative.appspot.com/o/static%2Fvexli_car_hood.jpg?alt=media&token=0bb73ace-1244-4b4a-b0af-f78efca46f10',
  logoLarge: 'https://firebasestorage.googleapis.com/v0/b/deliver-sense-web-test.appspot.com/o/static%2Flogo-medium.png?alt=media&token=f5d41819-8886-4edc-94dd-79e860d7850d',
  logoLargeWhite: 'https://firebasestorage.googleapis.com/v0/b/deliver-sense-web-test.appspot.com/o/static%2Flogo-medium-white.png?alt=media&token=bce2639c-bfa1-4552-bdb2-9cc2a68a06b4',
  logoXtraLarge: 'https://firebasestorage.googleapis.com/v0/b/deliver-sense-web-test.appspot.com/o/static%2Flogo_large.png?alt=media&token=907ba9cf-4d74-4393-a8c8-defaaf9b95bb',
  logoXtraLargeWhite: 'https://firebasestorage.googleapis.com/v0/b/deliver-sense-web-test.appspot.com/o/static%2Flogo_large_white.png?alt=media&token=e6691f0a-84a4-4ec4-8641-d8c11e7812b8',
  iconOfficial: "https://firebasestorage.googleapis.com/v0/b/deliver-sense-web-test.appspot.com/o/static%2Ficon-512x512.png?alt=media&token=9ba2e21d-f664-4a8e-a6f9-6c07f9075d44",
  offeringPlaceholder: "https://firebasestorage.googleapis.com/v0/b/prodville-test.appspot.com/o/static%2Foffering-placeholder.png?alt=media&token=49ecba43-627e-4ab5-b7e8-d5855ea2c30d",
  videoPlaceholder: 'https://firebasestorage.googleapis.com/v0/b/vexed-creative.appspot.com/o/static%2Fvideo-sample-placeholder.png?alt=media&token=c272b052-1235-4087-945f-b6e9008d3e61',
  cookie: 'https://firebasestorage.googleapis.com/v0/b/vexed-professional-lab.appspot.com/o/static%2Fcookie.png?alt=media&token=a81d25a2-0655-4659-85b8-877669ba7b36'
};

export const placeholderUrl = staticImageLocations.imagePlaceholder;
export const cardImageSources = {
  Visa: staticImageLocations.visaLogo,
  Discover: staticImageLocations.discoverLogo,
  MasterCard: staticImageLocations.masterCardLogo,
  "American Express": staticImageLocations.amexLogo
};
export const bulkLocationUploadTemplate = 'https://firebasestorage.googleapis.com/v0/b/deliver-sense-app-dev.appspot.com/o/data-sheets%2Fdeliver-sense-locations-upload-template.csv?alt=media&token=a33c15bb-149c-471c-8808-2a0b3d4e3ebf';
export const bulkLocation3pdRateTemplate = 'https://firebasestorage.googleapis.com/v0/b/deliver-sense-app-dev.appspot.com/o/data-sheets%2Fdeliver-sense-locations-3pd-rates-template.csv?alt=media&token=f9a68ffc-dd7d-4278-b230-332cba1bfd27';
export const getPlaceholderImage = {url: staticImageLocations.offeringPlaceholder};
export const testUETransactionData = [{
  "id": "1",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 3.07,
  "location": "8900",
  "orderSubtotal": 20.45,
  "partnerRemitted": 19.83,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1566802800, "nanoseconds": 0},
  "requestTime": "11:18:49",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 2.45,
  "totalSkus": 2,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "2",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 2.39,
  "location": "8900",
  "orderSubtotal": 15.95,
  "partnerRemitted": 15.47,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1566802800, "nanoseconds": 0},
  "requestTime": "12:12:43",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 1.91,
  "totalSkus": 1,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "3",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 2.09,
  "location": "8900",
  "orderSubtotal": 13.95,
  "partnerRemitted": 13.53,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1566802800, "nanoseconds": 0},
  "requestTime": "14:56:51",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 1.67,
  "totalSkus": 1,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "4",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 3.45,
  "location": "8900",
  "orderSubtotal": 23,
  "partnerRemitted": 22.31,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1566802800, "nanoseconds": 0},
  "requestTime": "19:38:09",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 2.76,
  "totalSkus": 3,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "5",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 5.76,
  "location": "8900",
  "orderSubtotal": 38.4,
  "partnerRemitted": 37.25,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1566802800, "nanoseconds": 0},
  "requestTime": "20:02:23",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 4.61,
  "totalSkus": 3,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "6",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 4.87,
  "location": "8900",
  "orderSubtotal": 32.45,
  "partnerRemitted": 31.47,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1566802800, "nanoseconds": 0},
  "requestTime": "20:53:33",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 3.89,
  "totalSkus": 2,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "10",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 6.2,
  "location": "8900",
  "orderSubtotal": 41.35,
  "partnerRemitted": 40.11,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1566889200, "nanoseconds": 0},
  "requestTime": "18:01:09",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 4.96,
  "totalSkus": 20,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "11",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 8.54,
  "location": "8900",
  "orderSubtotal": 56.95,
  "partnerRemitted": 55.24,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1566889200, "nanoseconds": 0},
  "requestTime": "13:32:25",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 6.83,
  "totalSkus": 5,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "12",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 6.29,
  "location": "8900",
  "orderSubtotal": 41.9,
  "partnerRemitted": 40.64,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1566889200, "nanoseconds": 0},
  "requestTime": "17:59:33",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 5.03,
  "totalSkus": 4,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "13",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 1.64,
  "location": "8900",
  "orderSubtotal": 10.95,
  "partnerRemitted": 10.62,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1566889200, "nanoseconds": 0},
  "requestTime": "18:11:22",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 1.31,
  "totalSkus": 1,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "14",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 13.55,
  "location": "8900",
  "orderSubtotal": 90.3,
  "partnerRemitted": 87.59,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1566889200, "nanoseconds": 0},
  "requestTime": "19:54:43",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 10.84,
  "totalSkus": 7,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "7",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 9.66,
  "location": "8900",
  "orderSubtotal": 64.4,
  "partnerRemitted": 62.47,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1566889200, "nanoseconds": 0},
  "requestTime": "18:06:09",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 7.73,
  "totalSkus": 3,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "8",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 15.95,
  "location": "8900",
  "orderSubtotal": 106.3,
  "partnerRemitted": 103.11,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1566889200, "nanoseconds": 0},
  "requestTime": "16:52:06",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 12.76,
  "totalSkus": 13,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "9",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 4.04,
  "location": "8900",
  "orderSubtotal": 26.9,
  "partnerRemitted": 26.09,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1566889200, "nanoseconds": 0},
  "requestTime": "18:27:54",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 3.23,
  "totalSkus": 4,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "15",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 3.14,
  "location": "8900",
  "orderSubtotal": 20.9,
  "partnerRemitted": 20.27,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1566975600, "nanoseconds": 0},
  "requestTime": "12:27:03",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 2.51,
  "totalSkus": 2,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "16",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 0,
  "location": "8900",
  "orderSubtotal": 0,
  "partnerRemitted": 17.86,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1566975600, "nanoseconds": 0},
  "requestTime": "15:01:39",
  "salesCorrection": 15.95,
  "status": "completed",
  "taxCorrection": 1.91,
  "taxSubtotal": 0,
  "totalSkus": 1,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "17",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 3.37,
  "location": "8900",
  "orderSubtotal": 22.45,
  "partnerRemitted": 21.77,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1566975600, "nanoseconds": 0},
  "requestTime": "16:29:59",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 2.69,
  "totalSkus": 2,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "18",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 6.47,
  "location": "8900",
  "orderSubtotal": 43.15,
  "partnerRemitted": 41.86,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1566975600, "nanoseconds": 0},
  "requestTime": "18:46:02",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 5.18,
  "totalSkus": 16,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "19",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 2.39,
  "location": "8900",
  "orderSubtotal": 15.9,
  "partnerRemitted": 15.42,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1566975600, "nanoseconds": 0},
  "requestTime": "15:57:26",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 1.91,
  "totalSkus": 6,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "20",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 2.39,
  "location": "8900",
  "orderSubtotal": 15.95,
  "partnerRemitted": 15.47,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1566975600, "nanoseconds": 0},
  "requestTime": "15:01:39",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 1.91,
  "totalSkus": 1,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "21",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 4.56,
  "location": "8900",
  "orderSubtotal": 30.4,
  "partnerRemitted": 29.49,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1566975600, "nanoseconds": 0},
  "requestTime": "12:06:24",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 3.65,
  "totalSkus": 3,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "22",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 1.94,
  "location": "8900",
  "orderSubtotal": 12.95,
  "partnerRemitted": 12.56,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1567062000, "nanoseconds": 0},
  "requestTime": "19:26:40",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 1.55,
  "totalSkus": 1,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "23",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 2.92,
  "location": "8900",
  "orderSubtotal": 19.45,
  "partnerRemitted": 18.86,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1567148400, "nanoseconds": 0},
  "requestTime": "12:45:07",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 2.33,
  "totalSkus": 2,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "24",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 2.48,
  "location": "8900",
  "orderSubtotal": 16.5,
  "partnerRemitted": 16,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1567148400, "nanoseconds": 0},
  "requestTime": "12:56:49",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 1.98,
  "totalSkus": 1,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "25",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 1.94,
  "location": "8900",
  "orderSubtotal": 12.95,
  "partnerRemitted": 12.56,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1567148400, "nanoseconds": 0},
  "requestTime": "17:10:51",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 1.55,
  "totalSkus": 9,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "26",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 3.44,
  "location": "8900",
  "orderSubtotal": 22.9,
  "partnerRemitted": 22.21,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1567148400, "nanoseconds": 0},
  "requestTime": "12:34:23",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 2.75,
  "totalSkus": 2,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "27",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 3.14,
  "location": "8900",
  "orderSubtotal": 20.95,
  "partnerRemitted": 20.32,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1567148400, "nanoseconds": 0},
  "requestTime": "15:45:18",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 2.51,
  "totalSkus": 1,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "28",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 8.75,
  "location": "8900",
  "orderSubtotal": 58.3,
  "partnerRemitted": 56.55,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1567148400, "nanoseconds": 0},
  "requestTime": "18:14:26",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 7,
  "totalSkus": 5,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "29",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 6.28,
  "location": "8900",
  "orderSubtotal": 41.85,
  "partnerRemitted": 40.59,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1567148400, "nanoseconds": 0},
  "requestTime": "11:13:46",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 5.02,
  "totalSkus": 3,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "30",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 3.29,
  "location": "8900",
  "orderSubtotal": 21.9,
  "partnerRemitted": 21.24,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1567148400, "nanoseconds": 0},
  "requestTime": "12:41:18",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 2.63,
  "totalSkus": 2,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "31",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 3.11,
  "location": "8900",
  "orderSubtotal": 20.7,
  "partnerRemitted": 20.07,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1567148400, "nanoseconds": 0},
  "requestTime": "19:31:49",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 2.48,
  "totalSkus": 1,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "32",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 6.07,
  "location": "8900",
  "orderSubtotal": 40.45,
  "partnerRemitted": 39.23,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1567148400, "nanoseconds": 0},
  "requestTime": "19:39:36",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 4.85,
  "totalSkus": 3,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "33",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 1.64,
  "location": "8900",
  "orderSubtotal": 10.95,
  "partnerRemitted": 10.62,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1567148400, "nanoseconds": 0},
  "requestTime": "19:04:07",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 1.31,
  "totalSkus": 1,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}, {
  "id": "34",
  "account": "TcVJpdbCUWAZo5xK0y98",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "dateCreated": {"seconds": 1573591539, "nanoseconds": 50000000},
  "deliveryFeeRate": 0.15,
  "deliveryFeeTotal": 4.5,
  "location": "8900",
  "orderSubtotal": 30,
  "partnerRemitted": 29.1,
  "partnerTaxRemitted": 0,
  "pos": "5Y5VyOobpySS3IRlby3T",
  "requestDate": {"seconds": 1567148400, "nanoseconds": 0},
  "requestTime": "20:26:51",
  "salesCorrection": 0,
  "status": "completed",
  "taxCorrection": 0,
  "taxSubtotal": 3.6,
  "totalSkus": 2,
  "workflowId": "5aaf6cee-0d18-49b5-9d9b-62a053ae98c7"
}]
export const testPOSTransactionData = [{
  "id": "157cf1f3c37ee3abeacbee9948eac638",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1566802800, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 13.95,
  "tax": 1.67,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "2098a9bbc62133d0cb27f66ac1011199",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1566802800, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 23,
  "tax": 2.76,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "36208432e3be86467660f025503fa66e",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1566802800, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 15.95,
  "tax": 1.91,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "3d68c07036275aadb1faafc1ecddb049",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1566802800, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 20.45,
  "tax": 2.45,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "7d68f1950c12324c62231c5262118980",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1566802800, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 32.45,
  "tax": 3.89,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "bf8ce51f0b7bc04751bd8ba1cd011b77",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1566802800, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 38.4,
  "tax": 4.61,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "3e5cc8eb378addff4e76a031a5927bf7",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1566889200, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 64.4,
  "tax": 7.73,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "457e74248a1994111a1a119a562704ac",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1566889200, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 41.35,
  "tax": 4.96,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "54652562f6e44102d58ffdf2f90ec72c",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1566889200, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 56.95,
  "tax": 6.83,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "80fc8305deae19cd16957d06301f3ea7",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1566889200, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 26.9,
  "tax": 3.23,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "a93ed37a81fdc52dd63fcda346ba724f",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1566889200, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 106.3,
  "tax": 12.76,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "b2eb91019df705350c4076663716fb07",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1566889200, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 10.95,
  "tax": 1.31,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "b51457f4fb423861300e989f44eb2113",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1566889200, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 41.9,
  "tax": 5.03,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "ecb873cd6f2a4360caeacb8118ebc138",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1566889200, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 90.3,
  "tax": 10.84,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "a2eaa9d3e67dc38f0f31b9a532b0c2da",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1566975600, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 22.95,
  "tax": 2.75,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "a573d6d09d4622f717152d7ada3ff6f6",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1566975600, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 20.9,
  "tax": 2.51,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "d069b98b7af955d082cfcba66d2e7f39",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1566975600, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 43.15,
  "tax": 5.18,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "d1dfe2c399bed0e310a873c899ff9225",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1566975600, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 15.95,
  "tax": 1.91,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "fb3e3003f05e2c3093c1830f6e85409d",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1566975600, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 15.9,
  "tax": 1.9,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "d1d3e0ab18a916533bd5358574d4d5d2",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1567062000, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 12.95,
  "tax": 1.55,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "168666e59c4c0f44316cf5cc9fb3f9d2",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1567148400, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 16.5,
  "tax": 1.98,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "21d88a678e7f887099dda71ceceb26a0",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1567148400, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 40.9,
  "tax": 4.91,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "4064b0ead2435ce21050d82f88c6807c",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1567148400, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 12.95,
  "tax": 1.55,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "51f109343b401b058c3a1664a8c29103",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1567148400, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 58.3,
  "tax": 7,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "640ddbd6c930bda523ea5467648b9c3d",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1567148400, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 40.85,
  "tax": 4.9,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "76f571f1b6c3ba1ac1a17b3b043b1e8e",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1567148400, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 19.45,
  "tax": 2.33,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "8b9536f634897ab2062183dae1be3ede",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1567148400, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 10.95,
  "tax": 1.31,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "91ef7e22ccea4c220fe5ea169bd9e031",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1567148400, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 22.9,
  "tax": 2.75,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "9425f76afd57fc320e81220da87c4c15",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1567148400, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 21.9,
  "tax": 2.63,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "bccbae1cca921a5ec29b94781acb53aa",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1567148400, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 20.7,
  "tax": 2.48,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "d3345d31ebb987f873701951c1dc8b14",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1567148400, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 30,
  "tax": 3.6,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "ee0f65bc02e93749aa97b46df9116c5f",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1567148400, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 20.95,
  "tax": 2.51,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}, {
  "id": "f06c8b308d18e0ad0e610d1c59752f33",
  "account": "5Y5VyOobpySS3IRlby3T",
  "client": "QKdAnf5u8g5bvEBRVnll",
  "date": {"seconds": 1567148400, "nanoseconds": 0},
  "dateCreated": {"seconds": 1573532096, "nanoseconds": 896000000},
  "location": "8900",
  "pos": "TcVJpdbCUWAZo5xK0y98",
  "subTotal": 39.45,
  "tax": 4.73,
  "workflowId": "1b7c6729-dbdd-4fba-a1aa-2c320463a648"
}]


export class Tutorial {
  title: string;
  summary: string;
  steps: TutorialStep[];
}

export class TutorialStep {
  title: string;
  targets: string[];
  source: string;
  delay: number;
  scrollTo: string; // id of element to scrollTo;
  startAction: string; // id of element to click;
  endAction: string; // id of element to click on exit;
  arrowText: string;
  mainText: string;
  mainImage: string;
}
/*******************************************************************/
/*******************************************************************/
/*******************************************************************/
/*******************************************************************/
export function dashboardTutorial() {
  const tutorial = new Tutorial();
  const tutorialSteps = [];
  tutorial.title = "Welcome To DeliverSense!";
  tutorial.summary = "Here is an overview of the application. Click 'Start' to begin the application tutorial.";
  tutorial.steps = tutorialSteps;
  // Organization
  const orgNavTutorialStep = new TutorialStep();
  orgNavTutorialStep.title = 'Setup Your Organization and Manage Your Team';
  orgNavTutorialStep.arrowText = 'Expand Links';
  orgNavTutorialStep.source = 'tutorial-sheet';
  orgNavTutorialStep.targets = ['org-nav-link'];
  orgNavTutorialStep.startAction = '#org-nav-link';
  orgNavTutorialStep.endAction = '#org-nav-link';
  orgNavTutorialStep.mainText = 'This link expands to show links to manage your organization: team members, locations, entities, departments, and projects.'
  // Client Selector
  const clientSelectTutorialStep = new TutorialStep();
  clientSelectTutorialStep.title = 'Work In Multiple Company Accounts with One Login!';
  clientSelectTutorialStep.arrowText = 'Select';
  clientSelectTutorialStep.source = 'tutorial-sheet';
  clientSelectTutorialStep.targets = ['header-client-selector'];
  clientSelectTutorialStep.startAction = '#header-client-selector';
  clientSelectTutorialStep.endAction = '#header-client-selector';
  clientSelectTutorialStep.mainText = 'You can toggle between clients that you are working in using the client selector in the top navigation bar; a handy feature for' +
    'consultants who work with multiple DeliverSense customers.';
  // Module Links
  const moduleLinksTutorialStep = new TutorialStep();
  moduleLinksTutorialStep.title = 'Your Company Modules';
  moduleLinksTutorialStep.arrowText = '';
  moduleLinksTutorialStep.source = 'tutorial-sheet';
  moduleLinksTutorialStep.targets = ['exemption-certificate-module-link', 'audit-trails-module-link', 'third-party-report-module-link'];
  moduleLinksTutorialStep.mainText = 'Below the organization links you will find the links to the modules that your company subscribes to.';
// notifications
  const userOptionsTutorialStep = new TutorialStep();
  userOptionsTutorialStep.title = 'Manage Your Global Profile';
  userOptionsTutorialStep.arrowText = '';
  userOptionsTutorialStep.source = 'tutorial-sheet';
  userOptionsTutorialStep.targets = ['header-user-actions-button'];
  userOptionsTutorialStep.mainText = 'Click your username to manage your global profile or logout.';
  // User Info
  const userNotificationTutorialStep = new TutorialStep();
  userNotificationTutorialStep.arrowText = '';
  userNotificationTutorialStep.title = 'Client Specific Notifications';
  userNotificationTutorialStep.source = 'tutorial-sheet';
  userNotificationTutorialStep.targets = ['header-notification-button'];
  userNotificationTutorialStep.startAction = '#header-notification-button';
  userNotificationTutorialStep.endAction = '#header-notification-button';
  userNotificationTutorialStep.mainText = 'See notifications specific to the selected client account. Notifications can include platform notifications, review requests,' +
    'or other module specific notifications.';
  // client branding
  const clientBrandingTutorialStep = new TutorialStep();
  clientBrandingTutorialStep.arrowText = '';
  clientBrandingTutorialStep.title = 'Manage Your Client Account';
  clientBrandingTutorialStep.source = 'tutorial-sheet';
  clientBrandingTutorialStep.targets = ['header-client-brand', 'nav-client-settings'];
  clientBrandingTutorialStep.mainText = "If you have admin access to the currently selected client you can brand the account with your company's logo and edit other client settings.";
  // Add steps
  tutorialSteps.push(orgNavTutorialStep);
  tutorialSteps.push(moduleLinksTutorialStep);
  tutorialSteps.push(clientSelectTutorialStep);
  tutorialSteps.push(userOptionsTutorialStep);
  tutorialSteps.push(userNotificationTutorialStep);
  tutorialSteps.push(clientBrandingTutorialStep);
  return tutorial
}

export function teamMembersTutorial() {
  const tutorial = new Tutorial();
  const tutorialSteps = [];
  tutorial.title = "Team Members";
  tutorial.summary = `Here you will be able to manage client team members. This includes inviting new team members via email, removing team members, and updating access to entities, departments,
   locations, and projects.`;
  tutorial.steps = tutorialSteps;
  return tutorial

}

export function locationsTutorial() {
  const tutorial = new Tutorial();
  const tutorialSteps = [];
  tutorial.title = "Locations";
  tutorial.summary = "This section will allow you to manage the client locations. Client locations include tax and accounting" +
    "profiles that enables module features such as '3PD Reporting' reconciliation automation.";
  tutorial.steps = tutorialSteps;
  return tutorial

}

export function locationTutorial() {
  const tutorial = new Tutorial();
  const tutorialSteps = [];
  tutorial.title = "Location Information and Rate Management";
  tutorial.summary = "This page allows the user to edit basic location information and manage the" +
    "'accounting profile' (i.e. tax rates, fee rates, and delivery partner information specific to the location).";
  tutorial.steps = tutorialSteps;
  return tutorial

}

export function thirdPartyReportsTutorial() {
  const tutorial = new Tutorial();
  const tutorialSteps = [];
  tutorial.title = "3PD Report";
  tutorial.summary = 'The table lists saved client third party delivery reports.' +
    'Click on one of the saved reports to start running a report using the saved parameters.';
  tutorial.steps = tutorialSteps;
  return tutorial

}

export function thirdPartyCreateReportTutorial() {
  const tutorial = new Tutorial();
  const tutorialSteps = [];
  tutorial.title = "Run 3PD Reports";
  tutorial.summary = 'The 3PD report generator pulls data from the selected 3PD partners and the client\'s internal POS or transaction system;' +
    'using a selected date range, to report compares the transactions between the systems and reconciles the information.';
  tutorial.steps = tutorialSteps;
  // parameters
  const selectParametersStep = new TutorialStep();
  selectParametersStep.title = 'Set the Report Parameters';
  selectParametersStep.source = 'tutorial-sheet';
  selectParametersStep.endAction = '#run-third-party-report-button';
  selectParametersStep.targets = ['select-locations', 'select-third-parties', 'select-start-date', 'select-end-date'];
  selectParametersStep.mainText = 'To run a 3PD reconciliation report you must select one or more locations, a 3PD partner, a start and an end date.' +
    "These parameters will be used to pull transaction data from the client's POS system and the selected delivery partners transaction records.";
  tutorialSteps.push(selectParametersStep);
  // reportInfo
  const locationSummaryStep = new TutorialStep();
  locationSummaryStep.title = 'Transaction Reporting';
  locationSummaryStep.source = 'tutorial-sheet';
  locationSummaryStep.delay = 500;
  locationSummaryStep.targets = ['pos-sales', 'third-party-sales', 'pos-tax', 'third-party-tax', 'third-party-effective-tax', 'location-tax'];
  locationSummaryStep.mainText = 'After the transactions have been pulled, a summary row for each location will summarize the ' +
    'sales, tax collection and effective tax rate for each location identifying disparities between the systems.';
  tutorialSteps.push(locationSummaryStep);
  // drilldown reports
  const drillDownStep = new TutorialStep();
  drillDownStep.title = 'Drill Down Reports';
  drillDownStep.source = 'tutorial-sheet';
  drillDownStep.startAction = '#expand-reports';
  drillDownStep.targets = ['match-level'];
  drillDownStep.mainText = 'Expand the row to view the drill down reports generated by the AI reconciliation engine. These reports will match the transactions ' +
    'between the POS and 3PD, automating the process of identifying discrepancies between the systems. This will assist in tax reporting, remittance validation' +
    'and effective management of the 3PD integrations.';
  tutorialSteps.push(drillDownStep);

  return tutorial

}

export function auditTrailsTutorial() {
  const tutorial = new Tutorial();
  const tutorialSteps = [];
  tutorial.title = "Audit Trails";
  tutorial.summary = 'Audit trails enables the team and invited external parties to more efficiently manage the work review process. Using project permission settings, ' +
    'the table on this page lists all the Audit Trail documents and review notes available to you based on your project access.';
  tutorial.steps = tutorialSteps;
  const newAuditTrailStep = new TutorialStep();
  newAuditTrailStep.arrowText = '';
  newAuditTrailStep.title = 'New Audit Trails';
  newAuditTrailStep.source = 'tutorial-sheet';
  newAuditTrailStep.targets = ['upload-audit-trail-button', 'create-new-audit-trail-button'];
  newAuditTrailStep.mainText = "New documents that your team wants to track review comments for can be created manually or by uploading an excel document. Uploaded documents are " +
    "intelligently parsed for tab name to automatically create review sections.";
  tutorialSteps.push(newAuditTrailStep);
  const exisitingAuditTrailSteps = new TutorialStep();
  exisitingAuditTrailSteps.arrowText = '';
  exisitingAuditTrailSteps.title = 'Current Statuses';
  exisitingAuditTrailSteps.source = 'tutorial-sheet';
  exisitingAuditTrailSteps.targets = ['open-comments-cell', 'approved-comments-cell', 'completed-status-cell'];
  exisitingAuditTrailSteps.mainText = "The list of existing Audit Trail documents shows the review status of each document. Open comments are pending or " +
    "have been completed by the assignee. Once the reviewer has approved a comment it will appear in the 'Approved' column. Once all the comments have been approved, " +
    "the status will appear as completed.";
  tutorialSteps.push(exisitingAuditTrailSteps);
  return tutorial
}

export function auditTrailTutorial() {
  const tutorial = new Tutorial();
  const tutorialSteps = [];
  tutorial.title = "Comment Manager";
  tutorial.summary = "Audit Trail's comment manager is where the your team will create, assign, track and resolve work review comments.";
  tutorial.steps = tutorialSteps;
  const pageTabSteps = new TutorialStep();
  pageTabSteps.arrowText = '';
  pageTabSteps.title = 'Review Sections';
  pageTabSteps.source = 'tutorial-sheet';
  pageTabSteps.targets = ['audit-trail-tab'];
  pageTabSteps.mainText = "Sections enable the review to be organized by pages, topics or spreadsheet tabs.";
  tutorialSteps.push(pageTabSteps);
  const addSectionsStep = new TutorialStep();
  addSectionsStep.arrowText = '';
  addSectionsStep.title = 'Adding Sections';
  addSectionsStep.source = 'tutorial-sheet';
  addSectionsStep.targets = ['add-section-button'];
  addSectionsStep.mainText = "If a spreadsheet is uploaded the sections will be created automatically using the tab names. If the review document changes during the course of the review process you can add additional sections to represent the new topic or tab.";
  tutorialSteps.push(addSectionsStep);
  const commentsListStep = new TutorialStep();
  commentsListStep.arrowText = '';
  commentsListStep.title = 'Adding and Managing Comments';
  commentsListStep.source = 'tutorial-sheet';
  commentsListStep.startAction = '.mat-expansion-panel-header';
  commentsListStep.endAction = '.mat-expansion-panel-header';
  commentsListStep.targets = ['add-comment-button', 'comment-text', 'resolution-text', 'assignee-selector', 'status-selector'];
  commentsListStep.mainText = "Within each section, a list of the comments will showcase review notes, the assignee and status. Expand the row to update the comment" +
    "based on your role. An assignee can add a response and change the status to either pending or completed. The creator of the " +
    "comment can update the review notes, change the assignee, and update the status to pending, approved, or cancelled." ;
  tutorialSteps.push(commentsListStep);
  return tutorial
}

export function exemptionCertificateTutorial() {
  const tutorial = new Tutorial();
  const tutorialSteps = [];
  tutorial.title = "Exemption Certificate Manager";
  tutorial.summary = "This module enable a client to efficiently manage and document tax exempt sales transactions." +
    "The table automatically populates with tax exempt transactions from the client's POS or internal transaction system.";
  tutorial.steps = tutorialSteps;
  const documentedStatusStep = new TutorialStep();
  documentedStatusStep.arrowText = '';
  documentedStatusStep.title = 'Support Status';
  documentedStatusStep.source = 'tutorial-sheet';
  documentedStatusStep.targets = ['documented-status'];
  documentedStatusStep.mainText = "This icon indicates whether the transaction has attached support validating the tax exemption. A check mark means the transaction is properly documented.";
  const expandDetailsStep = new TutorialStep();
  expandDetailsStep.arrowText = '';
  expandDetailsStep.title = 'Upload Documentation';
  expandDetailsStep.startAction = '#expand-exemption-details';
  expandDetailsStep.source = 'tutorial-sheet';
  expandDetailsStep.targets = ['expand-exemption-details'];
  expandDetailsStep.mainText = "Expand the row to upload exemption support (pdf or image files), download/edit existing documentation, and add notes.";
  const exportCertificatesStep = new TutorialStep();
  exportCertificatesStep.arrowText = '';
  exportCertificatesStep.title = 'Export Documentation';
  exportCertificatesStep.source = 'tutorial-sheet';
  exportCertificatesStep.targets = ['export-tax-exempt-documentation'];
  exportCertificatesStep.mainText = "Click the export button to download exempt sales information and certificates.";
  tutorialSteps.push(documentedStatusStep);
  tutorialSteps.push(expandDetailsStep);
  tutorialSteps.push(exportCertificatesStep);
  return tutorial;
}

export function clientSettingTutorial() {
  const tutorial = new Tutorial();
  const tutorialSteps = [];
  tutorial.title = "Client Settings";
  tutorial.summary = `A client admin will be able to update account information, view the client's enabled modules, and manage payments. `;
  tutorial.steps = tutorialSteps;
  return tutorial

}
