import {INITIAL_UI_STATE, UiState} from './ui-state';
import {Action} from '@ngrx/store';
import {
  AUTHENTICATION_FAILED,
  AUTHENTICATION_SUCCESS,
  SET_ACCOUNT_CLIENT,
  UNAUTHENTICATE_USER,
  SetAccountClientAction,
  UnauthenticateUserAction,
  UserAuthenticationSuccessAction, UserAuthenticationFailedAction, SetAccountRolesAction, SET_ACCOUNT_ROLES
} from './ui-state-actions';

export function uiState(state: UiState = INITIAL_UI_STATE, action: Action): UiState {
  switch (action.type) {
    /**
     * Authentication
     */
    case AUTHENTICATION_SUCCESS:
      return handleSetAuthenticatedUserAction(state, <any>action);
    case AUTHENTICATION_FAILED:
      return handleSetAuthenticationError(state, <any>action);
    case UNAUTHENTICATE_USER:
      return handleUnauthenticatedUserAction(state, <any>action);
    case SET_ACCOUNT_CLIENT:
      return handleSetAccountClientAction(state, <any>action);
    case SET_ACCOUNT_ROLES:
      return handleSetAccountRolesAction(state, <any>action);
    /**
     * Default
     */
    default:
      return state;
  }
}

function handleSetAuthenticatedUserAction(state: UiState, action: UserAuthenticationSuccessAction) {
  const newState = Object.assign({}, state);
  localStorage.setItem('user', JSON.stringify(action.payload));
  newState.authUser = action.payload;
  return newState;
}

function handleSetAccountClientAction(state: UiState, action: SetAccountClientAction) {
  const newState = Object.assign({}, state);
  localStorage.setItem('client', JSON.stringify(action.payload));
  newState.client = action.payload;
  return newState;
}

function handleSetAuthenticationError(state: UiState, action: UserAuthenticationFailedAction) {
  unsetAuthStorage();
  const newState = Object.assign({}, state);
  newState.authUser = undefined;
  newState.client = undefined;
  return newState;
}

function handleUnauthenticatedUserAction(state: UiState, action: UnauthenticateUserAction) {
  unsetAuthStorage();
  const newState = Object.assign({}, state);
  newState.authUser = undefined;
  newState.client = undefined;
  return newState;
}

function handleSetAccountRolesAction(state: UiState, action: SetAccountRolesAction) {
  const newState = Object.assign({}, state);
  newState.clientRole = action.payload.clientRole;
  newState.locations = action.payload.locations;
  newState.entities = action.payload.entities;
  newState.departments = action.payload.departments;
  newState.projects = action.payload.projects;
  return newState;
}

function unsetAuthStorage() {
  localStorage.removeItem('client');
  localStorage.removeItem('user');
}















