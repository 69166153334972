import {Client, UserRoles} from "@deliver-sense-librarian/data-schema";

export interface UiState {
  authUser: any;
  client: Client;
  clientRole: UserRoles,
  locations: any[];
  entities: any[];
  departments: any[];
  projects: any[];

}

export const INITIAL_UI_STATE: UiState = {
  authUser: JSON.parse(localStorage.getItem('user')),
  client: JSON.parse(localStorage.getItem('client')),
  clientRole: undefined,
  locations: [],
  entities: [],
  departments: [],
  projects: []
};
