import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {
  AuditTrailDocument,
  AuditTrailDocumentTab,
  AuditTrailDocumentTabComment,
  AuditTrailDocumentTabCommentStatuses,
  Client,
  Project,
  ThirdPartyReport,
  User,
  UserView
} from "@deliver-sense-librarian/data-schema";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {from, Subject} from "rxjs";
import {Store} from "@ngrx/store";
import {FirebaseApp} from "@angular/fire";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AngularFirestore} from "@angular/fire/firestore";
import {combineAll, takeUntil} from "rxjs/operators";
import {FirestoreUtilities} from "../../../../utilities/firestore-utilities";
import {ConfirmDialogComponent} from "../../../../dialogs/confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-reports',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.scss']
})
export class ReportsListComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public tableData: MatTableDataSource<ThirdPartyReport>;
  public displayedColumns: string[] = ['name', 'creator', 'date', 'delete'];
  private _user: User;
  private _destroy$ = new Subject();
  private _creators: UserView[] = [];
  private _client: Client;
  private reports: ThirdPartyReport[] = [];

  constructor(private store: Store<any>,
              private firebaseApp: FirebaseApp,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private afs: AngularFirestore,
              private _cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this._destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this._user = uiState$.authUser;
          this._client = uiState$.client;
          this.getReport();
        }
      });
  }

  public getCreatorInfo(doc$: AuditTrailDocument) {
    const creator = this._creators.find(user => user.id === doc$.creator);
    return creator ? creator.email ? creator.email : `${creator.firstName} ${creator.lastName}` : 'N/A';
  }

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  private getReport() {
    this.afs.collection('thirdPartyReports', ref => ref
      .where('client', '==', this._client.id))
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(reports$ => {
        this.reports = <ThirdPartyReport[]>FirestoreUtilities.mapToType(reports$);
        this.tableData = new MatTableDataSource(this.reports);
        this._getReportCreators();
      });
  }

  private _getReportCreators() {
    const creatorRequests = this.reports.map((doc$: ThirdPartyReport) => {
      return this.afs.doc(`userViews/${doc$.creator}`).snapshotChanges();
    });
    from(creatorRequests)
      .pipe(combineAll(), takeUntil(this._destroy$))
      .subscribe(userViews$ => {
        this._creators = FirestoreUtilities.mergeToType(userViews$).filter(item => !!item);
      })
  }

  async deleteReport(row: ThirdPartyReport) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this report?',
        action: 'Yes, Delete'
      }
    });
    confirmDialog.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`thirdPartyReports/${row.id}`).delete();
        this.snackBar.open('Report deleted successfully!', 'Dismiss', {
          duration: 5000
        })
      }
    });
  }
}
