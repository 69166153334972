import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqComponent } from './faq/faq.component';
import { MaterialModule } from "../../material.module";
import { MalihuScrollbarModule } from "ngx-malihu-scrollbar";
import { DialogsModule } from "../../dialogs/dialogs.module";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { FaqContentComponent } from './faq-content/faq-content.component';
import {DsSharedModule} from "../../shared/ds-shared.module";

@NgModule({
    imports: [
        CommonModule,
        MalihuScrollbarModule.forRoot(),
        MaterialModule,
        HttpClientModule,
        DialogsModule,
        ReactiveFormsModule,
        RouterModule,
        DsSharedModule,
        AngularFirestoreModule,
        FlexLayoutModule,
        BrowserAnimationsModule,
    ],
    declarations: [FaqComponent, FaqContentComponent],
    exports: [FaqComponent, FaqContentComponent]
})
export class FaqModule {
}
