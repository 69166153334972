import {MaterialModule} from '../material.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TermsAndServicesDialogComponent} from './terms-and-services-dialog/terms-and-services-dialog.component';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {SupportDialogComponent} from './support-dialog/support-dialog.component';
import {MalihuScrollbarModule} from "ngx-malihu-scrollbar";
import {TermsModule} from "../components/terms/terms.module";
import {PrivacyPolicyDialogComponent} from "./policy-policy-dialog/privacy-policy-dialog.component";
import {PolicyDialogComponent} from "./policy-dialog/policy-dialog.component";
import {HelpModule} from "../components/help/help.module";
import {ReasonPromptDialogComponent} from "./reason-prompt-dialog/reason-prompt-dialog.component";
import {StripePaymentManageDialogComponent} from './stripe-payment-manage-dialog/stripe-payment-manage-dialog.component';
import {ConfirmLeaveDialogComponent} from './unsaved-changes/confirm-leave-dialog.component';
import {DsSharedModule} from "../shared/ds-shared.module";
import {RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings} from "ng-recaptcha";
import {CreateClientDialogComponent} from './create-client/create-client.dialog.component';
import {ClientsModule} from "../components/organization/clients/clients.module";
import {CreateEntityDialogComponent} from "./create-entity/create-entity.dialog.component";
import {ConfirmDialogComponent} from "./confirm-dialog/confirm-dialog.component";
import {LoadingDialogComponent} from "./loading-dialog/loading-dialog.component";
import {OrganizationModule} from "../components/organization/organization.module";
import {LocationUploadDialogComponent} from './location-upload-dialog/location-upload-dialog.component';
import { TutorialBottomSheetComponent } from './tutorial-bottom-sheet/tutorial-bottom-sheet.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    DsSharedModule,
    FlexLayoutModule,
    RecaptchaModule.forRoot(), // Keep in mind the "forRoot"-magic nuances!
    RecaptchaFormsModule,
    MalihuScrollbarModule.forRoot(),
    TermsModule,
    HelpModule,
    ClientsModule,
    OrganizationModule
  ],
  declarations: [
    PrivacyPolicyDialogComponent,
    TermsAndServicesDialogComponent,
    SupportDialogComponent,
    PolicyDialogComponent,
    ReasonPromptDialogComponent,
    StripePaymentManageDialogComponent,
    ConfirmLeaveDialogComponent,
    CreateClientDialogComponent,
    CreateEntityDialogComponent,
    ConfirmDialogComponent,
    LoadingDialogComponent,
    LocationUploadDialogComponent,
    TutorialBottomSheetComponent
  ],
  entryComponents: [
    PrivacyPolicyDialogComponent,
    TermsAndServicesDialogComponent,
    SupportDialogComponent,
    PolicyDialogComponent,
    ReasonPromptDialogComponent,
    StripePaymentManageDialogComponent,
    ConfirmLeaveDialogComponent,
    CreateClientDialogComponent,
    CreateEntityDialogComponent,
    ConfirmDialogComponent,
    LoadingDialogComponent,
    LocationUploadDialogComponent,
    TutorialBottomSheetComponent
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LcfNbkUAAAAAEz20Rw2LMS8NAjPXA9eG-SZ89DF',
      } as RecaptchaSettings,
    },
  ]

})
export class DialogsModule {
}
