import {Component, Output, EventEmitter, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Subject} from "rxjs";
import {AngularFirestore} from '@angular/fire/firestore';
import {Store} from '@ngrx/store';
import {MatBottomSheet, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {
  auditTrailsTutorial, auditTrailTutorial,
  clientSettingTutorial,
  dashboardTutorial,
  exemptionCertificateTutorial,
  locationsTutorial, locationTutorial,
  teamMembersTutorial, thirdPartyCreateReportTutorial,
  thirdPartyReportsTutorial,
  Tutorial
} from "../../../shared/ds-constant";
import {TutorialBottomSheetComponent} from "../../../dialogs/tutorial-bottom-sheet/tutorial-bottom-sheet.component";
import {ScrollToService} from "@nicky-lenaers/ngx-scroll-to";

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html'
})
export class TutorialComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject();
  private bottomSheetRef: MatBottomSheetRef;
  private currentTutorial: Tutorial | null;

  constructor(private afs: AngularFirestore,
              private store: Store<any>,
              private bottomSheet: MatBottomSheet,
              private router: Router) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  startTutorial() {
    const url = this.router.url;
    this.currentTutorial = this.getTutorial(url) ? this.getTutorial(url) : null;
    this.bottomSheetRef = this.bottomSheet.open(TutorialBottomSheetComponent, {
      panelClass: 'overflow-bottom-sheet',
      data: {
        tutorial: this.currentTutorial
      }
    })
  }

  private getTutorial(url: string) {
    let tutorial;
    switch (url) {
      case '/app/dashboard':
        tutorial = dashboardTutorial();
        break;
      case '/app/organization/team':
        tutorial = teamMembersTutorial();
        break;
      case '/app/organization/locations':
        tutorial = locationsTutorial();
        break;
      case '/app/3pd-reports/list':
        tutorial = thirdPartyReportsTutorial();
        break;
      case '/app/exemption-certificates/list':
        tutorial = exemptionCertificateTutorial();
        break;
      case '/app/audit-trails/list':
        tutorial = auditTrailsTutorial();
        break;
      case '/app/client':
        tutorial = clientSettingTutorial();
        break;
      default:
        tutorial = null;
        break;
    }
    if (tutorial) {
      return tutorial
    } else {
      if (url.includes('/app/3pd-reports/')) {
        return thirdPartyCreateReportTutorial();
      } else if (url.includes('/app/organization/locations/')) {
        return locationTutorial();
      } else if (url.includes('/app/audit-trails/')) {
        return auditTrailTutorial();
      }
    }
  }
}

