import {Component, OnInit} from '@angular/core';
import {MatDialogRef, MatSnackBar} from "@angular/material";
import {FirebaseAuthService} from "../../auth/services/firebase-auth.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AngularFirestore} from "@angular/fire/firestore";
import {Client, User} from "@deliver-sense-librarian/data-schema";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from '../../../environments/environment';
import {LoadingDialogService} from "../../services/loading-dialog.service";
import {Store} from '@ngrx/store';
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'app-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.scss']
})
export class SupportDialogComponent implements OnInit {

  supportForm: FormGroup;
  user: User;
  reasons: ['General Questions', 'Billing Issues', 'Technical Issues'];
  _client: Client;
  private _destroy$ = new Subject();

  constructor(private http: HttpClient,
              public dialogRef: MatDialogRef<SupportDialogComponent>,
              private store: Store<any>,
              private fb: FormBuilder,
              private loadingService: LoadingDialogService,
              private afs: AngularFirestore,
              public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this._destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this.user = uiState$.authUser;
          this._client = uiState$.client;
        }
        this.setupContactForm();
      });
  }

  setupContactForm() {
    this.supportForm = this.fb.group({
      user: new FormControl(this.user && this.user.id ? this.user.id : null),
      email: new FormControl(this.user && this.user.email ? this.user.email : '', Validators.required),
      subject: new FormControl(null, Validators.required),
      message: new FormControl(null, Validators.required),
      recaptchaReactive: new FormControl(null, Validators.required)
    })
  }

  sendMessage() {
    if (this.supportForm.valid) {
      let userName = 'Unauthenticated User';
      if (this.user) {
        userName = this.user.firstName + ' ' + this.user.lastName;
      }
      const userEmail = this.supportForm.get('email').value;
      const text = this.supportForm.get('message').value;
      const subject = `${this.supportForm.get('subject').value} Support Contact from ${userName}`;
      const body = {
        email: userEmail,
        client: this._client.id,
        subject: subject,
        message: text
      };
      this.loadingService.isLoading(true, 'Sending your message to support...');
      this.http.post(`${environment.operationsApiUrl}support`, body, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        responseType: 'text'
      }).subscribe(() => {
          this.snackBar.open('Thank you for your message! Check your email for your confirmation.', 'Dismiss', {
            duration: 5000,
          });
          this.loadingService.isLoading(false);
          this.dialogRef.close();
        },
        err => {
          this.loadingService.isLoading(false);
          this.snackBar.open('Error sending your message. Please refresh the page and try again.', 'Dismiss', {
            duration: 5000,
          })
        }
      );
    } else {
      this.snackBar.open('Please complete the form before sending your inquiry.', 'Dismiss', {
        duration: 5000
      })
    }
  }
}
