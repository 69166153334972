import {PosTransaction, ThirdPartyTransaction} from "@deliver-sense-librarian/data-schema";
import moment = require("moment");
import * as firebase from "firebase";
import Timestamp = firebase.firestore.Timestamp;
import {LocationReport} from "./create-report/create-report.component";
import * as _ from 'lodash';
export class ThirdPartyDeliveryAnalyticsEngine {
  public posTransactions: PosTransaction[] = [];
  public thirdPartyTransactions: ThirdPartyTransaction[] = [];
  public matchingComplete = false;
  private distances: any[] = [];
  private _locationReportData: LocationReport[] = [];
  private matches = [];
  private unMatchedCount = 0;

  public setLocationReportData(locationReportData) {
    this._locationReportData = locationReportData;
    this.triggerAnalysis();
  }

  public get transactionMatches() {
    return this.matches;
  }

  constructor(private data?: LocationReport[]) {
    this._locationReportData = data;
    this.triggerAnalysis();
  }

  private triggerAnalysis() {
    if (this._locationReportData && this._locationReportData.length > 0) {
      this.analyzeResults();
      this.runTransactionMatches();
    }
  }

  /***************************************
   * Calculation Functions *
   ****************************************/
  private analyzeResults() {
    this._locationReportData.forEach((locationReport: LocationReport) => {
      /** fixed order ***/
      this.calculateThirdPartySalesTotal(locationReport);
      this.calculateThirdPartyTaxTotal(locationReport);
      this.calculateThirdPartyEffectiveRate(locationReport);

      this.calculatePosSalesTotal(locationReport);
      this.calculatePosTaxTotal(locationReport);
      this.calculateLocationTaxRatesTotal(locationReport);

      this.calculateDeliverFeeTotal(locationReport);
      this.calculateOtherFeeTotal(locationReport);
      this.calculateActualRemittance(locationReport);
      this.calculateMarketFacilitatorTaxTotal(locationReport);
      this.calculateExpectedRemittance(locationReport); // after 3pdSales and delvieryFee and 3pd tax and mfTax and other fees

      this.calculateTaxResponsibility(locationReport); // after 3pdTax and market facilitator
      this.calculateTaxAdjustment(locationReport); // after tax responsibility
    });
  }

  private calculateThirdPartySalesTotal(locationReport: LocationReport) {
    locationReport.thirdPartySales = locationReport['thirdPartyTransactions'].reduce((prev: number, transaction: ThirdPartyTransaction) => {
      if (+transaction.sale) {
        prev += transaction.sale;
      }
      if (+transaction.saleCorrection) {
        prev -= transaction.saleCorrection;
      }
      return +(prev).toFixed(2);
    }, 0);
  }

  private calculateThirdPartyTaxTotal(locationReport: LocationReport) {
    locationReport.thirdPartyTax = locationReport.thirdPartyTransactions
      .reduce((prev: number, transaction: ThirdPartyTransaction) => {
        if (+transaction.tax) {
          prev += transaction.tax;
        }
        if (+transaction.taxCorrection) {
          prev -= transaction.taxCorrection;
        }
        return +(prev).toFixed(2);
      }, 0);
  }


  private calculateThirdPartyEffectiveRate(locationReport: LocationReport) {
    locationReport.thirdPartyEffectiveTaxRate = locationReport.thirdPartyTax === 0 ? 0 :
      +(+locationReport.thirdPartyTax / +locationReport.thirdPartySales).toFixed(2) * 100;
  }

  private calculatePosSalesTotal(locationReport: LocationReport) {
    locationReport.posSales = locationReport.posTransactions
      .reduce((prev: number, transaction: PosTransaction) => {
        if (+transaction.sale) {
          prev += transaction.sale;
        }
        return +(prev).toFixed(2);
      }, 0);
  }

  private calculatePosTaxTotal(locationReport: LocationReport) {
    locationReport.posTax = locationReport.posTransactions
      .reduce((prev: number, transaction: PosTransaction) => {
        if (+transaction.tax) {
          prev += transaction.tax;
        }
        return +(prev).toFixed(2);
      }, 0);
  }

  private calculateLocationTaxRatesTotal(locationReport: LocationReport) {
    locationReport.locationTaxRate = +(
      +locationReport.stateTaxRate +
      +locationReport.countyTaxRate +
      +locationReport.cityTaxRate +
      +locationReport.specialTaxRate
    ).toFixed(2);
  }

  private calculateDeliverFeeTotal(locationReport: LocationReport) {
    locationReport.deliveryFees = locationReport.thirdPartyTransactions
      .reduce((prev: number, transaction: ThirdPartyTransaction) => {
        if (+transaction.deliveryFeeTotal) {
          prev += transaction.deliveryFeeTotal;
        }
        return +(prev).toFixed(2);
      }, 0);
  }

  private calculateOtherFeeTotal(locationReport: LocationReport) {
    locationReport.otherFees = locationReport.thirdPartyTransactions
      .reduce((prev: number, transaction: ThirdPartyTransaction) => {
        if (+transaction.promoFee) {
          prev += transaction.promoFee;
        }
        return +(prev).toFixed(2);
      }, 0);
  }

  private calculateActualRemittance(locationReport: LocationReport) {
    locationReport.actualRemittance = locationReport.thirdPartyTransactions
      .reduce((prev: number, transaction: ThirdPartyTransaction) => {
        if (+transaction.totalRemitted) {
          prev += transaction.totalRemitted;
        }
        return +(prev).toFixed(2);
      }, 0);
  }

  private calculateMarketFacilitatorTaxTotal(locationReport: LocationReport) {
    locationReport.marketFacilitatorTax = locationReport.thirdPartyTransactions
      .reduce((prev: number, transaction: ThirdPartyTransaction) => {
        if (+transaction.taxRemitted) {
          prev += transaction.taxRemitted;
        }
        return +(prev).toFixed(2);
      }, 0);
  }

  private calculateExpectedRemittance(locationReport: LocationReport) {
    locationReport.expectedRemittance = +(locationReport.thirdPartySales - locationReport.deliveryFees + (locationReport.thirdPartyTax - locationReport.marketFacilitatorTax) - locationReport.otherFees).toFixed(2);
  }

  private calculateTaxResponsibility(locationReport: LocationReport) {
    locationReport.taxResponsibility = locationReport.thirdPartyTax - locationReport.marketFacilitatorTax;
  }

  private calculateTaxAdjustment(locationReport: LocationReport) {
    locationReport.taxAdjustment = +(locationReport.posTax - locationReport.taxResponsibility).toFixed(2);
  }


  /***************************************
   * Transaction Mapping Functions *
   ****************************************/

  public runTransactionMatches() {
    this.posTransactions = this._locationReportData.reduce((arr, curr) => {
      return [...arr, ...curr.posTransactions]
    }, []);
    this.thirdPartyTransactions = this._locationReportData.reduce((arr, curr) => {
      return [...arr, ...curr.thirdPartyTransactions];
    }, [])
    _.uniqBy(this.posTransactions, 'id');
    _.uniqBy(this.thirdPartyTransactions, 'id');
    this.posTransactions.forEach((posTransaction: PosTransaction) => {
      posTransaction.date = new Timestamp(posTransaction.date.seconds, posTransaction.date.nanoseconds);
    });
    this.thirdPartyTransactions.forEach((thirdPartyTransaction: ThirdPartyTransaction) => {
      thirdPartyTransaction.date = new Timestamp(thirdPartyTransaction.date.seconds, thirdPartyTransaction.date.nanoseconds);
    });
    this.createDistanceMatrix();
  }

  private createDistanceMatrix() {
    const matrix = {};
    this.posTransactions.forEach((posTransaction: PosTransaction) => {
      matrix[posTransaction.id] = [];
      const posTransactionPoint: { subTotal?, tax?, date? } = {};
      posTransactionPoint.subTotal = posTransaction.sale ? posTransaction.sale : 0;
      posTransactionPoint.tax = posTransaction.tax ? posTransaction.tax : 0;
      posTransactionPoint.date = posTransaction.date ? moment(posTransaction.date.toDate()).unix() : 0;
      this.thirdPartyTransactions.forEach((thirdPartyTransaction: ThirdPartyTransaction) => {
        const thirdPartyTransactionPoint: { subTotal?, tax?, date? } = {};
        thirdPartyTransactionPoint.subTotal = thirdPartyTransaction.sale ? thirdPartyTransaction.sale : 0;
        thirdPartyTransactionPoint.tax = thirdPartyTransaction.tax ? thirdPartyTransaction.tax : 0;
        thirdPartyTransactionPoint.date = thirdPartyTransaction.date ? moment(thirdPartyTransaction.date.toDate()).unix() : 0;
        const distance = this.getEuclideanDistance(posTransactionPoint, thirdPartyTransactionPoint);
        const relatviceDistanceObject = {
          thirdPartyTransaction: thirdPartyTransaction.id,
          distance
        };
        matrix[posTransaction.id].push(relatviceDistanceObject);
      });
      matrix[posTransaction.id].sort((a, b) => {
        return a.distance < b.distance ? -1 : a.distance > b.distance ? 1 : 0;
      });
      const lowestDistanceMatch = matrix[posTransaction.id][0].distance;
      const lowestDistanceThirdPartyTransactionId = matrix[posTransaction.id][0].thirdPartyTransaction;
      this.distances.push(lowestDistanceMatch);
      this.matches.push({
        posTransaction: posTransaction.id,
        thirdPartyTransaction: lowestDistanceThirdPartyTransactionId,
        match: lowestDistanceMatch
      });
    });
    this.distances.sort((a, b) => {
      return a < b ? -1 : a > b ? 1 : 0;
    });
    this.thirdPartyTransactions.forEach(thirdPartyTransaction => {
      const matched = this.matches.find(match => match.thirdPartyTransaction === thirdPartyTransaction.id);
      if (!matched) {
        this.unMatchedCount++;
        this.matches.push({
          thirdPartyTransaction: thirdPartyTransaction.id,
          match: 1 // 1-1 = 0 --> no match
        });
      }
    });
    this.matchingComplete = true;
  }

  get minDistance() {
    return this.distances[0];
  }

  get maxDistance() {
    return this.distances[this.distances.length - 1];
  }

  public normalizeDistance(x) {
    if (x === 1) {
      return 0;
    }
    if (x !== 0) {
      return +((1 - (x - this.minDistance) / (this.maxDistance - this.minDistance)) * 100).toFixed(2);
    } else {
      return 100;
    }
  }

  private getEuclideanDistance(p1, p2) {
    const xdiff = Math.pow((p1[Object.keys(p1)[0]] - p2[Object.keys(p1)[0]]), 2);
    const ydiff = Math.pow((p1[Object.keys(p1)[1]] - p2[Object.keys(p2)[1]]), 2);
    const zdiff = Math.pow((p1[Object.keys(p1)[2]] - p2[Object.keys(p2)[2]]), 2);
    return Math.sqrt(xdiff + ydiff + zdiff)
  }

  public perc2color(perc) {
    let r, g = 0;
    const b = 0;
    if (perc < 50) {
      r = 255;
      g = Math.round(5.1 * perc);
    } else {
      g = 255;
      r = Math.round(510 - 5.10 * perc);
    }
    const h = r * 0x10000 + g * 0x100 + b * 0x1;
    const hexColor = '#' + ('000000' + h.toString(16)).slice(-6);
    const rgb = this.hexToRgb(hexColor);
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.6)`;
  }

  private hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }
}
