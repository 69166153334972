import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {AngularFirestore} from "@angular/fire/firestore";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {
  User,
  UserRoles,
  TeamMemberInvitation,
  Client,
  Entity,
  Department,
  Location,
  Project,
  UserView,
  ClientRole, OrganizationRole
} from "@deliver-sense-librarian/data-schema";
import moment = require("moment");
import {ConfirmDialogComponent} from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {map, take, takeUntil} from "rxjs/operators";
import {Store} from '@ngrx/store';
import {combineLatest, Observable, Subject} from "rxjs";
import {FirestoreUtilities} from "../../../../utilities/firestore-utilities";
import {environment} from "../../../../../environments/environment";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {AngularFireAuth} from "@angular/fire/auth";
import {scrollbarOptions} from 'app/shared/ds-constant';
import {LoadingDialogService} from "../../../../services/loading-dialog.service";
import {UiState} from "../../../../redux/custom-states/uiState/ui-state";
import {ActivatedRoute, Router} from "@angular/router";

export class TeamMemberClientRole {
  role: number;
  id: string;
  locations: OrganizationRole[] = [];
  entities: OrganizationRole[] = [];
  departments: OrganizationRole[] = [];
  projects: OrganizationRole[] = [];
}

@Component({
  selector: 'app-create-team-member',
  templateUrl: './team-member.component.html',
  styleUrls: ['./team-member.component.scss']
})
export class TeamMemberComponent implements OnInit, OnDestroy {
  @Input() teamMember: UserView;
  @Output() complete = new EventEmitter();
  public emailToSendTo = new FormControl('', [Validators.required, Validators.email]);
  public roles = [
    {name: 'admin', value: UserRoles.admin},
    {name: 'contributor', value: UserRoles.contributor},
    {name: 'viewer', value: UserRoles.viewer},
  ];
  public entities: Entity[] = [];
  public selectedEntities = new FormControl();
  public departments: Department[] = [];
  public filteredDepartments: Department[] = [];
  public selectedDepartments = new FormControl();
  public projects: Project[] = [];
  public filteredProjects: Project[] = [];
  public selectedProjects = new FormControl();
  public locations: Location[] = [];
  public filteredLocations: Location[] = [];
  public selectedLocations = new FormControl();
  public client: Client;
  public clientRoleForm: FormGroup;
  public scrollbarOptions = scrollbarOptions;
  private _user: User;
  private _destroy$ = new Subject();
  private teamMemberClientRoles: TeamMemberClientRole;
  private uiState: UiState;

  constructor(private snackBar: MatSnackBar,
              private http: HttpClient,
              private afAuth: AngularFireAuth,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private loadingService: LoadingDialogService,
              private store: Store<any>,
              private dialog: MatDialog,
              private fb: FormBuilder,
              private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this._destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this._user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$;
          this.activatedRoute.params.subscribe(params$ => {
            const teamMemberId = params$['id'];
            if (teamMemberId && teamMemberId !== 'new') {
              this.fetchTeamMember(teamMemberId);
            } else {
              this._setupClientRoleForm();
              this._getAccessibleOrganizationResources();
              if (this.teamMember && this.teamMember.id) {
                this.fetchTeamMemberClientRoles();
              } else {
                this.teamMemberClientRoles = new TeamMemberClientRole();
              }
            }
          });
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  private fetchTeamMember(teamMemberId) {
    this.afs.doc(`userViews/${teamMemberId}`)
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe((teamMemberUserView$) => {
        this.teamMember = FirestoreUtilities.objectToType(teamMemberUserView$);
        this._setupClientRoleForm();
        this._getAccessibleOrganizationResources();
        this.fetchTeamMemberClientRoles();
      });
  }

  private _getAccessibleOrganizationResources() {
    combineLatest([
      FirestoreUtilities.getUserAccessibleResourcesOfType('entities', this.afs, this.uiState.entities, [UserRoles.admin]),
      FirestoreUtilities.getUserAccessibleResourcesOfType('departments', this.afs, this.uiState.departments, [UserRoles.admin]),
      FirestoreUtilities.getUserAccessibleResourcesOfType('projects', this.afs, this.uiState.projects, [UserRoles.admin]),
      FirestoreUtilities.getUserAccessibleResourcesOfType('locations', this.afs, this.uiState.locations, [UserRoles.admin])
    ])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([entities$, departments$, projects$, locations$]) => {
        this.entities = <Entity[]>(entities$);
        this.departments = <Department[]>(departments$);
        this.projects = <Project[]>(projects$);
        this.locations = <Location[]>(locations$);
        this._setupResourceSelectionListeners();
        if (this.teamMember && this.teamMember.id) {
          this.fetchTeamMemberClientRoles();
        }
      })
  }

  private _setupResourceSelectionListeners() {
    this.selectedEntities.valueChanges.subscribe(selectedEntities$ => {
      this._populateResourceRoleArray(selectedEntities$, 'entities');
      // Entity --> Departments
      this.filteredDepartments = this._filterChildResourceType('departments', selectedEntities$, 'entity');
      this.updateFormSelectionFromAvailability(this.selectedDepartments, this.filteredDepartments);
      this.selectedDepartments.updateValueAndValidity();
      // Entity --> Locations
      this.filteredLocations = this._filterChildResourceType('locations', selectedEntities$, 'entity');
      this.updateFormSelectionFromAvailability(this.selectedLocations, this.filteredLocations);
      this.selectedLocations.updateValueAndValidity();
    });
    this.selectedLocations.valueChanges.subscribe(selectedLocations$ => {
      this._populateResourceRoleArray(selectedLocations$, 'locations');
    });
    this.selectedDepartments.valueChanges.subscribe(selectedDepartments$ => {
      this._populateResourceRoleArray(selectedDepartments$, 'departments');
      this.filteredProjects = this._filterChildResourceType('projects', selectedDepartments$, 'department');
      this.updateFormSelectionFromAvailability(this.selectedProjects, this.filteredProjects);
      this.selectedProjects.updateValueAndValidity();
    });
    this.selectedProjects.valueChanges.subscribe(selectedProjects$ => {
      this._populateResourceRoleArray(selectedProjects$, 'projects');
    });
  }

  /**
   * When the selection of resources changes update the master clientRoleForm to only have controls for the
   * available resources.
   * @param resourceIds
   * @param resourceName
   * @private
   */
  private _populateResourceRoleArray(resourceIds: any[], resourceName: string) {
    const formArray = this.clientRoleForm.get(resourceName) as FormArray;
    formArray.clear();
    if (resourceIds) {
      resourceIds.forEach(resourceId => {
        const resourceRole = this.teamMemberClientRoles[resourceName].find(role => role.resource === resourceId);
        formArray.push(new FormGroup({
          role: new FormControl(this.teamMemberClientRoles && this.teamMemberClientRoles[resourceName] ?
            (resourceRole ? resourceRole.role : '') :
            '',
            Validators.required),
          record: new FormControl(resourceId, Validators.required)
        }));
      });
    }
  }

  /**
   * If the parent selection changes filter out the available tutorial to only children of available parents
   * @param type
   * @param parentIds
   * @param relationshipKey
   * @private
   */
  private _filterChildResourceType(type: string, parentIds: string[], relationshipKey: string) {
    if (parentIds) {
      return parentIds.reduce((children, parentId) => {
        const childrenOfParent = this[type].filter(child => child[relationshipKey] === parentId);
        children = [...children, ...childrenOfParent];
        return children;
      }, []);
    } else {
      return [];
    }
  }

  /**
   * If parent selection changes unselect children that are no longer available
   * Chain result down the heirarchy.
   * @param formControl
   * @param filteredResources
   */
  private updateFormSelectionFromAvailability(formControl: FormControl, filteredResources: any[]) {
    let selection = formControl.value;
    if (selection && selection instanceof Array) {
      selection = selection.filter(selectedId => !!filteredResources.find(filteredResource => filteredResource.id === selectedId));
      formControl.patchValue(selection);
      formControl.updateValueAndValidity();
    }
  }

  public async save() {
    if (this.clientRoleForm.valid) {
      if (this.teamMember && this.teamMember.id) {
        this._updateTeamMember();
      } else {
        this._sendInvitation();
      }
    } else {
      this.snackBar.open('Please enter an email and select a role for each entity, department, and project.', 'Dismiss', {
        duration: 5000
      });
    }
  }

  private async _sendInvitation() {
    if (this.emailToSendTo.valid) {
      const invitation = this._mapValuesToInvitation();
      this.loadingService.isLoading(true, 'Sending Invite...');
      await this.afs.collection('teamMemberInvitations').add(invitation.toJSONObject());
      this.loadingService.isLoading(false);
      this.snackBar.open('Email sent to team member.', 'Dismiss', {
        duration: 5000
      });
      this.complete.emit(true);
      this.router.navigate(['/app/organization/team']);
    } else {
      this.snackBar.open('Please provide an email to send the invitation to.', 'Dismiss', {
        duration: 5000
      });
    }
  }

  private getToken(token): HttpHeaders {
    return new HttpHeaders().set('Authorization', `Bearer ${token}`);
  }

  private async _updateTeamMember() {
    const userClientRoleRef = this.afs.doc(`users/${this.teamMember.id}/clientRoles/${this.client.id}`);
    const userOrgRolesRef = this.afs.collection(`users/${this.teamMember.id}/clientRoles/${this.client.id}/organizationRoles`);
    const updatedRoles = this.getUpdatedOrgRolesFromForm();
    this.loadingService.isLoading(true, 'Updating team member values');
    await userClientRoleRef.set({
      role: updatedRoles.role,
      resource: this.client.id
    });
    const orgRolesBatch = this.afs.firestore.batch();
    updatedRoles.entities.forEach((entityRole: OrganizationRole) => {
      orgRolesBatch.set(userOrgRolesRef.doc(entityRole.resource).ref, {
        resource: entityRole.resource,
        role: entityRole.role,
        type: entityRole.type
      });
    });
    updatedRoles.locations.forEach((locationRole: OrganizationRole) => {
      orgRolesBatch.set(userOrgRolesRef.doc(locationRole.resource).ref, {
        resource: locationRole.resource,
        role: locationRole.role,
        type: locationRole.type
      });
    });
    updatedRoles.departments.forEach((departmentRole: OrganizationRole) => {
      orgRolesBatch.set(userOrgRolesRef.doc(departmentRole.resource).ref, {
        resource: departmentRole.resource,
        role: departmentRole.role,
        type: departmentRole.type
      });
    });
    updatedRoles.projects.forEach((projectRole: OrganizationRole) => {
      orgRolesBatch.set(userOrgRolesRef.doc(projectRole.resource).ref, {
        resource: projectRole.resource,
        role: projectRole.role,
        type: projectRole.type
      });
    });
    try {
      await orgRolesBatch.commit();
      this.loadingService.isLoading(false);
      this.snackBar.open('Successfully updated team member roles.', 'Dismiss', {
        duration: 5000
      });
      this.complete.emit(true);
      this.router.navigate(['/app/organization/team']);
    } catch (e) {
      this.loadingService.isLoading(false);
      this.snackBar.open('Something went wrong updating your team members roles. Please refresh and try again.', 'Dismiss', {
        duration: 5000
      });
    }
  }

  private _mapValuesToInvitation() {
    const invitation = new TeamMemberInvitation();
    invitation.client = this.client.id;
    invitation.from = this._user.id;
    invitation.email = this.emailToSendTo.value;
    invitation.clientRole = this.getUpdatedOrgRolesFromForm();
    invitation.status = 'pending';
    invitation.expiration = moment().add(24, 'hours').toDate();
    return invitation;
  }

  private getUpdatedOrgRolesFromForm() {
    const formValues = this.clientRoleForm.value;
    const newClientRole = {
      role: formValues.role,
      entities: [],
      departments: [],
      projects: [],
      locations: []
    };
    formValues.entities.forEach(entityRole => {
      const role = new OrganizationRole();
      role.resource = entityRole.record;
      role.type = 'entity';
      role.role = entityRole.role;
      newClientRole.entities.push(role.toJSONObject());
    });
    formValues.departments.forEach(departmentRole => {
      const role = new OrganizationRole();
      role.resource = departmentRole.record;
      role.type = 'department';
      role.role = departmentRole.role;
      newClientRole.departments.push(role.toJSONObject());
    });
    formValues.projects.forEach(projectRole => {
      const role = new OrganizationRole();
      role.resource = projectRole.record;
      role.type = 'project';
      role.role = projectRole.role;
      newClientRole.projects.push(role.toJSONObject());
    });
    formValues.locations.forEach(locationRole => {
      const role = new OrganizationRole();
      role.resource = locationRole.record;
      role.type = 'location';
      role.role = locationRole.role;
      newClientRole.locations.push(role.toJSONObject());
    });
    return newClientRole;
  }

  cancel() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Cancel',
        message: 'Are you sure you want to discard your changes?',
        action: 'Yes, cancel.'
      }
    });
    dialogRef.afterClosed().subscribe(discardChanges => {
      if (discardChanges) {
        this.complete.emit(false);
        this.router.navigate(['../']);
      }
    });
  }


  private mapExistingTeamMemberValues() {
    this.clientRoleForm.get('role').patchValue(this.teamMemberClientRoles.role);
    this.clientRoleForm.get('role').updateValueAndValidity();
    this.selectedEntities.patchValue(this.teamMemberClientRoles.entities.map(role => role.resource));
    this.selectedEntities.updateValueAndValidity();
    this.selectedDepartments.patchValue(this.teamMemberClientRoles.departments.map(role => role.resource));
    this.selectedDepartments.updateValueAndValidity();
    this.selectedProjects.patchValue(this.teamMemberClientRoles.projects.map(role => role.resource));
    this.selectedProjects.updateValueAndValidity();
    this.selectedLocations.patchValue(this.teamMemberClientRoles.locations.map(role => role.resource));
    this.selectedLocations.updateValueAndValidity();
  }


  private _setupClientRoleForm() {
    this.clientRoleForm = this.fb.group({
      role: new FormControl(this.teamMemberClientRoles ? this.teamMemberClientRoles.role : '', Validators.required),
      locations: new FormArray([]),
      entities: new FormArray([]),
      departments: new FormArray([]),
      projects: new FormArray([]),
    });
    if (this.teamMemberClientRoles) {
      this.mapExistingTeamMemberValues();
    }
  }

  getResourceName(id: any, collection: string) {
    const record = this[collection].find(_r => _r.id === id);
    return record ? record.name : '';
  }

  /**
   * Reset form to existing team member role values
   */
  reset() {
    this.mapExistingTeamMemberValues();
  }

  private fetchTeamMemberClientRoles() {
    combineLatest([
      this.afs.doc(`users/${this.teamMember.id}/clientRoles/${this.client.id}`)
        .snapshotChanges(),
      this.afs.collection(`users/${this.teamMember.id}/clientRoles/${this.client.id}/organizationRoles`)
        .snapshotChanges()
    ])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([teamMemberClientRole$, organizationRoles$]) => {
        const teamMemberClientRole = FirestoreUtilities.objectToType(teamMemberClientRole$);
        const organizationRoles = FirestoreUtilities.mapToType(organizationRoles$);
        this.teamMemberClientRoles = new TeamMemberClientRole();
        this.teamMemberClientRoles.role = teamMemberClientRole.role;
        this.teamMemberClientRoles.id = teamMemberClientRole.resource;
        this.teamMemberClientRoles.entities = organizationRoles ? organizationRoles.filter(orgRole => orgRole.type === 'entity') : [];
        this.teamMemberClientRoles.locations = organizationRoles ? organizationRoles.filter(orgRole => orgRole.type === 'location') : [];
        this.teamMemberClientRoles.departments = organizationRoles ? organizationRoles.filter(orgRole => orgRole.type === 'department') : [];
        this.teamMemberClientRoles.projects = organizationRoles ? organizationRoles.filter(orgRole => orgRole.type === 'project') : [];
        this.mapExistingTeamMemberValues();
      });
  }

  async delete() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Remove Team Member',
        message: `Are you sure you want to remove this team member? This will delete all the roles this user has for ${this.client.name}, but will not delete the user's account.`,
        action: 'Yes, remove.'
      }
    });
    dialogRef.afterClosed().subscribe(async (discardChanges) => {
      if (discardChanges) {
        await this.afs.doc(`users/${this.teamMember.id}/clientRoles/${this.client.id}`).delete();
        this.snackBar.open('Successfully removed team member.', 'Dismiss', {
          duration: 5000
        })
      }
    });
  }
}
