import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from "@angular/fire/firestore";
import { FirestoreUtilities } from "../../../utilities/firestore-utilities";
import { Faq } from "@deliver-sense-librarian/data-schema";
import * as moment from 'moment';
import {from, Observable, Subject} from "rxjs";
import { SupportDialogComponent } from "../../../dialogs/support-dialog/support-dialog.component";
import { MatDialog } from "@angular/material";
import {combineAll, takeUntil} from "rxjs/operators";

@Component({
    selector: 'app-faq-content',
    templateUrl: './faq-content.component.html',
    styleUrls: ['./faq-content.component.scss']
})
export class FaqContentComponent implements OnInit, OnDestroy {
    faqs: Faq[] = [];
    destroy$ = new Subject();
    constructor(private afs: AngularFirestore,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this.afs.collection('faqs').snapshotChanges().subscribe(faqs$ => {
            this.faqs = FirestoreUtilities.mapToType(faqs$);
            this.faqs.sort((a, b) => {
                if (a.order > b.order) {
                    return 1;
                } else if (a.order === b.order) {
                    if (moment(a.dateUpdated).isBefore(moment(b.dateUpdated))) {
                        return 1
                    } else {
                        return -1
                    }
                } else {
                    return -1;
                }
            });
        });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    showSupportDialog() {
        this.dialog.open(SupportDialogComponent);
    }
}
