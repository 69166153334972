import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource} from "@angular/material";
import {Papa} from "ngx-papaparse";
import {AngularFirestore} from '@angular/fire/firestore';
import {combineAll, map, takeUntil} from "rxjs/operators";
import {from, Subject} from "rxjs";
import {FirebaseAuthService} from "../../../auth/services/firebase-auth.service";
import {PosTransaction, User, ExemptionCertificate, Client} from "@deliver-sense-librarian/data-schema";
import {FirestoreUtilities} from "../../../utilities/firestore-utilities";
import {FirebaseApp} from 'angularfire2';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ConfirmDialogComponent} from "../../../dialogs/confirm-dialog/confirm-dialog.component";
import {Store} from '@ngrx/store';
import {tableExpandAnimation} from "../../../shared/ds-constant";
import {AngularFireStorage} from '@angular/fire/storage';

@Component({
  selector: 'app-exemption-certificate-management',
  templateUrl: './exemption-certificate-management.component.html',
  styleUrls: ['./exemption-certificate-management.component.scss'],
  animations: tableExpandAnimation
})
export class ExemptionCertificateManagementComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public tableData: MatTableDataSource<PosTransaction>;
  public displayedColumns: string[] = ['documented', 'id', 'location', 'date', 'sale', 'tax', 'exemption_details', 'expandTrigger'];
  public expandedElement: any | null;
  private _user: User;
  private _destroy$ = new Subject();
  private _transactions: PosTransaction[] = [];
  private excNoteUpdateTimeout: any;
  storagePath: any;
  private _client: Client;

  constructor(private store: Store<any>,
              private papa: Papa,
              private firebaseApp: FirebaseApp,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private afs: AngularFirestore,
              private storage: AngularFireStorage,
              private _cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this._destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this._user = uiState$.authUser;
          this._client = uiState$.client;
          this.storagePath = `clients/${this._client.id}/exemptionCertificates/`;
          this._fetchExemptOrders();
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  private _fetchExemptOrders() {
    this.afs.collection('posTransactions', ref => ref
      .where('account', '==', 'ex')
      .where('client', '==', this._client.id))
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(transactions$ => {
        this._transactions = FirestoreUtilities.mapToType(transactions$);
        this._fetchTransactionExemptionCertificates();
      });
  }

  getDownloadUrl(exemptionCertificate: ExemptionCertificate) {
  }

  applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  private _fetchTransactionExemptionCertificates() {
    const exCertRequests = this._transactions.map(transaction => {
      return this.afs.collection('exemptionCertificates', ref => ref
        .where('posTransaction', '==', transaction.id))
        .snapshotChanges();
    });
    from(exCertRequests)
      .pipe(combineAll(), takeUntil(this._destroy$))
      .subscribe(exCerts$ => {
        const exemptionCertificates = FirestoreUtilities.mergeCollectionToType(exCerts$);
        const downloadUrlRequests = exemptionCertificates.forEach(exc => {
          return this.storage.ref(exc.document).getDownloadURL().subscribe(downloadUrl => {
            exc['url'] = downloadUrl;
          });
        });
        this._transactions.forEach(transaction => {
          const matchingExCert = exemptionCertificates.find(exc => exc.posTransaction === transaction.id);
          transaction['exemptionCertificateExists'] = !!matchingExCert ? 'documented' : false;
          transaction['exemptionCertificate'] = matchingExCert;
        });
        this.tableData = new MatTableDataSource(this._transactions);
        this.tableData.paginator = this.paginator;
        this.tableData.sort = this.sort;
        this._cdr.detectChanges();
      });
  }

  updateExemptionCertificateNotes(exemptionCertificate: ExemptionCertificate, value) {
    if (this.excNoteUpdateTimeout) {
      clearTimeout(this.excNoteUpdateTimeout);
    }
    this.excNoteUpdateTimeout = setTimeout(() => {
      this.afs.doc(`exemptionCertificates/${exemptionCertificate.id}`).update({
        notes: value
      }).then(() => {
        this.snackBar.open('Exemption Certificate notes updated successfully', 'Dismiss', {
          duration: 5000
        })
      })
    }, 300)
  }

  addExemptionCert($event: any) {
    if (this.expandedElement) {
      this.afs.collection('exemptionCertificates').add({
        posTransaction: this.expandedElement.id,
        client: this._client.id,
        document: $event.path,
        fileSize: $event.size
      }).then(() => {
        this.snackBar.open('Exemption Certificate added successfully', 'Dismiss', {
          duration: 5000
        })
      });
    } else {
      this.snackBar.open('Oops.. something went wrong. Please refresh and try again.', 'Dismiss', {
        duration: 5000
      })
    }

  }

  async deleteExemptionCertificate(exemptionCertificate: ExemptionCertificate) {
    const path = exemptionCertificate.document;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: 'Are you sure you want to remove this exemption certificate?',
        action: 'Yes, delete.'
      }
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`exemptionCertificates/${exemptionCertificate.id}`).delete();
        await FirestoreUtilities.deleteImage(path);
        this.snackBar.open('Exemption Certificate removed successfully', 'Dismiss', {
          duration: 5000
        })
      }
    });

  }

  exportDocumentation() {

  }
}
