import {Component, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {FirebaseAuthService} from "../services/firebase-auth.service";
import {MatSnackBar} from "@angular/material";
import {take, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {LoadingDialogService} from "../../services/loading-dialog.service";
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {

  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  error = '';
  loading = false;
  private destroy$ = new Subject();

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private loadingService: LoadingDialogService,
              private snackBar: MatSnackBar,
              private store: Store<any>,
              private fbAuth: FirebaseAuthService) {
  }

  ngOnInit() {
    this.store.select(state => state.uiState.authUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe(authUser$ => {
        if (authUser$) {
          this.router.navigate(['client-selection']);
        }
      });
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngAfterViewInit() {
    document.body.classList.add('authentication-bg');
  }

  get form() {
    return this.loginForm.controls;
  }

  async login() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loadingService.isLoading(true);
    try {
      const user$ = await this.fbAuth.login(this.form.email.value, this.form.password.value);
      await this.router.navigate(['client-selection']);
    } catch (e) {
      this.snackBar.open('Invalid email and/or password', 'Dismiss', {
        duration: 5000
      });
    }
  }
}
