import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuditTrailDocumentListComponent } from './audit-trail-document-list/audit-trail-document-list.component';
import { AuditTrailDocumentComponent } from './audit-trail-document/audit-trail-document.component';
import {MaterialModule} from "../../../material.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import { UploadReviewDocumentComponent } from './upload-audit-trail-document/upload-review-document.component';
import { CreateAuditTrailDocumentComponent } from './create-audit-trail-document/create-audit-trail-document.component';
import { CreateAuditTrailDocumentTabCommentComponent } from './create-audit-trail-document-tab-comment/create-audit-trail-document-tab-comment.component';
import { AuditTrailDocumentCommentComponent } from './audit-trail-document-comment/audit-trail-document-comment.component';

@NgModule({
  declarations: [AuditTrailDocumentListComponent, AuditTrailDocumentComponent, UploadReviewDocumentComponent, CreateAuditTrailDocumentComponent, CreateAuditTrailDocumentTabCommentComponent, AuditTrailDocumentCommentComponent],
  exports: [UploadReviewDocumentComponent, CreateAuditTrailDocumentComponent, CreateAuditTrailDocumentTabCommentComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule
  ]
})
export class AuditTrailModule { }
