import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from "@angular/material";
import {AngularFirestore} from "@angular/fire/firestore";
import {MalihuScrollbarService} from "ngx-malihu-scrollbar";
import {FirestoreUtilities} from "../../utilities/firestore-utilities";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {StripeService} from "../../services/stripe.service";
import {usStates} from "@deliver-sense-librarian/data-schema";

@Component({
  selector: 'app-stripe-payment-manage-dialog',
  templateUrl: './stripe-payment-manage-dialog.component.html',
  styleUrls: ['./stripe-payment-manage-dialog.component.scss']
})
export class StripePaymentManageDialogComponent implements OnInit {
  countries = [];
  cardInfoForm: FormGroup;
  years = [];
  states = usStates;

  constructor(public dialogRef: MatDialogRef<StripePaymentManageDialogComponent>,
              private snackbar: MatSnackBar,
              private afs: AngularFirestore,
              private fb: FormBuilder,
              private stripeService: StripeService,
              private malihuService: MalihuScrollbarService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.setYears();
    this.afs.collection('countries').snapshotChanges().subscribe(countries$ => this.countries = FirestoreUtilities.mapToType(countries$));
    const card = this.data.source.card;
    const owner = this.data.source.owner;
    const address = owner.address;
    this.cardInfoForm = this.fb.group({
      name: new FormControl(owner.name, Validators.required),
      addressLine1: new FormControl(address.line1, Validators.required),
      city: new FormControl(address.city, Validators.required),
      stateProvince: new FormControl(address.state, Validators.required),
      postalCode: new FormControl(address.postal_code, Validators.required),
      country: new FormControl(address.country, Validators.required),
      email: new FormControl(owner.email, Validators.required),
      exp_month: new FormControl(card.exp_month, Validators.required),
      exp_year: new FormControl(card.exp_year, Validators.required)
    })
  }

  setYears() {
    for (let i = 18; i < 50; i++) {
      this.years.push(+`20${i}`);
    }
  }

  saveCard() {
    const formData = this.cardInfoForm.value;
    const cardOwnerData = {
      name: formData.name,
      address_line1: formData.addressLine1,
      address_state: formData.state,
      address_zip: formData.postalCode,
      address_city: formData.city,
      country: formData.country,
      exp_month: formData.exp_month,
      exp_year: formData.exp_year,
    };
    this.stripeService.updateStripeCard(this.data.customer, this.data.source.id, this.data.source.card.fingerprint, cardOwnerData).subscribe(result => {
      this.snackbar.open('Card Information Updated', 'Dismiss', {
        duration: 5000
      });
      this.dialogRef.close();
    }, () => {
      this.snackbar.open('Error updating card info please verify the information provided.', 'Dismiss', {
        duration: 5000
      })
    })
  }

}
