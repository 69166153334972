import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from "@angular/flex-layout";
import {MaterialModule} from "../material.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MalihuScrollbarModule} from "ngx-malihu-scrollbar";
import {DsSharedModule} from "../shared/ds-shared.module";
import { SidebarModule } from "primeng/primeng";
import {HelpDirective} from "./help.directive";

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        DsSharedModule,
        MalihuScrollbarModule.forRoot(),
        FlexLayoutModule,
        SidebarModule
    ],
    declarations: [HelpDirective],
    exports: [HelpDirective],
})
export class DirectivesModule {
}
