import {Component, Input, OnInit} from '@angular/core';
import {UserView} from "@deliver-sense-librarian/data-schema";

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
  @Input() userView: UserView;

  constructor() {
  }

  ngOnInit() {
  }

  getUserViewInitials() {
    const firstInitial = this.userView.firstName ? this.userView.firstName.charAt(0).toUpperCase() : '';
    const lastIntial = this.userView.lastName ? this.userView.lastName.charAt(0).toUpperCase() : '';
    return `${firstInitial}${lastIntial}`;
  }

  getUserViewInitialColor() {
    const firstInitial = this.userView.email.charAt(0).toLowerCase();
    switch (firstInitial) {
      case 'a':
        return '#E91E63';
      case 'b':
        return '#00BCD4';
      case 'c':
        return '#EEFF41';
      case 'd':
        return '#00E676';
      case 'e':
        return '#82B1FF';
      case 'f':
        return '#B388FF';
      case 'g':
        return '#FF8A80';
      case 'h':
        return '#EA80FC';
      case 'i':
        return '#1DE9B6';
      case 'j':
        return '#338dff';
      case 'k':
        return '#FB8C00';
      case 'l':
        return '#FFEA00';
      case 'm':
        return '#66BB6A';
      case 'n':
        return '#00BCD4';
      case 'o':
        return '#26A69A';
      case 'p':
        return '#8C9EFF';
      case 'q':
        return '#FF6E40';
      case 'r':
        return '#F06292';
      case 's':
        return '#00E5FF';
      case 't':
        return '#CE93D8';
      case 'u':
        return '#FFCDD2';
      case 'v':
        return '#26C6DA';
      case 'w':
        return '#4CAF50';
      case 'x':
        return '#C0CA33';
      case 'y':
        return '#F57C00';
      case 'z':
        return '#9E9E9E';
      default:
        return '#64FFDA';
    }
  }
}
