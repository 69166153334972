import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {FirebaseApp} from "@angular/fire";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AngularFirestore} from "@angular/fire/firestore";
import {User, Client, UserView, Entity, Department, Project, TeamMemberInvitation, ClientRole, UserRoles, OrganizationRole} from "@deliver-sense-librarian/data-schema";
import {combineAll, takeUntil} from "rxjs/operators";
import {combineLatest, from, Subject} from "rxjs";
import {FirestoreUtilities} from "../../../../utilities/firestore-utilities";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {Store} from "@ngrx/store";
import moment = require("moment");
import {UiState} from "../../../../redux/custom-states/uiState/ui-state";
import {ConfirmDialogComponent} from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import {scrollbarOptions} from "../../../../shared/ds-constant";

@Component({
  selector: 'app-team-members',
  templateUrl: './team-members.component.html',
  styleUrls: ['./team-members.component.scss']
})
export class TeamMembersComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public tableData: MatTableDataSource<UserView>;
  public displayedColumns: string[] = ['firstName', 'lastName', 'role', 'email'];
  public _user: User;
  public pendingInvites: TeamMemberInvitation[] = [];
  public teamMemberInEdit: UserView;
  public client: Client;
  public invitingNewTeamMember = false;
  public clientRole: ClientRole;
  private _destroy$ = new Subject();
  private teamMembers: any;
  private entities: Entity[] = [];
  private departments: Department[] = [];
  private projects: Project[] = [];
  private uiState: UiState;
  scrollbarOptions = scrollbarOptions;

  constructor(private firebaseApp: FirebaseApp,
              private store: Store<any>,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private afs: AngularFirestore,
              private _cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this._destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this._user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$;
          this._getTeamMembers();
          this._getPendingInvites();
          // this._getOrganizationAvailableResources();
        }
      })
  }

  /**
   * Get team members
   * Get team member current client roles
   * Get team member entites and roles
   * Get team member departments and roles
   * @private
   */
  private _getTeamMembers() {
    this.afs.collection('userViews', ref => ref
      .where('clients', 'array-contains', this.client.id))
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(userViews$ => {
        this.teamMembers = FirestoreUtilities.mapToType(userViews$).filter(teamMember => {
          return teamMember.id !== this._user.id;
        });
        this.tableData = new MatTableDataSource(this.teamMembers);
      });
  }

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  // private _getOrganizationAvailableResources() {
  //   combineLatest([
  //     FirestoreUtilities.getUserAccessibleResourcesOfType('entities', this.afs, this.uiState.entities, [UserRoles.admin]),
  //     FirestoreUtilities.getUserAccessibleResourcesOfType('departments', this.afs, this.uiState.departments, [UserRoles.admin]),
  //     FirestoreUtilities.getUserAccessibleResourcesOfType('projects', this.afs, this.uiState.projects, [UserRoles.admin])
  //   ])
  //     .pipe(takeUntil(this._destroy$))
  //     .subscribe(([entities$, departments$, projects$]) => {
  //       this.entities = <Entity[]>(entities$);
  //       this.departments = <Department[]>(departments$);
  //       this.projects = <Project[]>(projects$);
  //     });
  // }

  private _getPendingInvites() {
    this.afs.collection('teamMemberInvitations', ref => ref
      .where('from', '==', this._user.id)
      .where('status', '==', 'pending'))
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(pendingInvites$ => {
        this.pendingInvites = <TeamMemberInvitation[]>FirestoreUtilities.mapToType(pendingInvites$);
      })
  }

  isInviteExpired(invite: TeamMemberInvitation) {
    return moment(invite.expiration.toDate()).isSameOrBefore(moment());
  }

  async resendInvite(invite: TeamMemberInvitation) {
    await this.afs.doc(`teamMemberInvitations/${invite.id}`).update({
      expiration: moment().add(24, 'hours').toDate(),
      dateUpdated: moment().toDate(),
      resent: true
    });
    this.snackBar.open('Invitation resent successfully', 'Dismiss', {
      duration: 5000
    });
  }

  getClientRole(userView: UserView) {
    const role = userView.clientRoles[this.client.id] ? userView.clientRoles[this.client.id] : 0;
    switch (role) {
      case UserRoles.admin:
        return 'admin';
      case UserRoles.contributor:
        return 'contributor';
      case UserRoles.viewer:
        return 'viewer';
    }
  }

  isUserClientAdmin() {
    return this.uiState.clientRole > 2;
  }
}
