import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FirebaseAuthService} from "../../../../auth/services/firebase-auth.service";
import {Papa} from "ngx-papaparse";
import {FirebaseApp} from "@angular/fire";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AngularFirestore} from "@angular/fire/firestore";
import * as JSZip from "jszip";
import {
  Client,
  AuditTrailDocumentTabCommentStatuses,
  AuditTrailDocument,
  AuditTrailDocumentTab,
  AuditTrailDocumentTabComment,
  User,
  UserView, UserRoles, Project
} from "@deliver-sense-librarian/data-schema";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {from, Subject} from "rxjs";
import {combineAll, retry, subscribeOn, take, takeUntil} from "rxjs/operators";
import {FirestoreUtilities} from "../../../../utilities/firestore-utilities";
import {Store} from '@ngrx/store';
import {UiState} from "../../../../redux/custom-states/uiState/ui-state";

@Component({
  selector: 'app-review-document-list',
  templateUrl: './audit-trail-document-list.component.html',
  styleUrls: ['./audit-trail-document-list.component.scss']
})
export class AuditTrailDocumentListComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public tableData: MatTableDataSource<AuditTrailDocument>;
  public displayedColumns: string[] = ['name', 'num_tabs', 'creator', 'project', 'open_comments', 'approved_comments', 'completed'];
  public commentStatuses = AuditTrailDocumentTabCommentStatuses;
  public creatingNewPorDoc = false;
  private _user: User;
  private _destroy$ = new Subject();
  private auditTrailDocuments: AuditTrailDocument[] = [];
  private porDocumentTabs: AuditTrailDocumentTab[] = [];
  private porDocumentTabComments: AuditTrailDocumentTabComment[] = [];
  private _creators: UserView[] = [];
  private _client: Client;
  private projects: Project[] = [];
  public uiState: UiState;
  constructor(private store: Store<any>,
              private firebaseApp: FirebaseApp,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private afs: AngularFirestore,
              private _cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this._destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this._user = uiState$.authUser;
          this._client = uiState$.client;
          this.uiState = uiState$;
          this._getAuditTrailDocuments();
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  private _getAuditTrailDocuments() {
    FirestoreUtilities.getUserAccessibleResourcesOfType('projects', this.afs, this.uiState.projects, [UserRoles.admin, UserRoles.viewer, UserRoles.contributor])
      .pipe(takeUntil(this._destroy$))
      .subscribe(projects$ => {
        this.projects = (projects$) as Project[];
        if (this.projects.length > 0) {
          const auditTrailDocumentRequests = this.projects.map(project => {
            return this.afs.collection('auditTrailDocuments', ref => ref
              .where('project', '==', project.id))
              .snapshotChanges()
          });
          from(auditTrailDocumentRequests)
            .pipe(combineAll(), takeUntil(this._destroy$))
            .subscribe(auditTrailDocs$ => {
              this.auditTrailDocuments = FirestoreUtilities.mergeCollectionToType(auditTrailDocs$);
              // this.auditTrailDocuments.forEach(doc => {
              //   const matchingProject = this.projects.find(project => project.id === doc.project);
              //   if (matchingProject) {
              //
              //   }
              // })
              this._getPorDocumentTabs();
              this._getPorDocumentCreators();
            });
        }
      });
  }

  private _getPorDocumentTabs() {
    const porDocumentTabRequests = this.auditTrailDocuments.map(doc$ => {
      return this.afs.collection('auditTrailDocumentTabs', ref => ref
        .where('auditTrailDocument', '==', doc$.id))
        .snapshotChanges()
    });
    from(porDocumentTabRequests)
      .pipe(combineAll(), takeUntil(this._destroy$))
      .subscribe(porDocTabs$ => {
        this.porDocumentTabs = FirestoreUtilities.mergeCollectionToType(porDocTabs$);
        this._getPorDocumentTabComments();
      })
  }

  private _getPorDocumentTabComments() {
    const porDocumentTabCommentRequests = this.porDocumentTabs.map(tab$ => {
      return this.afs.collection('auditTrailDocumentTabComments', ref => ref
        .where('auditTrailDocumentTab', '==', tab$.id))
        .snapshotChanges()
    });
    from(porDocumentTabCommentRequests)
      .pipe(combineAll(), takeUntil(this._destroy$))
      .subscribe(porDocTabComments$ => {
        this.porDocumentTabComments = FirestoreUtilities.mergeCollectionToType(porDocTabComments$);
        this._mapDocumentMetaData();
        this.tableData = new MatTableDataSource(this.auditTrailDocuments);
      });
  }

  private _getPorDocumentCreators() {
    const creatorRequests = this.auditTrailDocuments.map(doc$ => {
      return this.afs.doc(`userViews/${doc$.creator}`).snapshotChanges();
    });
    from(creatorRequests)
      .pipe(combineAll(), takeUntil(this._destroy$))
      .subscribe(userViews$ => {
        this._creators = FirestoreUtilities.mergeToType(userViews$).filter(item => !!item);
      })
  }

  private _mapDocumentMetaData() {
    this.auditTrailDocuments.forEach(doc$ => {
      doc$['tabs'] = this.porDocumentTabs.filter(tab$ => tab$.auditTrailDocument === doc$.id);
      doc$['tabs'].forEach(tab$ => {
        tab$['comments'] = this.porDocumentTabComments.filter(comment$ => comment$.auditTrailDocumentTab === tab$.id);
      });
    });
  }

  public getNumberOfComments(tabs$: AuditTrailDocumentTab[], isOpen = true) {
    return tabs$.reduce((prev, tab$) => {
      const comments = tab$['comments'].filter((comment$: AuditTrailDocumentTabComment) => {
        if (isOpen) {
          return comment$.status === AuditTrailDocumentTabCommentStatuses.complete || comment$.status === AuditTrailDocumentTabCommentStatuses.pending
        } else {
          return comment$.status === AuditTrailDocumentTabCommentStatuses.approved
        }
      });
      if (comments) {
        prev += comments.length;
      }
      return prev;
    }, 0);
  }

  public getCreatorInfo(doc$: AuditTrailDocument) {
    const creator = this._creators.find(user => user.id === doc$.creator);
    return creator ? creator.email ? creator.email : `${creator.firstName} ${creator.lastName}` : 'N/A';
  }

  public applyFilter(filterValue: string) {
    this.tableData.filter = filterValue.trim().toLowerCase();
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  isDocumentReviewCompleted(doc: AuditTrailDocument) {
    const numberOfOpenComments = this.getNumberOfComments(doc['tabs']);
    // const numberOfCompletedComments = this.getNumberOfComments(doc['tabs'], false);
    if (numberOfOpenComments === 0) {
      return true;
    } else {
      return false;
    }
  }

  getProjectName(doc: AuditTrailDocument) {
    const project = this.projects.find(_project => _project.id === doc.project);
    return project ? project.name : '';
  }
}
