import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AuditTrailDocumentTabCommentStatuses, AuditTrailDocumentTab, User, UserView, Client, AuditTrailDocument} from "@deliver-sense-librarian/data-schema";
import {FirebaseAuthService} from "../../../../auth/services/firebase-auth.service";
import {Papa} from "ngx-papaparse";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AngularFirestore} from "@angular/fire/firestore";
import {ConfirmDialogComponent} from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import {takeUntil} from "rxjs/operators";
import {FirestoreUtilities} from "../../../../utilities/firestore-utilities";
import {Subject} from "rxjs";
import {Store} from '@ngrx/store';
import {CreateNotificationService} from "../../../../services/create-notification.service";
import {CustomValidators} from "ng2-validation";
import {markFields} from "../../../../shared/ds-constant";

@Component({
  selector: 'app-create-review-document-tab-comment',
  templateUrl: './create-audit-trail-document-tab-comment.component.html',
  styleUrls: ['./create-audit-trail-document-tab-comment.component.scss']
})
export class CreateAuditTrailDocumentTabCommentComponent implements OnInit, OnDestroy {
  @Input() auditTrailDocumentTab: AuditTrailDocumentTab;
  @Input() auditTrailDocument: AuditTrailDocument;
  @Output() complete = new EventEmitter();
  public commentForm: FormGroup;
  public commentStatuses = [
    {name: 'approved', value: AuditTrailDocumentTabCommentStatuses.approved},
    {name: 'pending', value: AuditTrailDocumentTabCommentStatuses.pending},
    {name: 'cancelled', value: AuditTrailDocumentTabCommentStatuses.cancelled},
    {name: 'complete', value: AuditTrailDocumentTabCommentStatuses.complete},
  ];
  public assignees: UserView[];
  private _user: User;
  private _destroy$ = new Subject();
  private _client: Client;

  constructor(private store: Store<any>,
              private papa: Papa,
              private dialog: MatDialog,
              private activedRoute: ActivatedRoute,
              private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private afs: AngularFirestore,
              private notificationService: CreateNotificationService) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .pipe(takeUntil(this._destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this._user = uiState$.authUser;
          this._client = uiState$.client;
          this._setupCommentForm();
          this._getAssignees();
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }


  private _setupCommentForm() {
    this.commentForm = this.fb.group({
      auditTrailDocumentTab: new FormControl(this.auditTrailDocumentTab.id),
      message: new FormControl('', Validators.required),
      creator: new FormControl(this._user.id, Validators.required),
      status: new FormControl(AuditTrailDocumentTabCommentStatuses.pending, Validators.required),
      dueDate: new FormControl('', CustomValidators.date),
      assignee: new FormControl('')
    });
  }

  public async save() {
    markFields(this.commentForm);
    if (this.commentForm.valid) {
      const form = this.commentForm.value;
      await this.afs.collection('auditTrailDocumentTabComments').add(form);
      await this.notificationService.createNotification(
        `New comment for ${this.auditTrailDocument.name} in section ${this.auditTrailDocumentTab.name} assigned to you by ${this._user.email}`,
        form.assignee);
      this.snackBar.open('Successfully added comment.', 'Dismiss', {
        duration: 5000
      });
      this.complete.emit(true);
    } else {
      this.snackBar.open('Please fill out required fields.', 'Dismiss', {
        duration: 5000
      })
    }
  }

  cancel() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Cancel',
        message: 'Are you sure you want to discard your changes?',
        action: 'Yes, cancel.'
      }
    });
    dialogRef.afterClosed().subscribe(discardChanges => {
      if (discardChanges) {
        this.complete.emit(false);
      }
    });
  }

  private _getAssignees() {
    this.afs.collection('userViews', ref => ref
      .where('clients', 'array-contains', this._client.id))
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(userViews$ => {
        // @TODO
        this.assignees = FirestoreUtilities.mapToType(userViews$).filter(userView => userView.id !== this._user.id);
        this.assignees = this.assignees.filter(assignee => {
          return assignee.clientRoles[this._client.id]['projects'][this.auditTrailDocument.project.toString()] > 0;
        })
      })
  }
}
