import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import {scrollbarOptions} from "../../shared/ds-constant";

@Component({
    selector: 'app-policy-dialog',
    templateUrl: './policy-dialog.component.html',
    styleUrls: ['./policy-dialog.component.scss']
})
export class PolicyDialogComponent implements OnInit {
    policy: string;
    scrollbarOptions = scrollbarOptions;

    constructor(public dialogRef: MatDialogRef<PolicyDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.policy = data.policy;
    }

    ngOnInit() {
    }

}
