import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {combineAll, takeUntil} from 'rxjs/operators';

import {ActivatedRoute, Router} from '@angular/router';
import {FirebaseAuthService} from "../services/firebase-auth.service";
import {MatSnackBar} from "@angular/material";
import {Client, ClientRole, User, UserRoles} from "@deliver-sense-librarian/data-schema";
import {AngularFirestore} from '@angular/fire/firestore';
import {from, Subject} from "rxjs";
import {FirestoreUtilities} from "../../utilities/firestore-utilities";
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-login',
  templateUrl: './client-selector.component.html',
  styleUrls: ['./client-selector.component.scss']
})
export class ClientSelectorComponent implements OnInit, OnDestroy, AfterViewInit {
  public clients: Client[] = [];
  public noClients = false;
  public user: User;
  public clientRoles: any[] = [];
  private _destroy$ = new Subject();

  constructor(private auth: FirebaseAuthService,
              private fb: FormBuilder,
              private store: Store<any>,
              private route: ActivatedRoute,
              private router: Router,
              private snackBar: MatSnackBar,
              private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.store.select(state => state.uiState)
      .pipe(takeUntil(this._destroy$))
      .subscribe(uiState$ => {
        if (uiState$.authUser) {
          if (uiState$.client) {
            this.router.navigate(['/app']);
          } else {
            this.user = uiState$.authUser;
            this._getUserClients();
          }
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  ngAfterViewInit() {
  }

  private _getUserClients() {
    this.afs.collection(`users/${this.user.id}/clientRoles`)
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(clientRoles$ => {
        this.clientRoles = FirestoreUtilities.mapToType(clientRoles$);
        if (this.clientRoles && clientRoles$.length > 0) {
          const clientRequest = this.clientRoles.map(clientRole => {
            return this.afs.doc(`clients/${clientRole.resource}`).snapshotChanges();
          });
          from(clientRequest)
            .pipe(combineAll(), takeUntil(this._destroy$))
            .subscribe(clients$ => {
              this.clients = FirestoreUtilities.mergeToType(clients$);
              if (this.clients.length < 1) {
                this.noClients = true;
              }
            })
        } else {
          this.noClients = true;
        }
      });
  }

  public async setClient(client) {
    await this.auth.setSelectedClient(client.id, this.user.id);
    this.router.navigate(['/app']);
  }

  signout() {
    this.auth.signOut();
  }

  getUserClientRole(client: Client) {
    if (this.user) {
      const clientRole = this.clientRoles.find(_cr => _cr.resource === client.id);
      switch (clientRole.role) {
        case UserRoles.admin:
          return 'admin';
        case UserRoles.contributor:
          return 'contributor';
        case UserRoles.viewer:
          return 'viewer';
      }
    }
  }
}
