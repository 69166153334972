import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Papa} from "ngx-papaparse";
import {MatDialog, MatSnackBar} from "@angular/material";
import {AngularFirestore} from '@angular/fire/firestore';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Client, Entity, User, UserRoles, usStates} from "@deliver-sense-librarian/data-schema";
import {FirestoreUtilities} from "../../../../utilities/firestore-utilities";
import {Store} from '@ngrx/store';
import {UiState} from "../../../../redux/custom-states/uiState/ui-state";
import {ConfirmDialogComponent} from "../../../../dialogs/confirm-dialog/confirm-dialog.component";
import {LoadingDialogService} from "../../../../services/loading-dialog.service";

@Component({
  selector: 'app-entity',
  templateUrl: './entity.component.html',
  styleUrls: ['./entity.component.scss']
})
export class EntityComponent implements OnInit {
  @Input() entity: Entity;
  @Input() selectedClient: Client;
  @Output() formSavedSuccess = new EventEmitter();
  entityForm: FormGroup;
  private _user: User;
  private client: any;
  states = usStates;
  uiState: UiState;

  constructor(private store: Store<any>,
              private papa: Papa,
              private dialog: MatDialog,
              private activedRoute: ActivatedRoute,
              private router: Router,
              private loadingService: LoadingDialogService,
              private snackBar: MatSnackBar,
              private fb: FormBuilder,
              private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.store.select(store => store.uiState)
      .subscribe(uiState$ => {
        if (uiState$.authUser && uiState$.client) {
          this._user = uiState$.authUser;
          this.client = uiState$.client;
          this.uiState = uiState$;
          if (!this.entity) {
            this.activedRoute.params.subscribe(params$ => {
              if (params$['id'] && params$['id'] !== 'new') {
                this._fetchEntity(params$['id']);
              } else {
                this.entity = new Entity();
                this._setupEntityForm()
              }
            });
          } else {
            this._setupEntityForm();
          }
        }
      });
  }

  private _fetchEntity(entityId) {
    this.afs.doc(`entities/${entityId}`)
      .snapshotChanges()
      .subscribe(entity$ => {
        this.entity = FirestoreUtilities.objectToType(entity$);
        this._setupEntityForm();
      })
  }

  private _setupEntityForm() {
    this.entityForm = this.fb.group({
      name: new FormControl(this.entity.name ? this.entity.name : '', Validators.required),
      client: new FormControl(this.client ? this.client.id : '', Validators.required),
      addressLine1: new FormControl(this.entity.addressLine1 ? this.entity.addressLine1 : '', Validators.required),
      addressLine2: new FormControl(this.entity.addressLine2 ? this.entity.addressLine2 : ''),
      addressCity: new FormControl(this.entity.addressCity ? this.entity.addressCity : '', Validators.required),
      addressState: new FormControl(this.entity.addressState ? this.entity.addressState : '', Validators.required),
      addressPostalCode: new FormControl(this.entity.addressPostalCode ? this.entity.addressPostalCode : '', Validators.required),
    });
  }

  public async save() {
    this.entityForm.updateValueAndValidity();
    if (this.entityForm.valid) {
      if (this.entity && this.entity.id) {
        const _entity = this._mapFormToEntity();
        await this.afs.doc(`entities/${this.entity.id}`)
          .update(_entity.toJSONObject());
        this.snackBar.open('Successfully updated the entity', 'Dismiss', {
          duration: 5000,
        });
        this.formSavedSuccess.emit(true);
        await this.router.navigate(['/app/organization/entities'])
      } else {
        const _entity = this._mapFormToEntity();
        const newEntity$ = await this.afs.collection(`entities`)
          .add(_entity.toJSONObject());
        await this.afs.doc(`users/${this._user.id}/clientRoles/${this.client.id}/organizationRoles/${newEntity$.id}`).set({
          resource: newEntity$.id,
          role: UserRoles.admin,
          type: 'entity'
        });
        this.snackBar.open('Successfully created the entity', 'Dismiss', {
          duration: 5000,
        });
        this.formSavedSuccess.emit(true);
        await this.router.navigate(['/app/organization/entities'])
      }
    } else {
      this.snackBar.open('Please fill out all required fields.', 'Dismiss', {
        duration: 5000,
      });
    }
  }

  private _mapFormToEntity() {
    const _entity = new Entity();
    const form = this.entityForm.value;
    _entity.name = form.name;
    _entity.primaryContact = this._user.id;
    _entity.client = this.client.id;
    _entity.addressLine1 = form.addressLine1;
    _entity.addressLine2 = form.addressLine2;
    _entity.addressCity = form.addressCity;
    _entity.addressState = form.addressState;
    _entity.addressPostalCode = form.addressPostalCode;
    return _entity;
  }

  delete() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this entity?. All departments and projects within this entity will also be deleted.',
        action: 'Yes, Delete'
      }
    });
    confirmDialog.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`entities/${this.entity.id}`).delete();
        this.loadingService.isLoading(true, 'Deleting entity...');
        setTimeout(() => {
          this.loadingService.isLoading(false);
          this.snackBar.open('Entity deleted successfully!', 'Dismiss', {
            duration: 5000
          });
          this.router.navigate(['/app/organization/entities']);
        }, 1000)
      }
    });
  }
}
