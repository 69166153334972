import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {MatSnackBar} from '@angular/material';
import {BehaviorSubject, combineLatest, Observable, Subject} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {first, map, take, takeUntil} from "rxjs/operators";
import {Client, User, UserRoles} from "@deliver-sense-librarian/data-schema";
import {LoadingDialogService} from "../../services/loading-dialog.service";
import {FirestoreUtilities} from "../../utilities/firestore-utilities";
import {environment} from "../../../environments/environment";
import {Store} from '@ngrx/store';
import {
  SetAccountClientAction, SetAccountRolesAction,
  UnauthenticateUserAction,
  UserAuthenticationSuccessAction
} from "../../redux/custom-states/uiState/ui-state-actions";

@Injectable()
export class FirebaseAuthService {
  private _user: User;
  private userSet = false;
  private inSignup = false;
  public authUser = new BehaviorSubject(null);
  public locationRoles: Observable<any>;
  public entityRoles: Observable<any>;
  public departmentRoles: Observable<any>;
  public projectRoles: Observable<any>;
  private userSubscription;
  private loggedIn = true;
  private loggingOut = new Subject();

  constructor(private afAuth: AngularFireAuth,
              private afs: AngularFirestore,
              private router: Router,
              private snackBar: MatSnackBar,
              private store: Store<any>,
              private loadingService: LoadingDialogService,
              protected http: HttpClient) {
    this._checkForAuthUser().then(user => {
      if (user && JSON.parse(localStorage.getItem('client'))) {
        this.setSelectedClient(JSON.parse(localStorage.getItem('client')).id, user.uid);
      }
    });
  }


  public async emailLogin(email, password) {
    const credential = await this.afAuth.auth.signInWithEmailAndPassword(email, password);
    return credential;
  }

  public async updateEmail(user: User, newEmail?, password?) {
    if (newEmail.value && user.email && password.value) {
      this.loadingService.isLoading(true, 'Updating your email...');
      try {
        const credential = await this.emailLogin(user.email, password.value);
        const res = await this.afAuth.auth.currentUser.updateEmail(newEmail.value);
        await this.afs.doc(`users/${user.id}`).update({email: newEmail.value});
        this.snackBar.open('Successfully updated your account email.', 'Dismiss', {
          duration: 5000
        });
        this.loadingService.isLoading(false);
      } catch (e) {
        this.loadingService.isLoading(false);
        this.snackBar.open('Error updating your email please try again later.', 'Dismiss', {
          duration: 5000
        });
      }
    } else {
      this.snackBar.open('Please enter your current email, new email, and confirm your password to complete the change.', 'Dismiss', {
        duration: 5000
      })
    }
  }

  async resetPassword(email) {
    try {
      await this.afAuth.auth.sendPasswordResetEmail(email);
      this.snackBar.open('Please check your account email for a link to reset your password.', 'Dismiss', {
        duration: 5000
      });
    } catch (e) {
      this.snackBar.open('Error resetting password please try again later.', 'Dismiss', {
        duration: 5000
      });
    }
  }

  public getCurrentToken() {
    return this.afAuth.idToken.pipe(first(), map(token => token));
  }

  public async forgotPassword(email) {
    try {
      await this.afAuth.auth.sendPasswordResetEmail(email);
      this.snackBar.open('Please check your account email for a link to reset your password.', 'Dismiss', {
        duration: 5000
      });
    } catch (e) {
      this.snackBar.open('Error resetting password please try again later.', 'Dismiss', {
        duration: 5000
      });
    }
  }


  private getAuthHeader(token): HttpHeaders {
    return new HttpHeaders().set('Authorization', `Bearer ${token}`);
  }

  public getCustomToken(token) {
    const url = `${environment.authorizationApiUrl}customToken`;
    const headerObj = {headers: this.getAuthHeader(token)};
    return this.http.get(url, headerObj).pipe(first(), map(response => {
      const customToken = response['token'];
      if (customToken) {
        return this.afAuth.auth.signInWithCustomToken(customToken);
      }
    }));
  }

  public setAuthUserState(userId) {
    return this.afs.doc(`users/${userId}`).snapshotChanges()
      .pipe(map(user$ => {
        const user = FirestoreUtilities.objectToType(user$);
        this.store.dispatch(new UserAuthenticationSuccessAction(user));
        return user;
      }));
  }

  public async setSelectedClient(clientId: string, userId: string) {
    const client$ = await this.afs.doc(`clients/${clientId}`)
      .snapshotChanges()
      .pipe(first()).toPromise();
    const client = FirestoreUtilities.objectToType(client$);
    this.store.dispatch(new SetAccountClientAction(client));
    combineLatest([
      this.afs.doc(`users/${userId}/clientRoles/${clientId}`)
        .snapshotChanges(),
      this.afs.collection(`users/${userId}/clientRoles/${clientId}/organizationRoles`)
        .snapshotChanges()
    ])
      .pipe(takeUntil(this.loggingOut))
      .subscribe(([clientRole$, clientOrgRoles$]) => {
        const clientRole = FirestoreUtilities.objectToType(clientRole$);
        const clientOrgRoles = FirestoreUtilities.mapToType(clientOrgRoles$);
        const accountRoles = {
          clientRole: clientRole.role,
          entities: clientOrgRoles.filter(orgRole => orgRole.type === 'entity'),
          locations: clientOrgRoles.filter(orgRole => orgRole.type === 'location'),
          departments: clientOrgRoles.filter(orgRole => orgRole.type === 'department'),
          projects: clientOrgRoles.filter(orgRole => orgRole.type === 'project')
        };
        this.store.dispatch(new SetAccountRolesAction(accountRoles));
      })
  }


  public async emailRegistration(user: User, password: string) {
    return await this.afAuth.auth.createUserWithEmailAndPassword(user.email, password);
  }

  public async setupNewUser(user: User, userId: string) {
    const persistUser = this.afs.doc(`users/${userId}`).set(user.toJSONObject());
    const createUserView = this.afs.doc(`userViews/${userId}`).set(user.toJSONObject());
    this.setAuthUserState(userId);
    return await Promise.all([persistUser, createUserView]);
  }

  public async setupNewClient(client, user) {
    const persistClient = this.afs.doc(`clients/${client.id}`).set(client.toJSONObject());
    this.setSelectedClient(client.id, user.id);
    return await persistClient;
  }

  public async emailRegistrationTemplate(user: User, password: string, client?: Client) {
    const credential = await this.emailRegistration(user, password);
    await this.setupNewUser(user, credential.user.uid);
    if (client) {
      await this.setupNewClient(client, user);
      await this.setUserClientRole(client, credential.user.uid)
    }
    return credential;
  }

  private async setUserClientRole(client: Client, userId: string) {
    return this.afs.doc(`user/${userId}/clientRoles/${client.id}`).set({
      resource: client.id,
      role: UserRoles.admin
    });
  }

  private async _checkForAuthUser() {
    if (!this.userSet) {
      const user = await this.afAuth.authState
        .pipe(map(user$ => user$), first())
        .toPromise();
      if (user) {
        this.setAuthUserState(user.uid).pipe(first()).toPromise();
        this.userSet = true;
        const token$ = await this.getCurrentToken().pipe(first()).toPromise();
        await this.getCustomToken(token$).pipe(first()).toPromise();
        // await this.router.navigate(['client-selection']);
        return user;
      } else {
        this.store.dispatch(new UnauthenticateUserAction());
      }
    }
  }

  public async register(user, password, client?) {
    this.loadingService.isLoading(true, 'Creating your account...');
    const credential = await this.emailRegistrationTemplate(user, password, client);
    return await this.setApplicationCredentials(credential);
  }

  public async login(email, password) {
    let credential;
    this.loadingService.isLoading(true, 'Verifying your credentials...');
    try {
      credential = await this.emailLogin(email, password);
      this.loadingService.isLoading(false);
      this.snackBar.open('Login Successful!', 'Dismiss', {
        duration: 5000
      });
      this.loadingService.isLoading(true, 'Loading your account information...');
      const user$ = await this.setApplicationCredentials(credential);
      this.loadingService.isLoading(false);
      return user$;
    } catch (e) {
      this.loadingService.isLoading(false);
      this.snackBar.open('Invalid email and/or password', 'Dismiss', {
        duration: 5000
      });
    }
  }

  private async setApplicationCredentials(credential) {
    const token = await this.getCurrentToken().toPromise();
    console.log(token);
    const customToken = await this.getCustomToken(token).toPromise();
    console.log('custom: ' + customToken);
    const user = await this.setAuthUserState(credential.user.uid).pipe(first()).toPromise();
    console.log(JSON.stringify(user));
    this.loadingService.isLoading(false);
    return user;
  }

  public async signOut() {
    this.store.dispatch(new UnauthenticateUserAction());
    await this.afAuth.auth.signOut();
    this.loggingOut.next(true);
    this.authUser = new BehaviorSubject<any>(null);
    this._user = null;
    await this.router.navigate([''])
  }

}
